/*
 DISORIENTED_SERIALS_LISTING
 Sreenath 3Nov2021 - Disoriented Serials Listing.
 Decoupled the logic from Serials component by creating new components for each tab since all tabs needs to show their own data.
 For those components that need locations values, passed the values as props from parent component, thus by eliminating repeated getLocations API calls each time component gets rendered.
 Removed unnecessary brands API calls, made use of value from getSerials to populate the brand name.
*/
import { StocksInfo } from '@components/stocks-info';
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  StyleRules,
  Tab,
  Tabs,
  Theme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ECategoryType } from '@models/category-type';
import { IStateApps } from '@models/state-app';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import { openSnackbarMessage, setTab } from '@redux/actions/appsActions';
import appLanguages from '@utils/app-languages';
import { getUserCategory } from '@utils/get-user-category';
import React from 'react';
import { connect } from 'react-redux';

import { compose } from 'redux';
/* Start - DISORIENTED_SERIALS_LISTING */
import {
  getLocations,
  registerLoginHistory,
  storeTmpGpsCords,
} from '@redux/actions/serversActions';
import { logout } from '@redux/actions/authActions';
import { DefaultMasterQuery } from '@utils/default-query';
import { IQuery } from '@models/query';
import userEnv from 'userEnv';

/* End DISORIENTED_SERIALS_LISTING */
import TabPanel, { a11yProps } from '../../elements/TabPanel';
import { SakeHistoryForRestaurant } from './sake-registrations/SakeHistoryForRestaurant';
import { SakeSalesHistoryForRestaurant } from './sake-registrations/SakeSalesHistoryForRestaurant';
import { SakeRegForBrewery } from './sake-registrations/SakeRegForBrewery';
import { SakeRegForRestaurant } from './sake-registrations/SakeRegForRestaurant';
import { SakeRegistrationForDistributor } from './sake-registrations/SakeRegistrationForDistributor';
import { SakeShipForDistributor } from './sake-registrations/SakeShipForDistributor';
import { Serials } from './serials/Serials';
import { SakeShippedForBrewery } from './sake-registrations/SakeShippedForBrewery';
import { SakeStockForDistributor } from './sake-registrations/SakeStockForDistributor';
import { SakeShippedForDistributor } from './sake-registrations/SakeShippedForDistributors';
import { functions } from '@utils/firebase';
import { BottleManagement } from './serials/bottleManagement';
import { BottleManagementBrewery } from './serials/bottleManagementBrewery';

class ContentClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isFirstTimeGetGps: true,
    };
  }
  private locationsList = [];
  async componentDidMount() {
    const { servers, registerLoginHistory } = this.props;
    const { isLoginHistoryRegistered } = servers;
    try {
      const request = functions.httpsCallable('getLocations');
      const result = await request({ ...DefaultMasterQuery, where: { hidden: false } });
      const { data } = result;
      !isLoginHistoryRegistered && registerLoginHistory();
      this.locationsList = data.objects;
    } catch (e) {
      console.error('error', e);
    }
  }

  handleChange(e, tabValue) {
    window.scrollTo(0, 0);
    const { setTab } = this.props;
    setTab(tabValue);
  }

  toDashboard() {
    const { servers, logout } = this.props;
    const toUrl =
      userEnv.dashboardUrl + `/?uid=${servers.user.uid}_${Date.now() + userEnv.cryptoKey}`;
    setTimeout(() => logout(), 100);
    window.open(toUrl, '_self');
  }

  render() {
    const { apps, servers, classes } = this.props;
    const { tabValue } = apps;
    const { user } = servers;
    const category = getUserCategory(user);
    const lang = apps.currentLanguage;

    const isNowLoading = servers.isRequesting || servers.isGetRequesting;
    const backdropView = (
      <Backdrop className={classes.backdrop} open={isNowLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );

    switch (category) {
      case ECategoryType.ADMIN: // 未指定
        return (
          <>
            <AppBar position='static' className={classes.appBar}>
              <div className={classes.buttonSpace}>
                <div className={classes.center}>
                  <Button className={classes.dashboardButton} onClick={() => this.toDashboard()}>
                    {appLanguages.mainMenu[lang]}
                  </Button>
                </div>
              </div>
              <Tabs
                value={tabValue}
                onChange={(e, v) => this.handleChange(e, v)}
                aria-label='main-tabs'
                variant='scrollable'
              >
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.bottleManagement[lang]}`}
                  {...a11yProps(0)}
                />
              </Tabs>
            </AppBar>

            <TabPanel value={tabValue} index={0}>
              {tabValue === 0 ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Card>
                      <BottleManagement />
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Box />
              )}
            </TabPanel>
          </>
        );
      case ECategoryType.BREWERY: // 酒蔵
        return (
          <>
            <AppBar position='static' className={classes.appBar}>
              <div className={classes.buttonSpace}>
                <div className={classes.center}>
                  <Button className={classes.dashboardButton} onClick={() => this.toDashboard()}>
                    {appLanguages.mainMenu[lang]}
                  </Button>
                </div>
              </div>
              <Tabs
                value={tabValue}
                onChange={(e, v) => this.handleChange(e, v)}
                aria-label='main-tabs'
                variant='scrollable'
              >
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.bottleManagement[lang]}`}
                  {...a11yProps(0)}
                />
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.inventoryManagement[lang]}`}
                  {...a11yProps(1)}
                />
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.shipping[lang]}`}
                  {...a11yProps(2)}
                />
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.shipped[lang]}`}
                  {...a11yProps(3)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              {tabValue === 0 ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <BottleManagementBrewery />
                  </Grid>
                </Grid>
              ) : (
                <Box />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {tabValue === 1 ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <StocksInfo />
                    <Card>
                      <Serials viewReference='brew' viewType='stock' />
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Box />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {tabValue === 2 ? (
                <>
                  <Grid container justify='center' alignContent='center' alignItems='center'>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Card>
                        <SakeRegForBrewery />
                      </Card>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Box />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              {tabValue === 3 ? (
                <>
                  <Grid container justify='center' alignContent='center' alignItems='center'>
                    {/*<Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Card>
                          <SummaryFunction />
                        </Card>
                      </Grid>
                      */}
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Card>
                        <SakeShippedForBrewery />
                      </Card>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Box />
              )}
            </TabPanel>
            {backdropView}
          </>
        );
      case ECategoryType.DISTRIBUTOR: // ディストリビュータ
        return (
          <>
            <AppBar position='static' className={classes.appBar}>
              <div className={classes.buttonSpace}>
                <div className={classes.center}>
                  <Button className={classes.dashboardButton} onClick={() => this.toDashboard()}>
                    {appLanguages.mainMenu[lang]}
                  </Button>
                </div>
              </div>
              <Tabs
                value={tabValue}
                onChange={(e, v) => this.handleChange(e, v)}
                aria-label='main-tabs'
                variant='scrollable'
              >
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.notReceived[lang]}`}
                  {...a11yProps(0)}
                />
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.stock[lang]}`}
                  {...a11yProps(1)}
                />
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.delivering[lang]}`}
                  {...a11yProps(2)}
                />
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.delivered[lang]}`}
                  {...a11yProps(3)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              {tabValue === 0 ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Card>
                      <SakeRegistrationForDistributor viewType='receiving' />
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Box />
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {tabValue === 1 ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <StocksInfo />
                    <Card>
                      {/* DISORIENTED_SERIALS_LISTING */}
                      <SakeStockForDistributor locations={this.locationsList} />
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Box />
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              {tabValue === 2 ? (
                <>
                  <Grid container justify='center' alignContent='center' alignItems='center'>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Card>
                        <SakeShipForDistributor />
                      </Card>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Box />
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
              {tabValue === 3 ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Card>
                      {/* DISORIENTED_SERIALS_LISTING */}
                      <SakeShippedForDistributor locations={this.locationsList} />
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Box />
              )}
            </TabPanel>

            {backdropView}
            {backdropView}
          </>
        );
      case ECategoryType.RESTAURANT: // レストラン
        return (
          <>
            <AppBar position='static' className={classes.appBar}>
              <div className={classes.buttonSpace}>
                <div className={classes.center}>
                  <Button className={classes.dashboardButton} onClick={() => this.toDashboard()}>
                    {appLanguages.mainMenu[lang]}
                  </Button>
                </div>
              </div>
              <Tabs
                value={tabValue}
                onChange={(e, v) => this.handleChange(e, v)}
                aria-label='main-tabs'
                variant='scrollable'
              >
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.restReceivingAndSelling[lang]}`}
                  {...a11yProps(0)}
                />
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.stock[lang]}`}
                  {...a11yProps(1)}
                />
                <Tab
                  disabled={isNowLoading}
                  className={classes.noneTextTransform}
                  label={`${appLanguages.sold[lang]}`}
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              {tabValue === 0 ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12}>
                    <Box mt={3} />
                  </Grid>
                  <Grid item xs={11} sm={11} md={9} xl={7} lg={5}>
                    <Card>
                      <SakeRegForRestaurant />
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Box />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {tabValue === 1 ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12}>
                    <Box mt={3} />
                  </Grid>
                  <Grid item xs={11} sm={11} md={9} xl={7} lg={5}>
                    <StocksInfo />
                    <Card>
                      <SakeHistoryForRestaurant viewType='stock' />
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Box />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {tabValue === 2 ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12}>
                    <Box mt={3} />
                  </Grid>
                  <Grid item xs={11} sm={11} md={9} xl={7} lg={5}>
                    <Card>
                      <SakeSalesHistoryForRestaurant viewType='sold' />
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Box />
              )}
            </TabPanel>
            {backdropView}
          </>
        );
      default:
        return '';
    }
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  auth: IStateAuth;
  servers: IStateServers;
  classes: any;
  logout: any;
}

interface State {
  isFirstTimeGetGps: boolean;
}

export interface IDispatchProps {
  setTab: (tabId: number) => void;
  openSnackbarMessage: (type: string, message: any) => void;
  getLocations: (query: IQuery) => void; // DISORIENTED_SERIALS_LISTING
  registerLoginHistory: () => void;
  storeTmpGpsCords: (params: any) => void; //temporaly
  logout: () => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  auth: state.auth,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setTab,
  openSnackbarMessage,
  getLocations, // DISORIENTED_SERIALS_LISTING
  registerLoginHistory,
  storeTmpGpsCords,
  logout,
};

const myStyles = (theme: Theme): StyleRules => ({
  noneTextTransform: { textTransform: 'none' },
  card: {
    padding: theme.spacing(2),
    textAlign: 'start',
    color: theme.palette.text.secondary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonSpace: {
    width: '10%',
    minWidth: '135px',
  },
  dashboardButton: {
    width: '90%',
    height: '90%',
    color: '#dbb26e',
    backgroundColor: '#15355c',
  },
  center: {
    position: 'relative',
    top: '15%',
    left: '5%',
  },
  appBar: {
    flexDirection: 'row',
  },
});

export const Content = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(ContentClass);
