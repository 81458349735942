/*
 DISORIENTED_SERIALS_LISTING
 Sreenath 3Nov2021 - Disoriented Serials Listing.
 Fetched the serials exclusively, removed getBrand API, made use of getSerials to populate brand name
*/

import { Box, Grid, LinearProgress } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-app';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import appLanguages from '@utils/app-languages';
import { getFormattedDateString, nowrapHeader, withConfirm } from '@utils/common';
import {
  appMuiTheme,
  enableSortOnlyOpts,
  muiDataTableCommonOptions,
} from '@utils/mui-datatables-ops';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';

import { compose } from 'redux';

import WhiteLinearProgress from '../../../elements/WhiteLinearProgress';
import { getSerials, setSerialsQuery } from '../../../redux/actions/serversActions';
import { functions } from '@utils/firebase';
import { LIMIT_PAGINATION_MAX } from '@utils/default-query';

class SakeSalesHistoryForRestaurantClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    viewType: 'sold',
  };

  public query = {};
  public pages = {
    offset: 0,
    limit: LIMIT_PAGINATION_MAX,
  };

  constructor(props) {
    super(props);

    const { apps, viewType, servers, setSerialsQuery } = this.props;
    const { user } = servers;
    const { location } = user;
    const lang = apps.currentLanguage;

    const query = {
      ...servers.serialsQuery,
      order: [
        ['restSoldAt', 'DESC'],
        ['code', 'ASC'],
      ],
      where: {
        $or: [{ brewShipRestLocId: location.id }, { distShipRestLocId: location.id }],
        restRecvAt: { $ne: null },
        restSoldAt: { $ne: null },
      },
    };

    this.query = query;

    setSerialsQuery(this.query);

    const columns = [];
    columns.push({
      name: 'code',
      label: nowrapHeader(appLanguages.serialCode[lang]),
      options: { ...enableSortOnlyOpts },
    });
    columns.push({
      name: 'code',
      label: nowrapHeader(appLanguages.brand[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => this.getBrandName(v, lang),
      },
    });
    columns.push({
      name: 'restRecvAt',
      label: nowrapHeader(appLanguages.serialDistRegistrationAt[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => this.getReceiveDateText(v, lang),
      },
    });
    columns.push({
      name: 'restSoldAt',
      label: nowrapHeader(appLanguages.serialSoldRegistrationAt[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => this.getSoldDateText(v, lang),
        display: viewType === 'sold',
      },
    });

    this.state = {
      initialized: false,
      columns,
      serialsList: null,
      serialsTotalCounts: 0,
    };
  }

  componentDidMount() {
    this.fetchSerials(this.query);
  }

  fetchSerials(query) {
    const self = this;
    const request = functions.httpsCallable('getSerials');
    request(query)
      .then((result) => {
        const { data } = result;
        data.objects = data.objects.filter(function (item) {
          console.log(item);
          return item.status !== 6;
        });
        self.setState({
          initialized: true,
          serialsList: data.objects,
          serialsTotalCounts: data.totalCounts,
        });
      })
      .catch((e) => console.error('error', e));
  }

  getReceiveDateText(dateString, lang) {
    let text = appLanguages.unreceived[lang];
    if (dateString) {
      const date = new Date(Date.parse(dateString));
      text = getFormattedDateString(date);
    }
    return text;
  }

  getSoldDateText(dateString, lang) {
    let text = '-'; // appLanguages.notonsale[lang];
    if (dateString) {
      const date = new Date(Date.parse(dateString));
      text = getFormattedDateString(date);
    }
    return text;
  }

  getBrandName(code: string, lang: string): string | null {
    const { serialsList } = this.state;
    if (serialsList) {
      const serial = serialsList.find((v) => v.code === code);
      return serial.brand?.name;
    }
  }

  requestQuery(options?: any) {
    const { servers } = this.props;
    const { user } = servers;
    const { location } = user;
    // Sreenath27thOct - Unordered serials list
    // query to fetch serials in ascending order and which are sold recently.
    const query = {
      ...servers.serialsQuery,
      order: [
        ['restSoldAt', 'DESC'],
        ['code', 'ASC'],
      ],
      where: {
        $or: [{ brewShipRestLocId: location.id }, { distShipRestLocId: location.id }],
        restRecvAt: { $ne: null },
        restSoldAt: { $ne: null },
      },
    };
    return { ...query, ...options };
  }

  updateQuery(newQuery) {
    const { setSerialsQuery } = this.props;
    const query = this.requestQuery(newQuery);
    setSerialsQuery(query);
    this.setState({ initialized: false }, () => {
      this.fetchSerials(query);
    });
  }

  handleOnChangePage(currentPage) {
    const { limit } = this.pages;
    this.pages.offset = currentPage * limit;
    this.updateQuery({ offset: this.pages.offset, limit: this.pages.limit });
  }

  handleOnChangeRowsPerPage(numberOfRows) {
    this.pages.limit = numberOfRows;
    this.updateQuery({ offset: 0, limit: numberOfRows });
  }

  handleOnColumnSortChange(changedColumn, direction) {
    const { columns } = this.state;
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.name === changedColumn) {
        if (direction.match(/desc/)) column.options.sortDirection = 'desc';
        else column.options.sortDirection = 'asc';
      } else {
        delete column.options.sortDirection;
      }
    }
    this.updateQuery({
      order: [[changedColumn, direction.match(/desc/) ? 'DESC' : 'ASC']],
      offset: this.pages.offset,
      limit: this.pages.limit,
    });
  }

  render() {
    const { servers, apps } = this.props;
    const { initialized, columns, serialsList, serialsTotalCounts } = this.state;
    const currentPage = this.pages.offset / this.pages.limit;
    const numberOfRows = this.pages.limit;
    let rowData = serialsList || [];
    const lang = apps.currentLanguage;

    //Todays date for the todays summary table
    let count_and_brand_today = {};
    let total_count_today = 0;
    let today_date = new Date();
    let today_date_number = today_date.getDate();
    let today_month_number = today_date.getMonth();
    let today_year_number = today_date.getFullYear();
    //////Calculations and code block for todays summary table
    for (var key in rowData) {
      let date = new Date(rowData[key]['restSoldAt']).getDate();
      let month = new Date(rowData[key]['restSoldAt']).getMonth();
      let year = new Date(rowData[key]['restSoldAt']).getFullYear();

      if (
        date === today_date_number &&
        month === today_month_number &&
        year === today_year_number
      ) {
        if (this.getBrandName(rowData[key]['code'], lang) in count_and_brand_today) {
          count_and_brand_today[this.getBrandName(rowData[key]['code'], lang)] += 1;
          total_count_today += 1;
        } else {
          count_and_brand_today[this.getBrandName(rowData[key]['code'], lang)] = 1;
          total_count_today += 1;
        }
      }
    }

    let data_today_summary = [];
    Object.keys(count_and_brand_today).map((key, index) => {
      data_today_summary.push({
        brand: key,
        stock: count_and_brand_today[key] + appLanguages.bottles[lang],
      });
    });

    let column_headers_today = [
      {
        name: 'brand',
        label: appLanguages.brand[lang],
        options: { setCellProps: () => ({ style: { minWidth: '180px' } }) },
      },
      {
        name: 'stock',
        label: `${appLanguages.stockCountTotal[lang]} : ${total_count_today} ${appLanguages.bottles[lang]}`,
        options: { setCellProps: () => ({ style: { minWidth: '180px' } }) },
      },
    ];

    const isRequesting = servers.isRequesting || servers.isGetRequesting || !initialized;
    return (
      <>
        {isRequesting ? <LinearProgress /> : <WhiteLinearProgress />}
        <MuiThemeProvider theme={appMuiTheme}>
          <Grid item style={{ paddingLeft: 15 }}>
            <Box>
              <h4>{appLanguages.todaySummaryrest[lang]}</h4>
            </Box>
          </Grid>
          <div
            style={{
              paddingTop: 25,
              paddingBottom: 20,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <MUIDataTable
              title=''
              data={data_today_summary}
              options={{
                ...muiDataTableCommonOptions(),
                responsive: 'standard',
                download: false,
                filter: false,
                search: false,
                expandableRows: false,
                expandableRowsOnClick: false,
                pagination: false,
                sort: false,
                selectableRowsHeader: false,
              }}
              columns={column_headers_today}
            />
          </div>
          <Grid item style={{ paddingTop: 25, paddingLeft: 15 }}>
            <h4>{appLanguages.allSerials[lang]}</h4>
          </Grid>
          <div
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <MUIDataTable
              title=''
              data={serialsList || []}
              columns={columns}
              options={{
                ...muiDataTableCommonOptions(),
                download: false,
                expandableRows: false,
                expandableRowsOnClick: false,
                onRowClick: (rowData, rowMeta) => console.log('onRowsSelect.', rowData, rowMeta),
                search: false,
                page: currentPage,
                rowsPerPage: numberOfRows,
                count: serialsTotalCounts,
                onChangePage: (currentPage) => this.handleOnChangePage(currentPage),
                onChangeRowsPerPage: (numberOfRows) => this.handleOnChangeRowsPerPage(numberOfRows),
                onColumnSortChange: (changedColumn, direction) =>
                  this.handleOnColumnSortChange(changedColumn, direction),
              }}
            />
          </div>
        </MuiThemeProvider>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  viewType?: 'stock' | 'sold';
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
  getSerials: (query: IQuery) => void;
}

interface State {
  initialized: boolean;
  columns: MUIDataTableColumn[];
  serialsList: any;
  serialsTotalCounts: number;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchProps: IDispatchProps = {
  setSerialsQuery,
  getSerials,
};

export const SakeSalesHistoryForRestaurant = compose(connect(mapStateToProps, mapDispatchProps))(
  withConfirm(SakeSalesHistoryForRestaurantClass),
);
