/*
 DISORIENTED_SERIALS_LISTING
 Sreenath 3Nov2021 - Disoriented Serials Listing.
 - Fetched the serials exclusively
 - removed getBrand API, made use of getSerials to populate brand name
 -removed getLocations API , passed locations props from parent component for populating location name.
*/

import { LinearProgress } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IQuery } from '@models/query';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import appLanguages from '@utils/app-languages';
import { getFormattedDateString, nowrapHeader } from '@utils/common';
import { LIMIT_PAGINATION_MAX } from '@utils/default-query';
import {
  appMuiTheme,
  disableDisplayOpts,
  disableSortOpts,
  enableSortOnlyOpts,
  getSelectCustomBodyRender,
  rowDataToObj,
  muiDataTableCommonOptions,
} from '@utils/mui-datatables-ops';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';

import { IStateApps } from 'models/state-app';
import { compose } from 'redux';

import WhiteLinearProgress from '../../../elements/WhiteLinearProgress';
import { functions } from '@utils/firebase';
import { setSerialsQuery } from '@redux/actions/serversActions';

import { SerialsCustomToolbar } from '../serials/SerialsCustomToolbar';

class SakeStockForDistributorClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {};
  public query = {};
  public pages = {
    offset: 0,
    limit: LIMIT_PAGINATION_MAX,
  };
  constructor(props) {
    super(props);
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    const columns = [];

    const { servers, setSerialsQuery } = this.props;
    const { user } = servers;
    const { location } = user;
    const orderBy = [
      ['distRecvAt', 'DESC'],
      ['code', 'ASC'],
    ];

    const query = {
      ...servers.serialsQuery,
      order: orderBy,
      searchText: null,
      where: {
        distShipAt: null,
        recvDistLocId: location.id,
      },
      offset: 0,
      limit: LIMIT_PAGINATION_MAX,
    };

    this.query = query;
    setSerialsQuery(this.query);

    columns.push({
      name: 'id',
      options: { ...disableDisplayOpts },
    });

    columns.push({
      name: 'code',
      label: nowrapHeader(appLanguages.serialCode[lang]),
      options: { ...disableSortOpts },
    });

    columns.push({
      name: 'code',
      label: nowrapHeader(appLanguages.brand[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => this.getBrandName(v, lang),
      },
    });

    columns.push({
      name: 'brewLocId',
      label: nowrapHeader(appLanguages.brewery[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => getSelectCustomBodyRender(this.getBrewShipRestLocOptionMap(v), v),
      },
    });

    columns.push({
      name: 'brewShipAt',
      label: nowrapHeader(appLanguages.brewShipAt[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => this.getShipAtText(v, lang),
      },
    });

    columns.push({
      name: 'distRecvAt',
      label: nowrapHeader(appLanguages.serialDistRegistrationAt[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => this.getShipAtText(v, lang),
      },
    });

    this.state = {
      initialized: false,
      columns,
      serialsList: null,
      serialsTotalCounts: 0,
    };
  }

  async componentDidMount() {
    await this.fetchSerials(this.query);
  }

  fetchSerials(query) {
    const self = this;
    const request = functions.httpsCallable('getSerials');
    request(query)
      .then((result) => {
        const { data } = result;
        data.objects = data.objects.filter(function (item) {
          console.log(item);
          return item.status !== 6;
        });
        self.setState({
          initialized: true,
          serialsList: data.objects,
          serialsTotalCounts: data.totalCounts,
        });
      })
      .catch((e) => console.error('error', e));
  }

  getSlipText(text) {
    return text ? text : 'N/A';
  }

  getBrandName(code: string, lang: string): string | null {
    const { serialsList } = this.state;
    if (serialsList) {
      const serial = serialsList.find((v) => v.code === code);
      return serial.brand?.name;
    }
  }

  getShipAtText(dateString, lang) {
    let shipAtText = appLanguages.unshipped[lang];
    if (dateString) {
      const date = new Date(Date.parse(dateString));
      shipAtText = getFormattedDateString(date);
    } else {
      shipAtText = 'N/A';
    }
    return shipAtText;
  }

  getBrewShipRestLocOptionMap(v) {
    const { locations } = this.props;
    const optionMap = {};
    locations?.forEach((o) => {
      optionMap[o.id] = o.name;
    });
    if (!(v in optionMap)) {
      optionMap[v] = 'N/A';
    }
    return optionMap;
  }

  rowDataToObj(rowData) {
    const { columns } = this.state;
    return rowDataToObj(columns, rowData);
  }

  updateQuery(newQuery) {
    const { setSerialsQuery } = this.props;
    const query = { ...this.query, ...newQuery };
    this.setState({ initialized: false }, () => {
      setSerialsQuery(query);
      this.fetchSerials(query);
    });
  }

  handleOnChangePage(currentPage) {
    const { limit } = this.pages;
    this.pages.offset = currentPage * limit;
    this.updateQuery({ offset: this.pages.offset, limit: this.pages.limit });
  }

  handleOnChangeRowsPerPage(numberOfRows) {
    this.pages.limit = numberOfRows;
    this.updateQuery({ offset: 0, limit: numberOfRows });
  }

  handleOnColumnSortChange(changedColumn, direction) {
    const { columns } = this.state;
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.name === changedColumn) {
        if (direction.match(/desc/)) column.options.sortDirection = 'desc';
        else column.options.sortDirection = 'asc';
      } else {
        delete column.options.sortDirection;
      }
    }
    this.updateQuery({
      order: [[changedColumn, direction.match(/desc/) ? 'DESC' : 'ASC']],
      offset: this.pages.offset,
      limit: this.pages.limit,
    });
  }

  render() {
    const { servers } = this.props;
    const { initialized, columns, serialsList, serialsTotalCounts } = this.state;
    const currentPage = this.pages.offset / this.pages.limit;
    const numberOfRows = this.pages.limit;
    /* 参考: https://github.com/gregnb/mui-datatables */
    const isRequesting = servers.isRequesting || servers.isGetRequesting || !initialized;
    return (
      <>
        {isRequesting ? <LinearProgress /> : <WhiteLinearProgress />}
        <MuiThemeProvider theme={appMuiTheme}>
          <MUIDataTable
            title=''
            data={serialsList || []}
            columns={columns}
            options={{
              ...muiDataTableCommonOptions(),
              download: false,
              expandableRowsOnClick: false,
              search: false,
              page: currentPage,
              rowsPerPage: numberOfRows,
              count: serialsTotalCounts,
              onChangePage: (currentPage) => this.handleOnChangePage(currentPage),
              customToolbar: () => (
                <SerialsCustomToolbar
                  showAddSerialButton={false}
                  textSearched={(text) => {
                    // DISORIENTED_SERIALS_LISTING - optional callback to handle rendering while using search box
                    this.query['searchText'] = text;
                    this.setState({ initialized: false }, () => {
                      this.pages.limit = LIMIT_PAGINATION_MAX;
                      this.pages.offset = 0;
                      const query = Object.assign({}, this.query, this.pages);
                      this.fetchSerials(query);
                    });
                  }}
                  refreshData={() => {
                    // DISORIENTED_SERIALS_LISTING - optional callback to handle rendering when refresh button clicked.
                    this.setState({ initialized: false }, () => {
                      this.pages.limit = LIMIT_PAGINATION_MAX;
                      this.pages.offset = 0;
                      const query = Object.assign({}, this.query, this.pages);
                      this.fetchSerials(query);
                    });
                  }}
                />
              ),
              onChangeRowsPerPage: (numberOfRows) => this.handleOnChangeRowsPerPage(numberOfRows),
              onColumnSortChange: (changedColumn, direction) =>
                this.handleOnColumnSortChange(changedColumn, direction),
            }}
          />
        </MuiThemeProvider>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  locations: any;
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
}

interface State {
  initialized: boolean;
  columns: MUIDataTableColumn[];
  serialsList: any;
  serialsTotalCounts: number;
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchProps: IDispatchProps = {
  setSerialsQuery,
};

export const SakeStockForDistributor = compose(connect(mapStateToProps, mapDispatchProps))(
  SakeStockForDistributorClass,
);
