/*
 DISORIENTED_SERIALS_LISTING
 Sreenath 3Nov2021 - Disoriented Serials Listing.
 - Fetched the serials exclusively
 - removed getBrand API, made use of getSerials to populate brand name
 -removed getLocations API , passed locations props from parent component for populating location name.
*/

import {
  TextField,
  CircularProgress,
  InputAdornment,
  Checkbox,
  Box,
  LinearProgress,
  Paper,
  Grid,
  Theme,
  StyleRules,
  Tooltip,
  IconButton,
  Button,
  Zoom,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IQuery } from '@models/query';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import { ISerial } from '@models/serial';
//import { ISerials } from '@models/serials';

import appLanguages from '@utils/app-languages';
import {
  getFormattedDateString,
  nowrapHeader,
  getFilterButton,
  getDateRangePicker,
  getSmallClearButton,
  getSmallTextField,
  getAutocomplete,
  SERIAL_ACTION,
} from '@utils/common';
import { LIMIT_PAGINATION_MAX } from '@utils/default-query';
import {
  appMuiTheme,
  //disableDisplayOpts,
  // disableSortOpts,
  enableSortOnlyOpts,
  getSelectCustomBodyRender,
  rowDataToObj,
} from '@utils/mui-datatables-ops';
import React from 'react';
import { connect } from 'react-redux';

import { IStateApps } from 'models/state-app';
import { compose } from 'redux';

import WhiteLinearProgress from '../../../elements/WhiteLinearProgress';
import {
  openSerialEditDialog,
  //openSnackbarMessage,
  setEditSerial,
  setSerialCustomToolbarSearchKeyword,
  toggleSerialCustomToolbarSearchBox,
} from '../../../redux/actions/appsActions';
import { SerialEditDialog } from '../serials/SerialEditDialog';
import { functions } from '@utils/firebase';
import {
  setSerialsQuery,
  getMasterLocations,
  setMasterLocationsQuery,
  getSerialBrands,
  delSerial,
  getSerialSlips,
  registerSerials,
  getSerials,
} from '@redux/actions/serversActions';

//Dialog box libraries
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogTitle } from '@material-ui/core';

//Grid libraries and packages
import { DataGrid } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import {
  Clear as ClearIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { DefaultMasterQuery } from '@utils/default-query';
import { ECategoryType } from '@models/category-type';

//orange color import
import { deepOrange } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { SummaryFunctionDistributor } from './SummaryFunctionDistributor';

const orangeTheme = createMuiTheme({ palette: { primary: deepOrange } });
const greyTheme = createMuiTheme({ palette: { primary: { main: '#616161' } } });
let disableConfirm = true;
let isRowSelectableFlag = true;
let SortAsc = '';
let SortDesc = '';
let ShowColumns = '';
let Hide = '';

class SakeShippedForDistributorClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {};
  private initialFilters = {
    filterCreatedAt: [],
    filterSalesOrder: '',
    filterDestinationId: '',
    filterBrandId: '',
  };
  public query = {};
  public pages = {
    offset: 0,
    limit: LIMIT_PAGINATION_MAX,
  };
  constructor(props) {
    super(props);
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    const columns = [];

    const { servers, setSerialsQuery } = this.props;
    const { user } = servers;
    const { location } = user;
    const orderBy = [
      ['distShipAt', 'DESC'],
      ['code', 'ASC'],
    ];

    const query = {
      ...servers.serialsQuery,
      order: orderBy,
      searchText: null,
      where: {
        distShipAt: { $ne: null },
        recvDistLocId: location.id,
      },
      offset: 0,
    };

    this.query = query;
    setSerialsQuery(this.query);

    /*
    columns.push({
      name: 'id',
      options: { ...disableDisplayOpts },
    });
    */

    // シリアル
    columns.push({
      field: 'code',
      headerName: nowrapHeader(appLanguages.serialCode[lang]),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      minWidth: 150,
    });

    // 銘柄

    columns.push({
      field: 'brandId',
      headerName: nowrapHeader(appLanguages.brand[lang]),
      renderCell: (params) =>
        getSelectCustomBodyRender(this.getBrandOptionMap(), params.row.brandId),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      minWidth: 150,
    });

    columns.push({
      field: 'distSlip',
      headerName: nowrapHeader(appLanguages.slipNum[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => this.getSlipText(v),
      },
      renderCell: (params) => this.getSlipText(params.row.distSlip),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      minWidth: 150,
    });

    columns.push({
      field: 'distShipAt',
      headerName: nowrapHeader(appLanguages.deliveryAt[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => this.getShipAtText(v, lang),
      },
      renderCell: (params) => this.getShipAtText(params.row.distShipAt, lang),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      minWidth: 150,
    });

    columns.push({
      field: 'distShipRestLocId',
      headerName: nowrapHeader(appLanguages.deliveringDestination[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => getSelectCustomBodyRender(this.getBrewShipRestLocOptionMap(v), v),
      },
      renderCell: (params) =>
        getSelectCustomBodyRender(
          this.getBrewShipRestLocOptionMap(params.row.distShipRestLocId),
          params.row.distShipRestLocId,
        ),
      flex: 1,
      minWidth: 150,
      sortComparator: (v1, v2, param1, param2) => {
        var rest1: any = this.getBrewShipRestLocOptionMap(v1);
        var rest2: any = this.getBrewShipRestLocOptionMap(v2);
        let p1: any = rest1[v1];
        let p2: any = rest2[v2];

        return p1.localeCompare(p2);
      },
      sortingOrder: ['asc', 'desc'],
    });

    this.state = {
      dialogopenflag: false,
      cancelShipFlag: false,
      filterTimeout: undefined,
      ...this.initialFilters,
      enableFilter: false,
      initialized: false,
      columns,
      serialsList: null,
      serialsTotalCounts: 0,
      refreshData: false,
      count: '',
      rowinfo: null,
      selectedrows: [],
      checked: false,
      searchTimeout: undefined,
      slips_data: [],
    };
  }

  async componentDidMount() {
    const {
      setMasterLocationsQuery,
      getMasterLocations,
      getSerialBrands,
      servers,
      setSerialsQuery,
    } = this.props;
    const query = {
      ...DefaultMasterQuery,
      // This will display the associated restaurants from this distributor user
      showLocationCategory: ECategoryType.DISTRIBUTOR,
    };
    const { user } = servers;
    const { location } = user;
    const orderBy = [
      ['distShipAt', 'DESC'],
      ['code', 'ASC'],
    ];

    const Serialsquery = {
      ...servers.serialsQuery,
      order: orderBy,
      searchText: null,
      where: {
        distShipAt: { $ne: null },
        recvDistLocId: location.id,
      },
      offset: 0,
    };

    this.query = Serialsquery;
    setSerialsQuery(this.query);

    setMasterLocationsQuery(query);
    await getSerialBrands({ ...DefaultMasterQuery });
    await getMasterLocations(query);
    await this.fetchSerials();
  }

  /*fetchSerials(query) {
    const self = this;
    self.query = query;
    const request = functions.httpsCallable('getSerials');
    request(query)
      .then((result) => {
        const { data } = result;
        self.setState({
          initialized: true,
          serialsList: data.objects,
          serialsTotalCounts: data.totalCounts,
        });
      })
      .catch((e) => console.error('error', e));
  }*/

  async fetchSerials() {
    const self = this;
    //const data = getSerials(this.query);
    //console.log('1');
    const request = functions.httpsCallable('getSerials');
    //console.log('2');
    let cancelShipFlag = true;
    const response = await request({
      query: this.query,
      cancelShippingFlagDistributor: cancelShipFlag,
    });
    //console.log('response: ' + JSON.stringify(response, null, 4));
    //console.log('3');

    const { data } = response;
    data.objects = data.objects.filter(function (item) {
      //console.log(item);
      return item.status !== 6;
    });
    //console.log('4');
    self.setState({
      initialized: true,
      serialsList: data.objects,
      serialsTotalCounts: data.totalCounts,
      slips_data: data.objects,
    });
    //console.log('5');
    //.catch((e) => console.error('error', e));
  }

  async fetchSerials_filter(query) {
    const self = this;
    //const data = getSerials(this.query);
    //console.log('1');
    const request = functions.httpsCallable('getSerials');
    //console.log('2');
    let cancelShipFlag = true;
    const response = await request({ query: query, cancelShippingFlagDistributor: cancelShipFlag });
    //console.log('response: ' + JSON.stringify(response, null, 4));
    //console.log('3');

    const { data } = response;
    data.objects = data.objects.filter(function (item) {
      //console.log(item);
      return item.status !== 6;
    });

    //console.log('4');
    self.setState({
      initialized: true,
      serialsList: data.objects,
      serialsTotalCounts: data.totalCounts,
    });
    //console.log('5');
    //.catch((e) => console.error('error', e));
  }

  getSlipText(text) {
    return text ? text : 'N/A';
  }

  getBrandName(code: string, lang: string): string | null {
    const { serialsList } = this.state;
    if (serialsList) {
      const serial = serialsList.find((v) => v.code === code);

      return serial.brand?.name;
    }
  }

  getBrandOptionMap() {
    const { servers } = this.props;
    const optionMap = {};
    servers.serialBrands.map((o) => {
      optionMap[o.id] = o.name;
      return o.id;
    });
    return optionMap;
  }

  getShipAtText(dateString, lang) {
    let shipAtText = appLanguages.unshipped[lang];
    if (dateString) {
      const date = new Date(Date.parse(dateString));
      shipAtText = getFormattedDateString(date);
    } else {
      shipAtText = 'N/A';
    }
    return shipAtText;
  }

  getBrewShipRestLocOptionMap(v) {
    const { locations } = this.props;
    const optionMap = {};
    locations?.forEach((o) => {
      optionMap[o.id] = o.name;
    });
    if (!(v in optionMap)) {
      optionMap[v] = 'N/A';
    }
    return optionMap;
  }

  rowDataToObj(rowData) {
    const { columns } = this.state;
    return rowDataToObj(columns, rowData);
  }

  handleOnChangePage(currentPage) {
    const { limit } = this.pages;
    this.pages.offset = currentPage * limit;
    this.updateQuery({ offset: this.pages.offset, limit: this.pages.limit });
  }

  handleOnChangeRowsPerPage(numberOfRows) {
    this.pages.limit = numberOfRows;
    this.updateQuery({ offset: 0, limit: numberOfRows });
  }

  /*
  handleOnColumnSortChange(changedColumn, direction) {
    const { columns } = this.state;
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.name === changedColumn) {
        if (direction.match(/desc/)) column.options.sortDirection = 'desc';
        else column.options.sortDirection = 'asc';
      } else {
        delete column.options.sortDirection;
      }
    }
    this.updateQuery({
      order: [[changedColumn, direction.match(/desc/) ? 'DESC' : 'ASC']],
      offset: this.pages.offset,
      limit: this.pages.limit,
    });
  }
  */

  clearFilterTimeout() {
    const { filterTimeout } = this.state;
    this.pages.limit = LIMIT_PAGINATION_MAX;
    this.pages.offset = 0;
    if (filterTimeout) clearTimeout(filterTimeout);
  }

  updateQuery(newQuery) {
    const { servers, setSerialsQuery } = this.props;
    const query = { ...this.query, ...newQuery };
    console.log(query);
    setSerialsQuery(query);
    console.log('Query after setSerialsQuery: ' + JSON.stringify(servers.serialsQuery, null, 4));

    this.setState({ initialized: false }, () => {
      this.fetchSerials_filter(query);
    });
  }

  setFilter(newWhere) {
    const { servers } = this.props;
    //const { user } = servers;
    //const { location } = user;
    this.clearFilterTimeout();
    console.log('Query after : ' + JSON.stringify(servers.serialsQuery, null, 4));
    //console.log(servers.serialsQuery.where);
    const where = {
      //distShipAt: { $ne: null },
      //recvDistLocId: location.id,
      ...servers.serialsQuery.where,
      ...newWhere,
    };
    const query = Object.assign({}, this.query, { where });
    this.pages.limit = query['limit'];
    this.pages.offset = query['offset'];
    setSerialsQuery(query);
    //this.updateQuery(query);
    this.setState({ initialized: false }, () => {
      this.fetchSerials_filter(query);
    });
    this.setState({ filterTimeout: null });
  }

  unsetFilter(key) {
    const { servers } = this.props;
    const { where } = servers.serialsQuery;
    if (where[key]) delete where[key];
    this.setFilter(where);
  }

  setFilterOnlyForChangeTime(newWhere) {
    const { servers } = this.props;
    const { user } = servers;
    const { location } = user;
    const where = { recvDistLocId: location.id, ...servers.serialsQuery.where, ...newWhere };
    const query = Object.assign({}, this.query, { where });
    this.pages.limit = query['limit'];
    this.pages.offset = query['offset'];
    this.updateQuery(query);
    this.setState({ filterTimeout: null });
  }

  handleChangeTime(filterKey, dbKey, v) {
    const { servers, setSerialsQuery } = this.props;
    this.clearFilterTimeout();
    const from = v && v.length && new Date(v[0].setHours(0, 0, 0));
    const to = v && v.length && new Date(v[1].setHours(23, 59, 59));
    const newState: Partial<State> = {};

    newState[filterKey] = v;
    this.setState(newState as State);
    // this.setState({
    //   [filterKey]: v,
    //   filterTimeout: setTimeout(
    //     () =>
    //       v.length
    //         ? this.setFilter({
    //             [dbKey]: { $gte: from.toISOString(), $lte: to.toISOString() },
    //           })
    //         : this.unsetFilter(dbKey),
    //     1000,
    //   ),

    // });
    let newWhere = { [dbKey]: { $gte: from.toISOString(), $lte: to.toISOString() } };
    const where = { ...servers.serialsQuery.where, ...newWhere };
    const newquery = Object.assign({}, this.query, { where });
    this.pages.limit = newquery['limit'];
    this.pages.offset = newquery['offset'];
    setSerialsQuery(newquery);
    this.setState({ initialized: false }, () => {
      this.fetchSerials_filter(newquery);
    });
  }

  handleChangeCreatedAt(v) {
    this.handleChangeTime('filterCreatedAt', 'distShipAt', v);
  }

  handleClickResetFilter() {
    this.setState({
      filterCreatedAt: [],
      filterSalesOrder: '',
      filterDestinationId: '',
      filterBrandId: '',
    });
    const { servers } = this.props;
    const { user } = servers;
    const { location } = user;
    const query = {
      ...servers.serialsQuery,
      where: { distShipAt: { $ne: null }, recvDistLocId: location.id },
    };
    //console.log('query after reset :' + JSON.stringify(servers.serialsQuery));
    this.updateQuery(query);
  }

  handleChangeFilterSalesOrder(v) {
    const { servers, setSerialsQuery } = this.props;
    this.clearFilterTimeout();
    this.setState({
      filterSalesOrder: v,
      //filterTimeout: setTimeout(() => this.setFilter({ distSlip: { $like: `%${v}%` } }), 1000),
    });
    let newWhere = { distSlip: { $like: `%${v}%` } };
    const where = { ...servers.serialsQuery.where, ...newWhere };
    const newquery = Object.assign({}, this.query, { where });
    this.pages.limit = newquery['limit'];
    this.pages.offset = newquery['offset'];
    setSerialsQuery(newquery);
    this.setState({ initialized: false }, () => {
      this.fetchSerials_filter(newquery);
    });
  }

  handleChageFilterDestination(v) {
    const { servers, setSerialsQuery } = this.props;
    this.clearFilterTimeout();
    this.setState({
      filterDestinationId: v,
      //filterTimeout: setTimeout(() => this.setFilter({ distShipRestLocId: v }), 1000),
    });
    let newWhere = { distShipRestLocId: v };
    const where = { ...servers.serialsQuery.where, ...newWhere };
    const newquery = Object.assign({}, this.query, { where });
    this.pages.limit = newquery['limit'];
    this.pages.offset = newquery['offset'];
    setSerialsQuery(newquery);
    this.setState({ initialized: false }, () => {
      this.fetchSerials_filter(newquery);
    });
  }

  handleChageFilterBrand(v) {
    const { servers, setSerialsQuery } = this.props;
    this.clearFilterTimeout();
    this.setState({
      filterBrandId: v,
      //filterTimeout: setTimeout(() => this.setFilter({ brandId: v }), 1000),
    });
    let newWhere = { brandId: v };
    const where = { ...servers.serialsQuery.where, ...newWhere };
    const newquery = Object.assign({}, this.query, { where });
    this.pages.limit = newquery['limit'];
    this.pages.offset = newquery['offset'];
    setSerialsQuery(newquery);
    this.setState({ initialized: false }, () => {
      this.fetchSerials_filter(newquery);
    });
  }

  cancelShipping() {
    this.setState((prevState) => ({
      cancelShipFlag: !prevState.cancelShipFlag,
    }));
  }

  colorscheme() {
    return {
      palette: {
        primary: '#ff8d00',
      },
    };
  }

  async handleOnClickDelete(obj) {
    const { delSerial } = this.props;
    await delSerial(obj);
    this.setState({ initialized: false }, () => {
      this.fetchSerials();
    });
  }

  textSearched(text) {
    //DISORIENTED_SERIALS_LISTING - optional callback to handle rendering while using search box
    this.query['searchText'] = text;
    this.setState({ initialized: false }, () => {
      this.pages.limit = LIMIT_PAGINATION_MAX;
      this.pages.offset = 0;
      const query = Object.assign({}, this.query, this.pages);
      this.updateQuery(query);
    });
  }

  refreshData() {
    //DISORIENTED_SERIALS_LISTING - optional callback to handle rendering when refresh button clicked.
    disableConfirm = true;
    isRowSelectableFlag = false;
    this.setState({ initialized: false }, () => {
      this.pages.limit = 100000000;
      this.pages.offset = 0;
      const query = Object.assign({}, this.query, this.pages);
      this.updateQuery(query);
    });
    setTimeout(function () {
      isRowSelectableFlag = true;
    }, 500);
    //this.render();
  }

  //From custom toolbar script

  handleChangeSearch(e) {
    this.props.setSerialCustomToolbarSearchKeyword(String(e.target.value));
    this.handleClickSearch();
  }

  handleKeyPressSearch(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleClickSearch();
    }
  }

  handleClickSearch() {
    const { searchTimeout } = this.state;
    console.log('ClickSearch');
    //console.log(`${searchKeyword}`)
    if (searchTimeout) clearTimeout(searchTimeout);
    const timeout = setTimeout(() => {
      const { servers, setSerialsQuery, getSerials, getSerialSlips } = this.props;
      const {
        apps: {
          serialCustomToolbarState: { searchKeyword },
        },
      } = this.props;
      const query = {
        ...servers.serialsQuery,
        searchText: searchKeyword,
      };
      getSerials(query);
      getSerialSlips(query);
      setSerialsQuery(query);
      this.setState({ searchTimeout: undefined }, () => {
        //const { textSearched } = this.props;
        console.log(searchKeyword);

        this.textSearched(searchKeyword);
      });
    }, 600);
    this.setState({ searchTimeout: timeout });
  }

  handleCloseSearchBox() {
    this.props.setSerialCustomToolbarSearchKeyword('');
    this.handleClickSearch();
    this.props.toggleSerialCustomToolbarSearchBox(false);
  }

  handleOpenSearchBox() {
    this.props.toggleSerialCustomToolbarSearchBox(true);
  }

  handleOnClickEdit(obj) {
    const { setEditSerial, openSerialEditDialog } = this.props;
    setEditSerial(obj);
    openSerialEditDialog(obj);
  }

  handleClickEdit(obj) {
    const { servers, openSerialEditDialog, getSerialBrands } = this.props;
    const { user } = servers;
    const { location } = user;
    getSerialBrands({ ...DefaultMasterQuery, where: { locationId: location.id } });
    openSerialEditDialog(obj);
  }

  async handleDelete(rowinfo) {
    this.closeDialog();
    this.render();
    const { registerSerials } = this.props;
    //const { user } = servers;
    //const { location } = user;
    //console.log(rowinfo);
    //console.log('Submit and cancel');
    await registerSerials({ action: SERIAL_ACTION.cancel, serials: rowinfo });

    this.handleClickReload();
  }

  async handleClickReload() {
    console.log('ClickReload');
    disableConfirm = true;
    isRowSelectableFlag = false;
    //const { servers, getSerials } = this.props;
    //await getSerials(servers.serialsQuery);
    this.render();
    this.refreshData();
  }

  closeDialog = () => {
    this.setState({ dialogopenflag: false });
    disableConfirm = false;
    isRowSelectableFlag = true;
  };

  opendialog() {
    this.setState({ dialogopenflag: true });
    disableConfirm = true;
    isRowSelectableFlag = false;
  }

  render() {
    const {
      servers,
      apps,
      classes,
      apps: {
        serialCustomToolbarState: { searchKeyword, openSearchBox },
      },
    } = this.props;
    const {
      initialized,
      columns,
      serialsList,
      //serialsTotalCounts,
      enableFilter,
      filterCreatedAt,
      filterSalesOrder,
      filterDestinationId,
      filterBrandId,
    } = this.state;
    //const currentPage = this.pages.offset / this.pages.limit;
    //const numberOfRows = this.pages.limit;
    const lang = apps.currentLanguage;
    const { searchTimeout } = this.state;
    const rowData = serialsList || [];
    let filter_row_data = [];
    let results = [];
    for (var key in rowData) {
      for (var secondKey in rowData[key]) {
        if (secondKey === 'id') {
          results.push(rowData[key][secondKey]);
        }
        if (secondKey === 'status') {
          //console.log(rowData[key][secondKey]);
          if (rowData[key][secondKey] === 3) filter_row_data.push(rowData[key]);
        }
      }
    }

    const defaultRest = 0;
    let restValue = { id: defaultRest, name: '' };
    const rests = servers.locations?.map((locObj) => {
      let displayLabel = locObj.name;
      if (locObj.id === filterDestinationId) restValue = { ...locObj };
      displayLabel = `${locObj?.name}`;
      return { ...locObj, displayLabel };
    });

    let brandValue = { id: 0, name: '' };
    const brands = servers.serialBrands?.map((brandObj) => {
      let displayLabel = brandObj.name;
      if (brandObj.id === filterBrandId) brandValue = { ...brandObj };
      displayLabel = `${brandObj?.name}`;
      return { ...brandObj, displayLabel };
    });

    const handleCheckClick = () => {
      this.setState({ checked: !this.state.checked }, () => {
        //console.log(this.state.checked);
      });

      !this.state.checked
        ? this.setState({ selectedrows: results }, () => {
            //console.log(this.state.selectedrows);
          })
        : this.setState({ selectedrows: [] }, () => {
            //console.log(this.state.selectedrows);
          });
    };

    const filtersView = (
      <>
        <Grid container alignItems='flex-end'>
          {getDateRangePicker(
            appLanguages.deliveryAt[lang],
            filterCreatedAt,
            (v) => this.handleChangeCreatedAt(v),
            lang,
          )}
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {getSmallTextField(appLanguages.slipNum[lang], filterSalesOrder, false, (e) =>
              this.handleChangeFilterSalesOrder(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {getAutocomplete(
              appLanguages.deliveringDestination[lang],
              `${appLanguages.pleaseSelect[lang]}`,
              true,
              { ...restValue },
              [...rests],
              (e, v) => this.handleChageFilterDestination(v ? v.id : defaultRest),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {getAutocomplete(
              appLanguages.brand[lang],
              `${appLanguages.pleaseSelect[lang]}`,
              true,
              { ...brandValue },
              [...brands],
              (e, v) => this.handleChageFilterBrand(v ? v.id : 0),
            )}
          </Grid>
        </Grid>
        {filterCreatedAt.length || filterSalesOrder || filterDestinationId || filterBrandId ? (
          <Grid container justify='flex-end' alignItems='flex-end'>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
            <Grid item>
              {getSmallClearButton(appLanguages.reset[lang], false, () =>
                this.handleClickResetFilter(),
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </>
    );

    const isRequestingg =
      servers.isRequesting || servers.isGetRequesting || Boolean(searchTimeout) || !servers?.user;
    const searchBoxView = (
      <>
        <TextField
          fullWidth
          autoFocus
          className={classes.margin}
          value={searchKeyword}
          onChange={(e) => this.handleChangeSearch(e)}
          onKeyPress={(e) => this.handleKeyPressSearch(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment onClick={() => this.handleClickSearch()} position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {isRequestingg ? (
                  <IconButton size='small'>
                    <CircularProgress size={16} color='primary' />
                  </IconButton>
                ) : (
                  ''
                )}
                <InputAdornment onClick={() => this.handleCloseSearchBox()} position='start'>
                  <ClearIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      </>
    );

    const NewToolbar = () => {
      return (
        <>
          <Grid container justify='flex-end' alignContent='center' alignItems='center'>
            <Grid item>
              {this.state.cancelShipFlag ? (
                <Box mr={6}>
                  <Checkbox checked={this.state.checked} onChange={handleCheckClick} />{' '}
                  {appLanguages.selectAll[lang]}
                </Box>
              ) : null}
            </Grid>
            <Grid item>
              {!this.state.cancelShipFlag ? (
                <Box mr={2}>
                  <MuiThemeProvider theme={orangeTheme}>
                    <Button
                      id='cancelShip'
                      variant='outlined'
                      style={{ float: 'right', marginTop: 0 }}
                      color='primary'
                      hidden={this.state.cancelShipFlag}
                      onClick={() => this.cancelShipping()}
                    >
                      {appLanguages.selectBottleToCancelShipping[lang]}
                    </Button>
                  </MuiThemeProvider>
                </Box>
              ) : null}
            </Grid>
            <Grid item>
              {this.state.cancelShipFlag ? (
                <MuiThemeProvider theme={orangeTheme}>
                  <Box mr={2}>
                    <Button
                      id='cancelShipButton2'
                      variant='contained'
                      style={{ float: 'right', marginTop: 0, marginRight: 0 }}
                      color='primary'
                      hidden={!this.state.cancelShipFlag}
                      onClick={() => this.opendialog()}
                      disabled={
                        this.state.selectedrows.length === 0 || disableConfirm === true
                          ? true
                          : false
                      }
                    >
                      {appLanguages.confirmCancelShipping[lang]}
                    </Button>
                  </Box>
                </MuiThemeProvider>
              ) : null}
              <Dialog
                open={this.state.dialogopenflag}
                onClose={this.closeDialog}
                fullWidth
                maxWidth='xs'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>
                  {appLanguages.cancelShippingDialogTitle[lang]}
                </DialogTitle>
                <DialogContent id='alert-dialog-description'>
                  <Box fontSize={14}>{appLanguages.cancelShippingDialogBody[lang]}</Box>
                  <Box mb={2} />
                </DialogContent>
                <DialogActions>
                  <Grid container spacing={8} justify='flex-end'>
                    <Grid item xs={2} />
                    <Grid item xs={1}>
                      <Button
                        style={{ float: 'right', height: 30, marginTop: 0 }}
                        fullWidth={false}
                        variant='text'
                        color='default'
                        onClick={this.closeDialog}
                      >
                        {appLanguages.no[lang]}
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        style={{ float: 'right', marginTop: 0 }}
                        fullWidth={false}
                        variant='text'
                        color='primary'
                        onClick={() => {
                          //console.log('Save and submit');
                          disableConfirm = true;
                          this.handleDelete(this.state.rowinfo);
                        }}
                      >
                        {appLanguages.yes[lang]}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item>
              {this.state.cancelShipFlag ? (
                <MuiThemeProvider theme={greyTheme}>
                  <Box mr={2}>
                    <Button
                      id='cancelShipButton1'
                      variant='contained'
                      style={{ float: 'left', marginTop: 0 }}
                      color='primary'
                      hidden={!this.state.cancelShipFlag}
                      onClick={() => this.cancelShipping()}
                    >
                      {appLanguages.undo[lang]}
                    </Button>
                  </Box>
                </MuiThemeProvider>
              ) : null}
            </Grid>

            <Zoom timeout={300} in={openSearchBox}>
              {openSearchBox ? (
                <Grid item xs>
                  {searchBoxView}
                </Grid>
              ) : (
                <Box />
              )}
            </Zoom>
            {openSearchBox ? (
              <Grid>
                <Box ml={3} />
              </Grid>
            ) : (
              ''
            )}
            <Zoom timeout={300} in={!openSearchBox}>
              {!openSearchBox ? (
                <Grid item>
                  <Tooltip title={appLanguages.search[lang]}>
                    <IconButton onClick={() => this.handleOpenSearchBox()}>
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <Box />
              )}
            </Zoom>

            <Grid item>
              <Tooltip title={'Reload'}>
                <IconButton onClick={() => this.handleClickReload()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      );
    };

    ///// 言語設定でソートメニューオプション
    if (lang === 'en') {
      SortAsc = 'Ascending';
      SortDesc = 'Descending';
      ShowColumns = 'Show Columns';
      Hide = 'Hide';
    } else if (lang == 'ja') {
      SortAsc = '昇順';
      SortDesc = '降順';
      ShowColumns = '表示列を選択';
      Hide = 'この列を隠す';
    } else if (lang == 'ko') {
      SortAsc = '오름차순';
      SortDesc = '내림차순';
      ShowColumns = '열 표시';
      Hide = '숨다';
    } else if (lang == 'zhcn' || lang == 'zhtw') {
      SortAsc = '上升';
      SortDesc = '降序';
      ShowColumns = '显示列';
      Hide = '隐藏';
    } else if (lang == 'th') {
      SortAsc = 'เรียงจากน้อยไปมาก';
      SortDesc = 'เรียงจากมากไปน้อย';
      ShowColumns = 'แสดงคอลัมน์';
      Hide = 'ซ่อน';
    } else if (lang == 'vi') {
      SortAsc = 'Tăng dần';
      SortDesc = 'Giảm dần';
      ShowColumns = 'Hiển thị cột';
      Hide = 'Ẩn';
    }

    const localizedTextsMap = {
      columnMenuSortAsc: SortAsc,
      columnMenuSortDesc: SortDesc,
      columnMenuShowColumns: ShowColumns,
      columnMenuHideColumn: Hide,
      columnMenuUnsort: '',
    };

    /* 参考: https://github.com/gregnb/mui-datatables */
    const isRequesting = servers.isRequesting || servers.isGetRequesting || !initialized;

    return (
      <>
        {isRequesting ? <LinearProgress /> : <WhiteLinearProgress />}
        <MuiThemeProvider theme={appMuiTheme}>
          {
            <SummaryFunctionDistributor
              serialsList={this.state.cancelShipFlag ? filter_row_data : serialsList || []}
            />
          }
          <Paper
            elevation={enableFilter ? 2 : 0}
            className={enableFilter ? classes.paper : classes.none}
          >
            <Grid item style={{ paddingTop: 25, paddingBottom: 20, paddingLeft: 15 }}>
              <h4>{appLanguages.allSerials[lang]}</h4>{' '}
            </Grid>
            <Grid item style={{ paddingLeft: 15 }}>
              {getFilterButton(
                enableFilter,
                () =>
                  this.setState({ enableFilter: !enableFilter }, () => {
                    window.scrollTo(0, 0);
                  }),
                lang,
              )}
              {enableFilter ? filtersView : ''}
            </Grid>
          </Paper>
          {enableFilter ? <Box mt={1} /> : ''}
          <div style={{ height: 640, overflow: 'auto', paddingLeft: 15, paddingRight: 15 }}>
            <DataGrid
              sx={{
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                  display: 'none',
                },
              }}
              rows={this.state.cancelShipFlag ? filter_row_data : serialsList || []}
              columns={columns}
              rowsPerPageOptions={[50, 100, 200]}
              checkboxSelection={this.state.cancelShipFlag ? true : false}
              disableSelectionOnClick={false}
              localeText={localizedTextsMap}
              disableColumnFilter={true}
              components={{
                Toolbar: NewToolbar,
              }}
              isRowSelectable={isRowSelectableFlag ? () => true : () => false}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rowData.filter((row) => selectedIDs.has(row.id));

                this.setState({ rowinfo: selectedRows });
                this.setState({ selectedrows: ids });
                disableConfirm = false;
                //console.log('Selected rows: ' + this.state.selectedrows);
              }}
              selectionModel={this.state.selectedrows}
            />
          </div>
        </MuiThemeProvider>
        {apps.isOpenSerialEditDialog ? (
          <SerialEditDialog
            cbEdited={() => {
              this.setState({ initialized: false }, () => this.updateQuery(this.query));
            }}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  locations: any;
  classes: any;
  textSearched?: any;
  refreshData?: any;
  showAddSerialButton?: boolean;
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
}

interface State {
  dialogopenflag: boolean;
  cancelShipFlag: boolean;
  filterTimeout: any;
  enableFilter: boolean;
  initialized: boolean;
  columns: GridColDef[];
  serialsList: any;
  serialsTotalCounts: number;
  filterCreatedAt: any;
  filterSalesOrder: any;
  filterDestinationId: any;
  filterBrandId: any;
  refreshData: any;
  count: any;
  rowinfo: any;
  selectedrows: any;
  checked: boolean;
  searchTimeout: any;
  slips_data: any;
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
  getMasterLocations: (query: IQuery) => void;
  setMasterLocationsQuery: (query: IQuery) => void;
  getSerialBrands: (query: IQuery) => void;
  toggleSerialCustomToolbarSearchBox: (value: boolean) => void;
  openSerialEditDialog: (serialObj: ISerial) => void;
  setEditSerial: (serialObj: ISerial) => void;
  setSerialCustomToolbarSearchKeyword: (keyword: string) => void;
  getSerials: (query: IQuery) => void;
  getSerialSlips: (query: IQuery) => void;
  delSerial: (serialObj: ISerial) => void;
  confirm?: () => void;
  registerSerials: (serialObj: any, successMessage?: any) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchProps: IDispatchProps = {
  setSerialsQuery,
  getMasterLocations,
  setMasterLocationsQuery,
  getSerialBrands,
  setEditSerial,
  toggleSerialCustomToolbarSearchBox,
  openSerialEditDialog,
  setSerialCustomToolbarSearchKeyword,
  getSerials,
  getSerialSlips,
  delSerial,
  registerSerials,
};

const myStyles = (theme: Theme): StyleRules => ({
  root: { width: '100%' },
  button: { marginTop: theme.spacing(1), marginRight: theme.spacing(1) },
  actionsContainer: { marginBottom: theme.spacing(2) },
  resetContainer: { padding: theme.spacing(3) },
  paper: { padding: theme.spacing(2) },
});

export const SakeShippedForDistributor = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchProps),
)(SakeShippedForDistributorClass);
