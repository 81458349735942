/*
 DISORIENTED_SERIALS_LISTING
 Sreenath 3Nov2021 - Disoriented Serials Listing.
 - Fetched the serials exclusively
 - removed getBrand API, made use of getSerials to populate brand name
 -removed getLocations API , passed locations props from parent component for populating location name.
*/

import { store } from '@/redux/reducers';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  StyleRules,
  TextField,
  Theme,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
  Clear as ClearIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { IQuery } from '@models/query';
import { ISerial } from '@models/serial';
//import { ISerials } from '@models/serials';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
//Grid libraries and packages
import { DataGrid } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import {
  delSerial,
  getSerialBrands,
  getSerials,
  getSerialSlips,
  registerSerialsCancelShipping,
  setSerialsQuery,
} from '@redux/actions/serversActions';
import appLanguages from '@utils/app-languages';
import {
  getAutocomplete,
  getDateRangePicker,
  getFilterButton,
  getFormattedDateString,
  getSmallClearButton,
  getSmallTextField,
  nowrapHeader,
  SERIAL_ACTION,
} from '@utils/common';

import { DefaultMasterQuery } from '@utils/default-query';
import { functions } from '@utils/firebase';
import { formatString } from '@utils/format-string';
import {
  appMuiTheme,
  getDeleteActionsRender,
  getEditActionsRender,
  getSelectCustomBodyRender,
  getWarningDeleteActionsRender,
  getWarningEditActionsRender,
  rowDataToObj,
} from '@utils/mui-datatables-ops';
import React from 'react';
import { connect } from 'react-redux';

import { IStateApps } from 'models/state-app';
import { compose } from 'redux';

import WhiteLinearProgress from '../../../elements/WhiteLinearProgress';
import {
  openSerialEditDialog,
  openSnackbarMessage,
  setEditSerial,
  setSerialCustomToolbarSearchKeyword,
  toggleSerialCustomToolbarSearchBox,
} from '../../../redux/actions/appsActions';
import { SerialEditDialog } from '../serials/SerialEditDialog';
import { SummaryFunction } from './SummaryFunctionBrewery';

const orangeTheme = createMuiTheme({ palette: { primary: deepOrange } });
const greyTheme = createMuiTheme({ palette: { primary: { main: '#616161' } } });
let disableConfirm = true;
let isRowSelectableFlag = true;
let SortAsc = '';
let SortDesc = '';
let ShowColumns = '';
let Hide = '';

class SakeShippedForBreweryClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = { showAddSerialButton: true };
  private initialFilters = {
    filterBrewShipAt: [],
    filterSalesOrder: '',
    filterDestinationId: '',
    filterDistDestinationId: '',
    filterBrandId: '',
  };
  public query = {};
  public pages = {
    offset: 0,
    limit: 100000000,
  };

  constructor(props) {
    super(props);
    const { apps, servers, setSerialsQuery } = this.props;
    const lang = apps.currentLanguage;
    const { user } = servers;
    const { location } = user;

    const orderBy = [
      ['brewShipAt', 'DESC'],
      ['code', 'ASC'],
    ];

    const query = {
      ...servers.serialsQuery,
      order: orderBy,
      searchText: null,
      where: {
        brewShipAt: { $ne: null },
        brewLocId: location.id,
      },
      offset: 0,
      limit: 1000000,
    };
    this.query = query;
    setSerialsQuery(this.query);

    const columns = [];

    /*columns.push({
      field: 'id',
      options: { ...disableDisplayOpts },
    });
    */

    columns.push({
      field: 'code',
      headerName: nowrapHeader(appLanguages.serialCode[lang]),
      flex: 1,

      sortingOrder: ['asc', 'desc'],
      minWidth: 150,
    });

    columns.push({
      field: 'brandId',
      headerName: nowrapHeader(appLanguages.brand[lang]),
      renderCell: (params) =>
        getSelectCustomBodyRender(this.getBrandOptionMap(), params.row.brandId),
      flex: 1,
      minWidth: 150,
      sortComparator: (v1, v2) => {
        var rest1: any = this.getBrandOptionMapSort(v1);
        var rest2: any = this.getBrandOptionMapSort(v2);
        return rest1.localeCompare(rest2);
      },
      sortingOrder: ['asc', 'desc'],
    });

    columns.push({
      field: 'slip',
      headerName: nowrapHeader(appLanguages.slipNum[lang]),

      renderCell: (params) => this.getSlipText(params.row.slip),

      flex: 1,
      minWidth: 150,
      sortComparator: (v1, v2) => {
        return v1.localeCompare(v2);
      },
      sortingOrder: ['asc', 'desc'],
    });

    columns.push({
      field: 'brewShipAt',
      headerName: nowrapHeader(appLanguages.shipAt[lang]),
      renderCell: (params) => this.getShipAtText(params.row.brewShipAt, lang),
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      minWidth: 150,
    });

    columns.push({
      field: 'brewShipDistLocId',
      headerName: nowrapHeader(appLanguages.shippingDestination[lang]),
      renderCell: (params) => {
        let x = params.row.brewShipRestLocId || params.row.brewShipDistLocId;
        return getSelectCustomBodyRender(this.getBrewShipRestLocOptionMap(x), x);
      },
      flex: 1,
      minWidth: 150,
      sortComparator: (v1, v2, param1, param2) => {
        var rest1: any = this.getBrewShipRestLocOptionMap(v1);
        var rest2: any = this.getBrewShipRestLocOptionMap(v2);
        let p1: any = rest1[v1];
        let p2: any = rest2[v2];

        return p1.localeCompare(p2);
      },
      sortingOrder: ['asc', 'desc'],
    });

    columns.push({
      field: 'brewShipRestLocId',
      headerName: nowrapHeader(appLanguages.restaurant2[lang]),
      renderCell: (params) =>
        getSelectCustomBodyRender(
          this.getBrewShipRestLocOptionMap(params.row.brewShipRestLocId),
          params.row.brewShipRestLocId,
        ),
      flex: 1,
      minWidth: 150,
      hide: true,
      sortComparator: (v1, v2, param1, param2) => {
        var rest1: any = this.getBrewShipRestLocOptionMap(v1);
        var rest2: any = this.getBrewShipRestLocOptionMap(v2);
        let p1: any = rest1[v1];
        let p2: any = rest2[v2];

        return p1.localeCompare(p2);
      },
      sortingOrder: ['asc', 'desc'],
    });

    columns.push({
      field: 'recvDistLocId',
      headerName: 'RecvDistLocId',
      flex: 1,
      hide: true,
    });

    /*
    columns.push({
      name: '',
      options: {
        ...enableEmptyOpts,
        customBodyRender: (_, mt) => this.getActionsRender(mt),
      },
    });
    */

    this.state = {
      dialogopenflag: false,
      cancelShipFlag: false,
      enableFilter: false,
      filterTimeout: undefined,
      initialized: false,
      ...this.initialFilters,
      columns,
      serialsList: null,
      serialsTotalCounts: 0,
      searchTimeout: undefined,
      count: '',
      refreshData: false,
      rowinfo: null,
      selectedrows: [],
      checked: false,
      slips_data: [],
    };
  }

  customSortAsc = (a, b) => {
    if (a === '') return 1;
    if (b === '') return -1;
    if (a > b) return 1;
    if (a < b) return -1;
    if (a === b) return 0;
  };

  async componentDidMount() {
    this.fetchSerials();
  }

  async fetchSerials() {
    const self = this;
    //const data = getSerials(this.query);

    const request = functions.httpsCallable('getSerials');

    let cancelShipFlag = true;
    const response = await request({ query: this.query, cancelShippingFlag: cancelShipFlag });
    //console.log('response: ' + JSON.stringify(response, null, 4));

    const { data } = response;
    data.objects = data.objects.filter(function (item) {
      console.log(item);
      return item.status !== 6;
    });

    self.setState({
      initialized: true,
      serialsList: data.objects,
      serialsTotalCounts: data.totalCounts,
      slips_data: data.objects,
    });
    //.catch((e) => console.error('error', e));
  }

  async fetchSerials_filter(query) {
    const self = this;
    //const data = getSerials(this.query);

    const request = functions.httpsCallable('getSerials');

    let cancelShipFlag = true;
    const response = await request({ query: query, cancelShippingFlag: cancelShipFlag });
    //console.log('response: ' + JSON.stringify(response, null, 4));

    const { data } = response;
    data.objects = data.objects.filter(function (item) {
      console.log(item);
      return item.status !== 6;
    });

    self.setState({
      initialized: true,
      serialsList: data.objects,
      serialsTotalCounts: data.totalCounts,
    });
    //.catch((e) => console.error('error', e));
  }

  getSlipText(text) {
    return text ? text : 'N/A';
  }

  getBrandOptionMap() {
    const { servers } = this.props;
    const optionMap = {};
    servers.serialBrands.map((o) => {
      optionMap[o.id] = o.name;
      return o.id;
    });
    //console.log(optionMap);
    return optionMap;
  }

  getBrandOptionMapSort(v) {
    const { servers } = this.props;
    const optionMap = {};
    servers.serialBrands.map((o) => {
      optionMap[o.id] = o.name;
    });
    //console.log(optionMap);
    return optionMap[v];
  }

  getShipAtText(dateString, lang) {
    let shipAtText = appLanguages.unshipped[lang];
    if (dateString) {
      const date = new Date(Date.parse(dateString));
      shipAtText = getFormattedDateString(date);
    } else {
      shipAtText = 'N/A';
    }
    return shipAtText;
  }

  getBrewShipRestLocOptionMap(v) {
    const { servers } = this.props;
    const optionMap = {};
    servers?.locations?.forEach((o) => {
      optionMap[o.id] = o.name;
    });
    if (!(v in optionMap)) {
      optionMap[v] = 'N/A';
    }
    return optionMap;
  }

  rowDataToObj(rowData) {
    const { columns } = this.state;
    return rowDataToObj(columns, rowData);
  }

  updateQuery(newQuery) {
    const { setSerialsQuery } = this.props;
    const query = { ...this.query, ...newQuery };
    this.setState({ initialized: false }, () => {
      setSerialsQuery(query);
      this.fetchSerials_filter(query);
    });
  }

  handleOnChangePage(currentPage) {
    const { limit } = this.pages;
    this.pages.offset = currentPage * limit;
    this.updateQuery({ offset: this.pages.offset, limit: this.pages.limit });
  }

  handleOnChangeRowsPerPage(numberOfRows) {
    this.pages.limit = numberOfRows;
    this.updateQuery({ offset: 0, limit: numberOfRows });
  }

  handleOnClickEdit(obj) {
    const { setEditSerial, openSerialEditDialog } = this.props;
    setEditSerial(obj);
    openSerialEditDialog(obj);
  }

  getEditActionsRender(tableMeta) {
    const {
      servers,
      openSnackbarMessage,
      apps: { currentLanguage },
    } = this.props;
    const obj = this.rowDataToObj(tableMeta.rowData);
    const isRequesting = servers ? servers.isRequesting : true;
    const onClickEdit = (o) => this.handleOnClickEdit(o);
    const { columnIndex, rowData } = tableMeta;
    const invalid = rowData[columnIndex - 1]; // brewShipAt
    return (
      <>
        {invalid
          ? getWarningEditActionsRender(() =>
              openSnackbarMessage(
                'error',
                appLanguages.cannotUpdateDueToBreweryProcedures[currentLanguage],
              ),
            )
          : getEditActionsRender(obj, isRequesting, onClickEdit)}
      </>
    );
  }

  async handleOnClickDelete(obj) {
    const { delSerial } = this.props;
    await delSerial(obj);
    this.setState({ initialized: false }, () => {
      this.fetchSerials();
    });
  }

  getDeleteActionsRender(tableMeta) {
    const {
      servers,
      confirm,
      openSnackbarMessage,
      apps: { currentLanguage },
    } = this.props;
    const obj = this.rowDataToObj(tableMeta.rowData);
    const isRequesting = servers ? servers.isRequesting : true;
    const lang = store.getState().apps.currentLanguage;
    const delTitle = appLanguages.serialDelete[lang];
    const delDesc = formatString(appLanguages.confirmSerialDelete[lang], { field: obj['code'] });
    const onClickDelete = (o) => this.handleOnClickDelete(o);
    const { columnIndex, rowData } = tableMeta;
    const invalid = rowData[columnIndex - 1]; // brewShipAt
    return (
      <>
        {invalid
          ? getWarningDeleteActionsRender(() =>
              openSnackbarMessage(
                'error',
                appLanguages.cannotUpdateDueToBreweryProcedures[currentLanguage],
              ),
            )
          : getDeleteActionsRender(obj, delTitle, delDesc, isRequesting, onClickDelete, confirm)}
      </>
    );
  }
  /*
  getActionsRender(tableMeta) {
    return (
      <>
        <Grid container wrap='nowrap'>
          <Grid item>{this.getEditActionsRender(tableMeta)}</Grid>
          <Grid item>{this.getDeleteActionsRender(tableMeta)}</Grid>
        </Grid>
      </>
    );
  }
  */

  clearFilterTimeout() {
    const { filterTimeout } = this.state;
    this.pages.limit = 100000000;
    this.pages.offset = 0;
    if (filterTimeout) clearTimeout(filterTimeout);
  }

  setFilter(newWhere) {
    const { servers } = this.props;
    const where = { ...servers.serialsQuery.where, ...newWhere };
    const query = Object.assign({}, this.query, { where });
    this.pages.limit = query['limit'];
    this.pages.offset = query['offset'];
    this.updateQuery(query);
    this.setState({ filterTimeout: null });
  }

  unsetFilter(key) {
    const { servers } = this.props;
    const { where } = servers.serialsQuery;
    if (where[key]) delete where[key];
    this.setFilter(where);
  }

  handleChangeTime(filterKey, dbKey, v) {
    this.clearFilterTimeout();
    const from = v && v.length && new Date(v[0].setHours(0, 0, 0));
    const to = v && v.length && new Date(v[1].setHours(23, 59, 59));
    const newState: Partial<State> = {};

    newState[filterKey] = v;

    newState.filterTimeout = setTimeout(() => {
      if (v.length) {
        this.setFilter({ [dbKey]: { $gte: from.toISOString(), $lte: to.toISOString() } });
      } else {
        this.unsetFilter(dbKey);
      }
    }, 1000);

    this.setState(newState as State);
  }

  handleChangeBrewShipAt(v) {
    this.handleChangeTime('filterBrewShipAt', 'brewShipAt', v);
  }

  handleClickResetFilter() {
    this.setState({
      filterBrewShipAt: [],
      filterSalesOrder: '',
      filterBrandId: '',
      filterDestinationId: '',
    });
    const { servers } = this.props;
    const { user } = servers;
    const { location } = user;
    const query = {
      ...servers.serialsQuery,
      where: { brewShipAt: { $ne: null }, brewLocId: location.id },
    };
    //console.log('query after reset :' + JSON.stringify(servers.serialsQuery));
    this.updateQuery(query);
  }

  handleChangeFilterSalesOrder(v) {
    this.clearFilterTimeout();
    this.setState({
      filterSalesOrder: v,
      filterTimeout: setTimeout(() => this.setFilter({ slip: { $like: `%${v}%` } }), 1000),
    });
  }

  handleChageFilterBrand(v) {
    this.clearFilterTimeout();
    this.setState({
      filterBrandId: v,
      filterTimeout: setTimeout(() => this.setFilter({ brandId: v }), 1000),
    });
  }

  handleChageFilterDestination(v) {
    this.clearFilterTimeout();
    this.setState({
      filterDestinationId: v,
      filterTimeout: setTimeout(() => this.setFilter({ distShipRestLocId: v }), 1000),
    });
  }

  handleChageFilterDistDestination(v) {
    this.clearFilterTimeout();
    this.setState({
      filterDestinationId: v,
      filterTimeout: setTimeout(() => this.setFilter({ brewShipDistLocId: v }), 1000),
    });
  }

  cancelShipping() {
    this.setState((prevState) => ({
      cancelShipFlag: !prevState.cancelShipFlag,
    }));
  }

  colorscheme() {
    return {
      palette: {
        primary: '#ff8d00',
      },
    };
  }

  textSearched(text) {
    //DISORIENTED_SERIALS_LISTING - optional callback to handle rendering while using search box
    this.query['searchText'] = text;
    this.setState({ initialized: false }, () => {
      this.pages.limit = 1000000;
      this.pages.offset = 0;
      const query = Object.assign({}, this.query, this.pages);
      this.updateQuery(query);
    });
  }

  //From custom toolbar script

  handleChangeSearch(e) {
    this.props.setSerialCustomToolbarSearchKeyword(String(e.target.value));
    this.handleClickSearch();
  }

  handleKeyPressSearch(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleClickSearch();
    }
  }

  handleClickSearch() {
    const { searchTimeout } = this.state;
    if (searchTimeout) clearTimeout(searchTimeout);
    const timeout = setTimeout(() => {
      const { servers, setSerialsQuery, getSerials, getSerialSlips } = this.props;
      const {
        apps: {
          serialCustomToolbarState: { searchKeyword },
        },
      } = this.props;
      const query = {
        ...servers.serialsQuery,
        searchText: searchKeyword,
      };
      getSerials(query);
      getSerialSlips(query);
      setSerialsQuery(query);
      this.setState({ searchTimeout: undefined }, () => {
        //const { textSearched } = this.props;

        this.textSearched(searchKeyword);
      });
    }, 600);
    this.setState({ searchTimeout: timeout });
  }

  handleCloseSearchBox() {
    this.props.setSerialCustomToolbarSearchKeyword('');
    this.handleClickSearch();
    this.props.toggleSerialCustomToolbarSearchBox(false);
  }

  handleOpenSearchBox() {
    this.props.toggleSerialCustomToolbarSearchBox(true);
  }

  handleClickEdit(obj) {
    const { servers, openSerialEditDialog, getSerialBrands } = this.props;
    const { user } = servers;
    const { location } = user;
    getSerialBrands({ ...DefaultMasterQuery, where: { locationId: location.id } });
    openSerialEditDialog(obj);
  }

  async handleDelete(rowinfo) {
    this.closeDialog();
    this.render();
    const { registerSerialsCancelShipping } = this.props;
    //const { user } = servers;
    //const { location } = user;
    //console.log('Submit and cancel');
    await registerSerialsCancelShipping({ action: SERIAL_ACTION.cancel, serials: rowinfo });
    console.log('Successfully cancelled');
    this.handleClickReload();
  }

  async handleClickReload() {
    console.log('ClickReload');
    disableConfirm = true;
    isRowSelectableFlag = false;
    //const { servers, getSerials } = this.props;
    //await getSerials(servers.serialsQuery);
    //this.fetchSerials(this.query);
    this.render();
    this.refreshData();
  }

  refreshData() {
    //DISORIENTED_SERIALS_LISTING - optional callback to handle rendering when refresh button clicked.
    disableConfirm = true;
    isRowSelectableFlag = false;
    this.setState({ initialized: false }, () => {
      this.pages.limit = 100000000;
      this.pages.offset = 0;
      const query = Object.assign({}, this.query, this.pages);
      this.updateQuery(query);
    });
    setTimeout(function () {
      isRowSelectableFlag = true;
    }, 1000);
    //this.render();
  }

  closeDialog = () => {
    this.setState({ dialogopenflag: false });
    disableConfirm = false;
    isRowSelectableFlag = true;
    this.setState({ initialized: false }, () => {
      this.pages.limit = 100000000;
      this.pages.offset = 0;
      const query = Object.assign({}, this.query, this.pages);
      this.updateQuery(query);
    });
  };

  opendialog() {
    this.setState({ dialogopenflag: true });
    disableConfirm = true;
    isRowSelectableFlag = false;
  }

  brandid(params) {
    getSelectCustomBodyRender(this.getBrandOptionMap(), params.row.brandId);
  }

  render() {
    const {
      apps,
      servers,
      classes,
      apps: {
        serialCustomToolbarState: { searchKeyword, openSearchBox },
      },
    } = this.props;
    const {
      initialized,
      columns,
      serialsList,
      filterBrewShipAt,
      filterSalesOrder,
      filterBrandId,
      filterDestinationId,
      filterDistDestinationId,
      enableFilter,
      rowinfo,
    } = this.state;

    //const { offset, limit } = servers.serialsQuery;
    //const currentPage = offset / limit;
    //const numberOfRows = limit;
    const lang = apps.currentLanguage;
    const { searchTimeout } = this.state;
    const rowData = serialsList || [];
    let filter_row_data = [];
    //let filter_row_id = [];
    let results = [];
    for (var key in rowData) {
      for (var secondKey in rowData[key]) {
        //console.log(secondKey);
        if (secondKey === 'id') {
          results.push(rowData[key][secondKey]);
        }
        //if (secondKey === 'distRecvAt') {
        // if (rowData[key]['distRecvAt'] === null && rowData[key]['restRecvAt'] === null) {
        //   filter_row_data.push(rowData[key]);
        //   filter_row_id.push(rowData[key]['id']);
        if (secondKey === 'status') {
          if (rowData[key][secondKey] === 1) filter_row_data.push(rowData[key]);
        }
      }
    }

    const defaultRest = 0;
    let restValue = { id: defaultRest, name: '' };
    const rests = servers.locations
      ?.map((locObj) => {
        let displayLabel = locObj.name;
        if (locObj.id === filterDestinationId) restValue = { ...locObj };
        displayLabel = `${locObj?.name}`;
        return { ...locObj, displayLabel };
      })
      .filter((x) => x.category === 3);

    let distValue = { id: 0, name: '' };
    const dists = servers.locations
      ?.map((locObj) => {
        let displayLabel = locObj.name;
        if (locObj.id === filterDistDestinationId) distValue = { ...locObj };
        displayLabel = `${locObj?.name}`;
        return { ...locObj, displayLabel };
      })
      .filter((x) => x.category === 2);

    let brandValue = { id: 0, name: '' };
    const brands = servers.serialBrands?.map((brandObj) => {
      let displayLabel = brandObj.name;
      if (brandObj.id === filterBrandId) brandValue = { ...brandObj };
      displayLabel = `${brandObj?.name}`;
      return { ...brandObj, displayLabel };
    });

    const handleCheckClick = () => {
      this.setState({ checked: !this.state.checked }, () => {
        //console.log(this.state.checked);
      });

      !this.state.checked
        ? this.setState({ selectedrows: results }, () => {
            //console.log('Hi');
          })
        : this.setState({ selectedrows: [] }, () => {
            //console.log(this.state.selectedrows);
          });
    };

    const filtersView = (
      <>
        <Grid container alignItems='flex-end'>
          {getDateRangePicker(
            appLanguages.shipAt[lang],
            filterBrewShipAt,
            (v) => this.handleChangeBrewShipAt(v),
            lang,
          )}
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {getSmallTextField(appLanguages.slipNum[lang], filterSalesOrder, false, (e) =>
              this.handleChangeFilterSalesOrder(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className='auto-complete-wrapper'>
            {getAutocomplete(
              appLanguages.restaurant2[lang],
              `${appLanguages.pleaseSelect[lang]}`,
              true,
              { ...restValue },
              [...rests],
              (e, v) => this.handleChageFilterDestination(v ? v.id : defaultRest),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className='auto-complete-wrapper'>
            {getAutocomplete(
              appLanguages.selectDistToShip[lang],
              `${appLanguages.pleaseSelect[lang]}`,
              true,
              { ...distValue },
              [...dists],
              (e, v) => this.handleChageFilterDistDestination(v ? v.id : defaultRest),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className='auto-complete-wrapper'>
            {getAutocomplete(
              appLanguages.brand[lang],
              `${appLanguages.pleaseSelect[lang]}`,
              true,
              { ...brandValue },
              [...brands],
              (e, v) => this.handleChageFilterBrand(v ? v.id : 0),
            )}
          </Grid>
        </Grid>
        {filterBrewShipAt.length ||
        filterSalesOrder ||
        filterBrandId ||
        filterDestinationId ||
        filterDistDestinationId ? (
          <Grid container justify='flex-end' alignItems='flex-end'>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
            <Grid item>
              {getSmallClearButton(appLanguages.reset[lang], false, () =>
                this.handleClickResetFilter(),
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </>
    );

    /* 参考: https://github.com/gregnb/mui-datatables */
    const isRequesting = servers.isRequesting || servers.isGetRequesting || !initialized;
    const isRequestingg =
      servers.isRequesting || servers.isGetRequesting || Boolean(searchTimeout) || !servers?.user;
    const searchBoxView = (
      <>
        <TextField
          fullWidth
          autoFocus
          className={classes.margin}
          value={searchKeyword}
          onChange={(e) => this.handleChangeSearch(e)}
          onKeyPress={(e) => this.handleKeyPressSearch(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment onClick={() => this.handleClickSearch()} position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {isRequestingg ? (
                  <IconButton size='small'>
                    <CircularProgress size={16} color='primary' />
                  </IconButton>
                ) : (
                  ''
                )}
                <InputAdornment onClick={() => this.handleCloseSearchBox()} position='start'>
                  <ClearIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      </>
    );

    const NewToolbar = () => {
      return (
        <>
          <Grid
            container
            //wrap='nowrap'
            justify='flex-end'
            alignContent='center'
            alignItems='center'
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <Grid item>
              {this.state.cancelShipFlag ? (
                <Box mr={6}>
                  <Checkbox checked={this.state.checked} onChange={handleCheckClick} />{' '}
                  {appLanguages.selectAll[lang]}
                </Box>
              ) : null}
            </Grid>
            <Grid item>
              {!this.state.cancelShipFlag ? (
                <Box mr={3}>
                  <MuiThemeProvider theme={orangeTheme}>
                    <Button
                      id='cancelShip'
                      variant='outlined'
                      style={{ float: 'right', marginTop: 0 }}
                      color='primary'
                      hidden={this.state.cancelShipFlag}
                      onClick={() => this.cancelShipping()}
                    >
                      {appLanguages.selectBottleToCancelShipping[lang]}
                    </Button>
                  </MuiThemeProvider>
                </Box>
              ) : null}
            </Grid>
            <Grid item>
              {this.state.cancelShipFlag ? (
                <MuiThemeProvider theme={orangeTheme}>
                  <Box mr={3}>
                    <Button
                      id='cancelShipButton2'
                      variant='contained'
                      style={{ float: 'right', marginTop: 0, marginRight: 0 }}
                      color='primary'
                      hidden={!this.state.cancelShipFlag}
                      onClick={() => this.opendialog()}
                      disabled={
                        this.state.selectedrows.length === 0 || disableConfirm === true
                          ? true
                          : false
                      }
                    >
                      {appLanguages.confirmCancelShipping[lang]}
                    </Button>
                  </Box>
                </MuiThemeProvider>
              ) : null}
              <Dialog
                open={this.state.dialogopenflag}
                onClose={this.closeDialog}
                fullWidth
                maxWidth='xs'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>
                  {appLanguages.cancelShippingDialogTitle[lang]}
                </DialogTitle>
                <DialogContent id='alert-dialog-description'>
                  <Box fontSize={14}>{appLanguages.cancelShippingDialogBody[lang]}</Box>
                  <Box mb={2} />
                </DialogContent>
                <DialogActions>
                  <Grid container spacing={8} justify='flex-end'>
                    <Grid item xs={2} />
                    <Grid item xs={1}>
                      <Button
                        style={{ float: 'right', height: 30, marginTop: 0 }}
                        fullWidth={false}
                        variant='text'
                        color='default'
                        onClick={this.closeDialog}
                      >
                        {appLanguages.no[lang]}
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        style={{ float: 'right', height: 30, marginTop: 0 }}
                        fullWidth={false}
                        variant='text'
                        color='primary'
                        onClick={() => {
                          //console.log('Submit');
                          disableConfirm = true;
                          this.handleDelete(rowinfo);
                        }}
                      >
                        {appLanguages.yes[lang]}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item>
              {this.state.cancelShipFlag ? (
                <MuiThemeProvider theme={greyTheme}>
                  <Box mr={2}>
                    <Button
                      id='cancelShipButton1'
                      variant='contained'
                      style={{ float: 'left', marginTop: 0 }}
                      color='primary'
                      hidden={!this.state.cancelShipFlag}
                      onClick={() => this.cancelShipping()}
                    >
                      {appLanguages.undo[lang]}
                    </Button>
                  </Box>
                </MuiThemeProvider>
              ) : null}
            </Grid>

            <Zoom timeout={300} in={openSearchBox}>
              {openSearchBox ? (
                <Grid item xs>
                  {searchBoxView}
                </Grid>
              ) : (
                <Box />
              )}
            </Zoom>
            {openSearchBox ? (
              <Grid>
                <Box ml={3} />
              </Grid>
            ) : (
              ''
            )}
            <Zoom timeout={300} in={!openSearchBox}>
              {!openSearchBox ? (
                <Grid item>
                  <Tooltip title={appLanguages.search[lang]}>
                    <IconButton onClick={() => this.handleOpenSearchBox()}>
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <Box />
              )}
            </Zoom>

            <Grid item>
              <Tooltip title={'Reload'}>
                <IconButton onClick={() => this.handleClickReload()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      );
    };

    ///// Language setting for sort menu options
    if (lang === 'en') {
      SortAsc = 'Ascending';
      SortDesc = 'Descending';
      ShowColumns = 'Show Columns';
      Hide = 'Hide';
    } else if (lang == 'ja') {
      SortAsc = '昇順';
      SortDesc = '降順';
      ShowColumns = '表示列を選択';
      Hide = 'この列を隠す';
    } else if (lang == 'ko') {
      SortAsc = '오름차순';
      SortDesc = '내림차순';
      ShowColumns = '열 표시';
      Hide = '숨다';
    } else if (lang == 'zhcn' || lang == 'zhtw') {
      SortAsc = '上升';
      SortDesc = '降序';
      ShowColumns = '显示列';
      Hide = '隐藏';
    } else if (lang == 'th') {
      SortAsc = 'เรียงจากน้อยไปมาก';
      SortDesc = 'เรียงจากมากไปน้อย';
      ShowColumns = 'แสดงคอลัมน์';
      Hide = 'ซ่อน';
    } else if (lang == 'vi') {
      SortAsc = 'Tăng dần';
      SortDesc = 'Giảm dần';
      ShowColumns = 'Hiển thị cột';
      Hide = 'Ẩn';
    }

    const localizedTextsMap = {
      columnMenuSortAsc: SortAsc,
      columnMenuSortDesc: SortDesc,
      columnMenuShowColumns: ShowColumns,
      columnMenuHideColumn: Hide,
      columnMenuUnsort: '',
    };

    return (
      <>
        {isRequesting ? <LinearProgress /> : <WhiteLinearProgress />}
        <MuiThemeProvider theme={appMuiTheme}>
          {
            <SummaryFunction
              serialsList={this.state.cancelShipFlag ? filter_row_data : serialsList || []}
            />
          }
          {/*<Grid container justify='center' alignContent='center' alignItems='center'>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Card>
                <SummaryFunction serialsList={{ rowData }} />
              </Card>
            </Grid>
          </Grid>*/}
          <Paper
            elevation={enableFilter ? 2 : 0}
            className={enableFilter ? classes.paper : classes.none}
          >
            <Grid item style={{ paddingTop: 25, paddingBottom: 20, paddingLeft: 15 }}>
              <h4>{appLanguages.allSerials[lang]}</h4>
            </Grid>
            <Grid item style={{ paddingLeft: 15 }}>
              {getFilterButton(
                enableFilter,
                () =>
                  this.setState({ enableFilter: !enableFilter }, () => {
                    window.scrollTo(0, 0);
                  }),
                lang,
              )}
              {enableFilter ? filtersView : ''}
            </Grid>
          </Paper>
          {enableFilter ? <Box mt={1} /> : ''}
          <div style={{ height: 640, overflow: 'auto', paddingLeft: 15, paddingRight: 15 }}>
            <DataGrid
              sx={{
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                  display: 'none',
                },
              }}
              rows={this.state.cancelShipFlag ? filter_row_data : serialsList || []}
              columns={columns}
              rowsPerPageOptions={[25, 50, 100]}
              checkboxSelection={this.state.cancelShipFlag ? true : false}
              disableSelectionOnClick={false}
              localeText={localizedTextsMap}
              disableColumnFilter={true}
              components={{
                Toolbar: NewToolbar,
              }}
              //isRowSelectable={(id) => {
              //console.log(id?.row.recvDistLocId);
              //return !id?.row.recvDistLocId;
              //}}
              isRowSelectable={isRowSelectableFlag ? () => true : () => false}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rowData.filter((row) => selectedIDs.has(row.id));
                //console.log('Selected rows: ' + selectedRows);
                this.setState({ rowinfo: selectedRows });
                this.setState({ selectedrows: ids });
                disableConfirm = false;
                //console.log('ROWINFO RENDER: ' + rowinfo);
                //console.log('Selected rows: ' + this.state.selectedrows);
              }}
              selectionModel={this.state.selectedrows}
            />
          </div>
        </MuiThemeProvider>
        {apps.isOpenSerialEditDialog ? (
          <SerialEditDialog
            cbEdited={() => {
              this.setState({ initialized: false }, () => this.updateQuery(this.query));
            }}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
  textSearched?: any;
  refreshData?: any;
  showAddSerialButton?: boolean;
  serialsList: any;
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
  openSnackbarMessage: (type: string, message: string) => void;
  setEditSerial: (serialObj: ISerial) => void;
  openSerialEditDialog: (serialObj: ISerial) => void;
  delSerial: (serialObj: ISerial) => void;
  confirm?: () => void;
  registerSerialsCancelShipping: (serialObj: any, successMessage?: any) => void;
  setSerialCustomToolbarSearchKeyword: (keyword: string) => void;
  getSerials: (query: IQuery) => void;
  getSerialSlips: (query: IQuery) => void;
  toggleSerialCustomToolbarSearchBox: (value: boolean) => void;
  getSerialBrands: (query: IQuery) => void;
}

interface State {
  dialogopenflag: boolean;
  cancelShipFlag: boolean;
  enableFilter: boolean;
  filterTimeout: any;
  initialized: boolean;
  columns: GridColDef[];
  serialsList: any;
  serialsTotalCounts: number;
  filterBrewShipAt: any;
  filterSalesOrder: any;
  filterBrandId: any;
  filterDestinationId: any;
  filterDistDestinationId: any;
  searchTimeout: any;
  refreshData: any;
  count: any;
  rowinfo: any;
  selectedrows: any;
  checked: boolean;
  slips_data: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setSerialsQuery,
  openSnackbarMessage,
  setEditSerial,
  openSerialEditDialog,
  delSerial,
  getSerialBrands,
  registerSerialsCancelShipping,
  toggleSerialCustomToolbarSearchBox,
  getSerialSlips,
  getSerials,
  setSerialCustomToolbarSearchKeyword,
};

const myStyles = (theme: Theme): StyleRules => ({
  root: { width: '100%' },
  button: { marginTop: theme.spacing(1), marginRight: theme.spacing(1) },
  actionsContainer: { marginBottom: theme.spacing(2) },
  resetContainer: { padding: theme.spacing(3) },
  paper: { padding: theme.spacing(2) },
});

export const SakeShippedForBrewery = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SakeShippedForBreweryClass);
