import { distance as turfDistance } from '@turf/distance';
import { IUser } from '@models/user';
import { TURF_OPTIONS } from './common';

/**
 * Geolocation API を使って現在地を取得する
 */
export async function getCurrentGeoLocation(): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

interface ScannedLocationInfo {
  distance: number;
  scanLat: number;
  scanLng: number;
  distanceText: string;
}

/**
 * 現在地を取得し、ユーザーの位置情報との距離を計算する
 */
export async function getScannedLocationInfo(user: IUser): Promise<ScannedLocationInfo> {
  try {
    const position = await getCurrentGeoLocation();
    const { latitude: scanLat, longitude: scanLng } = position.coords;

    const userLat = user?.location?.latitude ?? 0;
    const userLng = user?.location?.longitude ?? 0;

    const from = [scanLng, scanLat];
    const to = [userLng, userLat];
    const distance = turfDistance(from, to, TURF_OPTIONS);
    const distanceKm = distance / 1000;
    const distanceText = `${distanceKm.toFixed(2)} km`;

    return {
      distance,
      scanLat,
      scanLng,
      distanceText,
    };
  } catch (error) {
    console.error('Geolocation error:', error);
    return {
      distance: 0,
      scanLat: 0,
      scanLng: 0,
      distanceText: 'Location denied',
    };
  }
}
