import { TSupportedLanguages } from '@models/supported-languages';

export default LangSettings({
  /**
   * ダッシュボード
   */
  // ヘッダー
  dashboard: {
    ja: 'ダッシュボード',
    en: 'Dashboard',
    zhcn: '仪表板',
    zhtw: '儀表板',
    ko: '대시 보드',
    th: 'แดชบอร์ด',
    vi: 'Bảng điều khiển',
  },
  mainMenu: {
    ja: 'メインメニュー',
    en: 'Main Menu',
    zhcn: '主菜单',
    zhtw: '主菜單',
    ko: '메인 메뉴',
    th: 'เมนูหลัก',
    vi: 'Menu chính',
  },
  section: {
    ja: 'セクション',
    en: 'Section',
    zhcn: '部分',
    zhtw: '部分',
    ko: '섹션',
    th: 'ส่วน',
    vi: 'Phần',
  },
  chat: {
    ja: 'チャット',
    en: 'Chat',
    zhcn: '聊天',
    zhtw: '聊天',
    ko: '채팅',
    th: 'แชท',
    vi: 'Trò chuyện',
  },
  masterManagement: {
    ja: 'マスタ管理',
    en: 'Master Management',
    zhcn: '管理大师',
    zhtw: '管理大師',
    ko: '마스터 관리',
    th: 'การจัดการหลัก',
    vi: 'Quản lý chính',
  },
  languageSetting: {
    ja: '言語設定',
    en: 'Language Setting',
    zhcn: '语言设定',
    zhtw: '語言設定',
    ko: '언어 설정',
    th: 'การตั้งค่าภาษา',
    vi: 'Cài đặt ngôn ngữ',
  },
  login: {
    ja: 'ログイン',
    en: 'Login',
    zhcn: '登录',
    zhtw: '登錄',
    ko: '로그인',
    th: 'เข้าสู่ระบบ',
    vi: 'Đăng nhập',
  },
  openDashboard: {
    ja: 'ダッシュボードを開く',
    en: 'Open Dashboard',
    zhcn: '打开仪表板',
    zhtw: '打開儀表板',
    ko: '대시 보드를 열기',
    th: 'เปิดแดชบอร์ด',
    vi: 'Mở bảng điều khiển',
  },
  openApp: {
    ja: '流通アプリを開く',
    en: 'Open Distribution Application',
    zhcn: '开启分发程序',
    zhtw: '開啟分發程序',
    ko: '유통 응용 프로그램 열기',
    th: 'เปิดแอปพลิเคชันการจัดจำหน่าย',
    vi: 'Mở ứng dụng phân phối',
  },
  openAppManual: {
    ja: 'マニュアルを開く',
    en: 'Open Manual',
    zhcn: '开启应用程式手册',
    zhtw: '開啟應用程式手冊',
    ko: '매뉴얼 열기',
    th: 'เปิดคู่มือ',
    vi: 'Mở hướng dẫn',
  },
  logout: {
    ja: 'ログアウト',
    en: 'Logout',
    zhcn: '登出',
    zhtw: '登出',
    ko: '로그아웃',
    th: 'ออกจากระบบ',
    vi: 'Đăng xuất',
  },
  welcome: {
    ja: 'ようこそ！',
    en: 'Welcome!',
    zhcn: '欢迎！',
    zhtw: '歡迎！',
    ko: '환영합니다!',
    th: 'ยินดีต้อนรับ!',
    vi: 'Chào mừng!',
  },
  incorrectEmailFormatMessage: {
    ja: 'メールアドレスのフォーマットが正しくありません',
    en: 'Invalid email address format',
    zhcn: 'E-mail的格式不正确',
    zhtw: 'E-mail的格式不正確',
    ko: '이메일 주소 형식이 잘못되었',
    th: 'รูปแบบอีเมลไม่ถูกต้อง',
    vi: 'Định dạng email không hợp lệ',
  },
  notRegisteredEmailMessage: {
    ja: 'メールアドレスは登録されていません',
    en: 'Email address is not registered',
    zhcn: 'E-mail地址未注册',
    zhtw: 'E-mail地址未註冊',
    ko: '이메일 주소가 등록되어 있지 않습니다',
    th: 'ไม่ได้ลงทะเบียนอีเมล',
    vi: 'Email chưa được đăng ký',
  },
  invalidPasswordMessage: {
    ja: '無効なパスワードです',
    en: 'Invalid password',
    zhcn: '无效的密码',
    zhtw: '無效的密碼',
    ko: '잘못된 비밀번호입니다',
    th: 'รหัสผ่านไม่ถูกต้อง',
    vi: 'Mật khẩu không hợp lệ',
  },
  addedMessage: {
    ja: '登録が完了しました',
    en: 'Registration completed',
    zhcn: '登记已完成',
    zhtw: '登記已完成',
    ko: '등록이 완료되었습니다',
    th: 'ลงทะเบียนเสร็จสิ้น',
    vi: 'Đăng ký hoàn tất',
  },
  soldMessage: {
    ja: '販売が完了しました',
    en: 'Selling complete',
    zhcn: '銷售完成',
    zhtw: '銷售完成',
    ko: '판매가 완료되었습니다',
    th: 'ขายเสร็จสิ้น',
    vi: 'Bán hàng hoàn tất',
  },
  updatedMessage: {
    ja: '更新が完了しました',
    en: 'Update completed',
    zhcn: '更新已完成',
    zhtw: '更新已完成',
    ko: '업데이트가 완료되었습니다',
    th: 'อัปเดตเสร็จสิ้น',
    vi: 'Cập nhật hoàn tất',
  },
  deletedMessage: {
    ja: '削除が完了しました',
    en: 'Deletion completed',
    zhcn: '删除已完成',
    zhtw: '刪除已完成',
    ko: '삭제가 완료되었습니다',
    th: 'ลบเสร็จสิ้น',
    vi: 'Xóa hoàn tất',
  },
  addedSerialMessage: {
    ja: '新規個品流通履歴を登録しました',
    en: 'New product distribution record registered',
    zhcn: '新商品的配送履历登录完成',
    zhtw: '新商品的配送紀錄註冊完成',
    ko: '신규 품목별 유통 이력이 등록되었습니다',
    th: 'บันทึกการจัดจำหน่ายสินค้ารายการใหม่เสร็จสมบูรณ์',
    vi: 'Đã đng ký lịch sử phân phối sản phẩm mới',
  },
  updatedSerialMessage: {
    ja: '個品流通履歴情報を更新しました',
    en: 'Product distribution record updated',
    zhcn: '更新了个人物品分发历史信息',
    zhtw: '更新了個人物品分發歷史信息',
    ko: '품목별 유통 이력 정보를 갱신했습니다',
    th: 'อัปเดตประวัติการจัดจำหน่ายสินค้า',
    vi: 'Đã cập nhật lịch sử phân phối sản phẩm',
  },
  deletedSerialMessage: {
    ja: '指定の個品流通履歴を削除しました',
    en: 'Product distribution record deleted',
    zhcn: '商品配送履历已删除',
    zhtw: '商品配送紀錄已刪除',
    ko: '해당 품목별 유통 이력을 삭제했습니다',
    th: 'ลบประวัติการจัดจำหน่ายสินค้าที่ระบุ',
    vi: 'Đã xóa lịch sử phân phối sản phẩm được chỉ định',
  },
  resetSerialMessage: {
    ja: '指定の個品流通履歴のリセットが完了しました',
    en: 'Product distribution record reset was successful',
    zhcn: '指定单个产品的分销历史的重置现已完成',
    zhtw: '指定單個產品的分銷歷史的重置現已完成',
    ko: '해당 품목별 유통 이력 초기화가 완료되었습니다',
    th: 'รีเซ็ตประวัติการจัดจำหน่ายสินค้าที่ระบุเสร็จสิ้น',
    vi: 'Đã đặt lại lịch sử phân phối sản phẩm được chỉ định thành công',
  },
  addedLocationMessage: {
    ja: '新規拠点を登録しました',
    en: 'New location registered',
    zhcn: '新地点登录完成',
    zhtw: '新地點註冊完成',
    ko: '신규 지점이 등록되었습니다',
    th: 'ลงทะเบียนสถานที่ใหม่',
    vi: 'Đã đăng ký vị trí mới',
  },
  updatedLocationMessage: {
    ja: '新規拠点を更新しました',
    en: 'Location information updated',
    zhcn: '地点更新',
    zhtw: '地點更新',
    ko: '신규 지점을 갱신했습니다',
    th: 'อัปเดตสถานที่ใหม่',
    vi: 'Đã cập nhật thông tin vị trí mới',
  },
  deletedLocationMessage: {
    ja: '指定の拠点を削除しました',
    en: 'Location deleted',
    zhcn: '地点已刪除',
    zhtw: '地點已刪除',
    ko: '해당 지점을 삭제했습니다',
    th: 'ลบสถานที่ที่ระบุ',
    vi: 'Đã xóa vị trí được chỉ định',
  },
  addedBrandMessage: {
    ja: '新規の銘柄を追加しました',
    en: 'New brand registered',
    zhcn: '新增品牌',
    zhtw: '新增品牌',
    ko: '새 브랜드를 추가했습니다',
    th: 'เพิ่มแบรนด์ใหม่',
    vi: 'Đã thêm thương hiệu mới',
  },
  updatedBrandMessage: {
    ja: '銘柄情報を更新しました',
    en: 'Brand information updated',
    zhcn: '品牌信息更新',
    zhtw: '品牌資訊更新',
    ko: '브랜드 정보를 갱신했습니다',
    th: 'อัปเดตข้อมูลแบรนด์',
    vi: 'Đã cập nhật thông tin thương hiệu',
  },
  deletedBrandMessage: {
    ja: '指定の銘柄を削除しました',
    en: 'Brand deleted',
    zhcn: '品牌已刪',
    zhtw: '品牌已刪',
    ko: '해당 브랜드를 삭제했습니다',
    th: 'ลบแบรด์ที่ระบุ',
    vi: 'Đã xóa thương hiệu được chỉ định',
  },
  addedUserMessage: {
    ja: '新規ユーザを登録しました',
    en: 'New user registered',
    zhcn: '新用户註冊',
    zhtw: '新用戶註冊',
    ko: '새로운 사용자가 등록되었습니다',
    th: 'ลงทะเบียนผู้ใช้ใหม่',
    vi: 'Đã đăng ký người dùng mới',
  },
  updatedUserMessage: {
    ja: 'ユーザ情報を更新しました',
    en: 'User information updated',
    zhcn: '用户信息更新',
    zhtw: '用戶資訊更新',
    ko: '사용자 정보를 갱신했습니다',
    th: 'อัปเดตข้อมูลผู้ใช้',
    vi: 'Đã cập nhật thông tin người dùng',
  },
  deletedUserMessage: {
    ja: '指定のユーザを削除しました',
    en: 'User deleted',
    zhcn: '用户已刪',
    zhtw: '用戶已刪',
    ko: '해당 사용자를 삭제했습니다',
    th: 'ลบผู้ใช้ที่ระบุ',
    vi: 'Đã xóa người dùng được chỉ định',
  },
  addedHistoryMessage: {
    ja: '新規の履歴を登録しました',
    en: 'New record registered',
    zhcn: '新履历註冊',
    zhtw: '新履歷登錄',
    ko: '새로운 기록이 등록되었습니다',
    th: 'ลงทะเบียนบันทึกใหม่',
    vi: 'Đã đăng ký hồ sơ mới',
  },
  updatedHistoryMessage: {
    ja: '履歴情報を更新しました',
    en: 'Record updated',
    zhcn: '履历已更新',
    zhtw: '履歷已更新',
    ko: '이력 정보를 갱신했습니다',
    th: 'อัปเดตบันทึก',
    vi: 'Đã cập nhật hồ sơ',
  },
  deletedHistoryMessage: {
    ja: '指定の履歴を削除しました',
    en: 'Record deleted',
    zhcn: '履历已刪',
    zhtw: '履歷已刪',
    ko: '해당 기록을 삭제했습니다',
    th: 'ลบบันทึกที่ระบุ',
    vi: 'Đã xóa hồ sơ được chỉ định',
  },
  addedCommentMessage: {
    ja: '新規コメントを登録しました',
    en: 'New comment registered',
    zhcn: '新评论已注册',
    zhtw: '新的評論已註冊',
    ko: '새 댓글이 등록되었습니다',
    th: 'ลทะเบียนความคิดเห็นใหม่',
    vi: 'Đã đăng ký bình luận mới',
  },
  updatedCommentMessage: {
    ja: 'コメントを更新しました',
    en: 'Comment updated',
    zhcn: '更新评论',
    zhtw: '更新評論',
    ko: '댓글을 갱신했습니다',
    th: 'อัปเดตความคิดเห็น',
    vi: 'Đã cập nhật bình luận',
  },
  deletedCommentMessage: {
    ja: '指定のコメントを削除しました',
    en: 'Comment deleted',
    zhcn: '评论已刪除',
    zhtw: '評論已刪除',
    ko: '해당 댓글을 삭제했습니다',
    th: 'ลบความคิดเห็นที่ระบุ',
    vi: 'Đã xóa bình luận được chỉ định',
  },
  notFoundMessage: {
    ja: '指定したページは存在しません',
    en: 'This page does not exist',
    zhcn: '指定的页面不存在',
    zhtw: '指定的頁面不存在',
    ko: '해당 페이지가 존재하지 않습니다',
    th: 'หน้าที่ระบุไ่存在',
    vi: 'Trang được chỉ định không tồn tại',
  },
  require: {
    ja: '*必須',
    en: '*Required',
    zhcn: '*必需的',
    zhtw: '*必需的',
    ko: '*필수',
    th: '*จำป็',
    vi: '*Bắt buộc',
  },
  openAllSections: {
    ja: '全てのセクションを開く',
    en: 'Open All Sections',
    zhcn: '打开所有的章节',
    zhtw: '打開所有的章節',
    ko: '모든 섹션 열기',
    th: 'เปิดส่วนทั้งหมด',
    vi: 'Mở tất cả các phần',
  },
  closeAllSections: {
    ja: '全てのセクションを閉じる',
    en: 'Close All Sections',
    zhcn: '关闭所有的章节',
    zhtw: '關閉所有的章節',
    ko: '모든 섹션 닫기',
    th: 'ปิดส่วนทั้งหมด',
    vi: 'Đóng tất cả các phần',
  },
  customizeTheOrderOfSections: {
    ja: 'セクションの並び順をカスタマイズ',
    en: 'Section Order Customization',
    zhcn: '自定义部分的顺序',
    zhtw: '自定義部分的順序',
    ko: '섹션 순서 커스터마이즈',
    th: 'ปรับแต่งลำดับของส่วน',
    vi: 'Tùy chỉnh thứ tự các phần',
  },
  serialDistributionHistories: {
    ja: '個品流通履歴',
    en: 'Product Distribution Record',
    zhcn: '产品流通记录',
    zhtw: '產品發貨紀錄',
    ko: '품목별 유통 이력',
    th: 'ประวัติการจัดจำหน่ายสินค้า',
    vi: 'Lịch sử phân phối sản phẩm',
  },
  restaurantShippingConfirmation: {
    ja: 'レストラン出荷確認',
    en: 'Restaurant Shipping Confirmation',
    zhcn: '餐厅发货确认',
    zhtw: '餐廳發貨確認',
    ko: '레스토랑 출하 확인',
    th: 'ยืนยันการจัดส่งร้านอาหาร',
    vi: 'Xác nhận giao hàng nhà hàng',
  },
  restaurantManagement: {
    ja: 'レストラン管理',
    en: 'Restaurant Management',
    zhcn: '餐厅管理',
    zhtw: '餐廳管理',
    ko: '레스토랑 관리',
    th: 'การจัดการร้านอาหาร',
    vi: 'Quản lý nhà hàng',
  },
  consumerScanAnalysis: {
    ja: '消費者スキャン数分析',
    en: 'Consumer Scan Analysis',
    zhcn: '消费者扫描分析',
    zhtw: '消費者掃描分析',
    ko: '소비자 스캔 분석',
    th: 'วิเคราะห์การสแกนของู้บริโภค',
    vi: 'Phân tích quét của người tiêu dùng',
  },
  consumerLocationAnalysis: {
    ja: '消費者ロケーション分析',
    en: 'Consumer Location Analysis',
    zhcn: '消费者定位分析',
    zhtw: '消費者定位分析',
    ko: '소비자 위치 분석',
    th: 'วิเคราะห์ตำแหน่งของผู้บริโภค',
    vi: 'Phân tích vị trí người tiêu dùng',
  },
  swapTheOrderByDraggingAndDropping: {
    ja: 'ドラッグ＆ドロップで順序を入れ替えてください',
    en: 'Drag & drop to change order',
    zhcn: '请拖动和拖放更换顺序',
    zhtw: '請拖動和拖放更換順序',
    ko: '드래그 앤 드롭으로 순서 변경',
    th: 'ลากและวางเพื่อเปลี่ยนลำดับ',
    vi: 'Kéo và thả để thay đổi thứ tự',
  },
  setToInitialState: {
    ja: '初期状態に戻す',
    en: 'Set to Initial State',
    zhcn: '返回到初始状态',
    zhtw: '返回到初始狀態',
    ko: '원래 상태로 복원',
    th: 'ตั้งค่าเป็นสถานะเริ่มต้น',
    vi: 'Đặt lại trạng thái ban đầu',
  },
  myChats: {
    ja: 'マイチャット',
    en: 'My Chat',
    zhcn: '我的聊天',
    zhtw: '我的聊天',
    ko: '내 채팅',
    th: 'แชทของฉัน',
    vi: 'Trò chuyện của tôi',
  },
  allUserChats: {
    ja: '全ユーザのチャット',
    en: "All User's Chat",
    zhcn: '聊天的所有用户',
    zhtw: '聊天的所有用戶',
    ko: '모든 사용자의 채팅',
    th: 'แชทของผู้ใช้ทั้งหมด',
    vi: 'Trò chuyện của tất cả người dùng',
  },
  deleteThisChatRoom: {
    ja: 'このチャットルームを削除する',
    en: 'Delete this chat room',
    zhcn: '删除此聊天室',
    zhtw: '刪除此聊天室',
    ko: '이 채팅방 삭제',
    th: 'ลบห้องแชทนี้',
    vi: 'Xóa phòng trò chuyện này',
  },
  moveToChatRoom: {
    ja: 'このチャットルームへ移動する',
    en: 'Move to this chat room',
    zhcn: '移动到这个聊天室',
    zhtw: '移動到這個聊天室',
    ko: '이 채팅으로 이동',
    th: 'ไปยังห้องแชทนี้',
    vi: 'Di chuyển đến phòng trò chuyện này',
  },
  searchUsers: {
    ja: 'ユーザを検索',
    en: 'Search User',
    zhcn: '搜索用户',
    zhtw: '搜索用戶',
    ko: '사용자 검색',
    th: 'ค้นหาผู้ใช้',
    vi: 'Tìm kiếm người dùng',
  },
  enterMessage: {
    ja: 'メッセージを入力...（Shift + Enterキーで送信）',
    en: 'Enter message... (Shift + Enter to send)',
    zhcn: '输入消息...（在Shift发送+ Enter键）',
    zhtw: '入消息...（在Shift發送+ Enter鍵）',
    ko: '메시지 입력 (Shift + Enter로 보내기)',
    th: 'ป้อนข้อความ... (Shift + Enter เพื่อส่ง)',
    vi: 'Nhập tin nhắn... (Shift + Enter để gửi)',
  },
  pleaseSelectUser: {
    ja: 'ユーザを選択してください',
    en: 'Select An User',
    zhcn: '请选择一个用户',
    zhtw: '請選擇一個用戶',
    ko: '사용자를 선택하십시오',
    th: 'กรุณาเลือกผู้ใช้',
    vi: 'Vui lòng chọn một ngời dùng',
  },
  serials: {
    ja: '個品流通履歴管理',
    en: 'Product Distribution Record Management',
    zhcn: '个别产品分销历史管理',
    zhtw: '個別產品分銷歷史管理',
    ko: '품목별 유통 이력 관리',
    th: 'การจัดการประวัติการจัดจำหน่ายสินค้า',
    vi: 'Quản lý lịch sử phân phối sản phẩm',
  },
  serials2: {
    ja: '個品流通履歴一覧',
    en: 'Product Distribution Record',
    zhcn: '个别产品分发历史记录列表',
    zhtw: '個別產品分發歷史記錄列表',
    ko: '품목별 유통 이력 목록',
    th: 'รายการประวัติการจัดจำหน่ายสินค้า',
    vi: 'Danh sách lịch sử phân phối sản phẩm',
  },
  locations: {
    ja: '拠点管理',
    en: 'Location Management',
    zhcn: '现场管理',
    zhtw: '現場管理',
    ko: '지점 관리',
    th: 'การจัดการสถานที่',
    vi: 'Quản lý vị trí',
  },
  locations2: {
    ja: '拠点一覧',
    en: 'Location List',
    zhcn: '办公',
    zhtw: '辦公',
    ko: '지점 목록',
    th: 'รายการสถานที่',
    vi: 'Danh sách vị trí',
  },
  brands: {
    ja: '銘柄管理',
    en: 'Brand Management',
    zhcn: '品牌管理',
    zhtw: '品牌管理',
    ko: '브랜드 관리',
    th: 'การจัดการแบรนด์',
    vi: 'Quản lý thương hiệu',
  },
  brands2: {
    ja: '銘柄一覧',
    en: 'Brand List',
    zhcn: '品牌列表',
    zhtw: '品牌列表',
    ko: '브랜드 목록',
    th: 'รายการแบรนด',
    vi: 'Danh sách thương hiệu',
  },
  users: {
    ja: 'ユーザ管理',
    en: 'User Management',
    zhcn: '用户管理',
    zhtw: '用戶管理',
    ko: '사용자 관리',
    th: 'การจัดการผู้ใช้',
    vi: 'Quản lý người dùng',
  },
  users2: {
    ja: 'ユーザ一覧',
    en: 'User List',
    zhcn: '用户列表',
    zhtw: '用戶列表',
    ko: '사용자 목록',
    th: 'รายชื่อผู้ใช้',
    vi: 'Danh sách người dùng',
  },
  histories: {
    ja: '履歴管理',
    en: 'Record Management',
    zhcn: '历史管理',
    zhtw: '歷史管理',
    ko: '이력 관리',
    th: 'การจัดการบันทึก',
    vi: 'Quản lý hồ sơ',
  },
  histories2: {
    ja: '履歴一覧',
    en: 'Record List',
    zhcn: '历史记录列表',
    zhtw: '歷史記錄列表',
    ko: '이력 목록',
    th: 'รายการบันทึก',
    vi: 'Danh sách hồ sơ',
  },
  id: {
    ja: 'ID',
    en: 'ID',
    zhcn: 'ID',
    zhtw: 'ID',
    ko: 'ID',
    th: 'ไอดี',
    vi: 'ID',
  },
  publicId: {
    ja: '公開ID',
    en: 'Public ID',
    zhcn: '公共ID',
    zhtw: '公共ID',
    ko: '공개 ID',
    th: 'ไอดีสาธารณะ',
    vi: 'ID công khai',
  },
  name: {
    ja: '名前',
    en: 'Name',
    zhcn: '姓',
    zhtw: '姓',
    ko: '이름',
    th: 'ชื่อ',
    vi: 'Tên',
  },
  enName: {
    ja: '名前（英語）',
    en: 'Name (English)',
    zhcn: '名称（英文）',
    zhtw: '名稱（英文）',
    ko: '이름 (영문)',
    th: 'ชื่อ (ภาษาอังกฤษ)',
    vi: 'Tên (tiếng Anh)',
  },
  cnName: {
    ja: '名前（簡体字）',
    en: 'Name (Simplified Chinese)',
    zhcn: '名称（简体字）',
    zhtw: '名稱（簡體字）',
    ko: '이름 (간체)',
    th: 'ชื่อ (ภาษาจีนตัวย่อ)',
    vi: 'Tên (tiếng Trung giản thể)',
  },
  hkName: {
    ja: '名前（繁体字）',
    en: 'Name (Traditional Chinese)',
    zhcn: '名称（繁体字）',
    zhtw: '名稱（繁體字）',
    ko: '이름 (번체)',
    th: 'ชื่อ (ภาษาจีนตัวเต็ม)',
    vi: 'Tên (tiếng Trung phồn thể)',
  },
  krName: {
    ja: '名前（韓国語）',
    en: 'Name (Korean)',
    zhcn: '名称（韩文）',
    zhtw: '名稱（韓文）',
    ko: '이름 (한국어)',
    th: 'ชื่อ (ภาษาเกาหลี)',
    vi: 'Tên (tiếng Hàn)',
  },
  information: {
    ja: '情報',
    en: 'Information',
    zhcn: '信息',
    zhtw: '資訊',
    ko: '정보',
    th: 'ข้อมูล',
    vi: 'Thông tin',
  },
  enInformation: {
    ja: '情報 (英語)',
    en: 'Information (English)',
    zhcn: '信息（英文',
    zhtw: '資訊（英文）',
    ko: '정보 (영문)',
    th: 'ข้อมูล (ภาษาอังกฤษ)',
    vi: 'Thông tin (tiếng Anh)',
  },
  cnInformation: {
    ja: '情報 (簡体字)',
    en: 'Information (Simplified Chinese)',
    zhcn: '信息（简体字）',
    zhtw: '資訊（簡體字）',
    ko: '정보 (간체)',
    th: 'ข้อมูล (ภาษาจีนตัวย่อ)',
    vi: 'Thông tin (tiếng Trung giản thể)',
  },
  hkInformation: {
    ja: '情報 (繁体字)',
    en: 'Information (Traditional Chinese)',
    zhcn: '信息（繁体字）',
    zhtw: '資訊（繁體字）',
    ko: '정보 (번체)',
    th: 'ข้อมูล (ภาษาจีนตัวเต็ม)',
    vi: 'Thông tin (tiếng Trung phồn thể)',
  },
  krInformation: {
    ja: '情報 (韓国語)',
    en: 'Information (Korean)',
    zhcn: '信息（韩国）',
    zhtw: '資訊（韓國）',
    ko: '정보 (한국어)',
    th: 'ข้อมูล (ภาษาเกาหลี)',
    vi: 'Thông tin (tiếng Hàn)',
  },
  description: {
    ja: '説明',
    en: 'Description',
    zhcn: '说明',
    zhtw: '說明',
    ko: '설명',
    th: 'คำอธิบาย',
    vi: 'Mô tả',
  },
  enDescription: {
    ja: '説明 (英語)',
    en: 'Description (English)',
    zhcn: '说明（英文）',
    zhtw: '說明（英文）',
    ko: '설명 (영문)',
    th: 'คำอธิบาย (ภาษาอังกฤษ)',
    vi: 'Mô tả (tiếng Anh)',
  },
  cnDescription: {
    ja: '説明 (簡体字)',
    en: 'Description (Simplified Chinese)',
    zhcn: '说明（简体字）',
    zhtw: '說明（簡體字）',
    ko: '설명 (간체)',
    th: 'คำอธิบาย (ภาษาจีนตัวย่อ)',
    vi: 'Mô tả (tiếng Trung giản thể)',
  },
  hkDescription: {
    ja: '説明 (繁体字)',
    en: 'Description (Traditional Chinese)',
    zhcn: '说明（繁体字）',
    zhtw: '說明（繁體字）',
    ko: '설명 (번체)',
    th: 'คำอธิบาย (ภาษาจีนตัวเต็ม)',
    vi: 'Mô tả (tiếng Trung phồn thể)',
  },
  krDescription: {
    ja: '説明 (韓国語)',
    en: 'Description (Korean)',
    zhcn: '说明（韩语）',
    zhtw: '說明（韓語）',
    ko: '설명 (한국어)',
    th: 'คำอธิบาย (ภาษาเกาหลี)',
    vi: 'Mô tả (tiếng Hàn)',
  },
  image: {
    ja: '画像',
    en: 'Image',
    zhcn: '图像',
    zhtw: '圖像',
    ko: '이미지',
    th: 'รูปภาพ',
    vi: 'Hình ảnh',
  },
  imageUrl: {
    ja: '画像URL',
    en: 'Image URL',
    zhcn: '图片网址',
    zhtw: '圖片網址',
    ko: '이미지 URL',
    th: 'URL รูปภาพ',
    vi: 'URL hình ảnh',
  },
  avatarImageUrl: {
    ja: 'アバター画像URL',
    en: 'Avatar Image URL',
    zhcn: '头像图片URL',
    zhtw: '頭像圖片URL',
    ko: '아바타 이미지 URL',
    th: 'URL รูปภาพอวตาร',
    vi: 'URL hình ảnh đại diện',
  },
  logoImageUrl: {
    ja: 'ロゴ画像URL',
    en: 'Logo Image URL',
    zhcn: '标志图片址',
    zhtw: '標誌圖片網址',
    ko: '로고 이미지 URL',
    th: 'URL รูปภาพโลโก้',
    vi: 'URL hình ảnh logo',
  },
  createdAt: {
    ja: '作成日',
    en: 'Created at',
    zhcn: '创建日期',
    zhtw: '創建日期',
    ko: '작성일',
    th: 'วันที่สร้าง',
    vi: 'Ngày tạo',
  },
  updatedAt: {
    ja: '更新日',
    en: 'Updated at',
    zhcn: '更新日期',
    zhtw: '更新日期',
    ko: '업데이트 날짜',
    th: 'วันที่อัปเดต',
    vi: 'Ngày cập nhật',
  },
  locationId: {
    ja: '拠点ID',
    en: 'Location ID',
    zhcn: '位置ID',
    zhtw: '位置ID',
    ko: '지점 ID',
    th: 'ไอดีสถานที่',
    vi: 'ID vị trí',
  },
  location: {
    ja: '拠点',
    en: 'Location',
    zhcn: '位置',
    zhtw: '位置',
    ko: '지점',
    th: 'สถานที่',
    vi: 'Vị trí',
  },
  belongLocation: {
    ja: '所属拠点',
    en: 'Belonging Location',
    zhcn: '所属位置',
    zhtw: '所',
    ko: '소속 지점',
    th: 'สถานที่ที่เป็นเจ้าของ',
    vi: 'Vị trí thuộc sở hữu',
  },
  cancel: {
    ja: 'キャンセル',
    en: 'Cancel',
    zhcn: '取消',
    zhtw: '取消',
    ko: '취소',
    th: 'ยกเลิก',
    vi: 'Hủy',
  },
  close: {
    ja: '閉じる',
    en: 'Close',
    zhcn: '关闭',
    zhtw: '關閉',
    ko: '닫기',
    th: 'ปิด',
    vi: 'Đóng',
  },
  yes: {
    ja: 'はい',
    en: 'Yes',
    zhcn: '是',
    zhtw: '是',
    ko: '예',
    th: 'ใช่',
    vi: 'Có',
  },
  no: {
    ja: 'いいえ',
    en: 'No',
    zhcn: '否',
    zhtw: '否',
    ko: '아니오',
    th: 'ไม่',
    vi: 'Không',
  },
  set: {
    ja: '設定する',
    en: 'Set',
    zhcn: '建立',
    zhtw: '建立',
    ko: '설정',
    th: 'ตั้งค่า',
    vi: 'Thiết lập',
  },
  addNew: {
    ja: '{data}を新規登録',
    en: 'Add New {data}',
    zhcn: '{data}报名',
    zhtw: '{data}報名',
    ko: '{data}신규등록',
    th: 'เพิ่ม{data}ใหม่',
    vi: 'Thêm mới {data}',
  },
  editData: {
    ja: 'データ編集',
    en: 'Edit Data',
    zhcn: '数据编辑',
    zhtw: '數據編輯',
    ko: '데이터 수정',
    th: 'แก้ไขข้อมูล',
    vi: 'Chỉnh sửa dữ liệu',
  },
  scannedOutsideSpecifiedRange: {
    ja: '指定範囲外（登録拠点から{distance}mより離れた位置）でスキャンされました。',
    en:
      'Scanned outside the specified range (a location more than {distance}m away from the registered location)',
    zhcn: '在指定范围之外（距已注册基地{distance}m以上的位置）扫描了该文件',
    zhtw: '在指定範圍之外（距已註冊基地{distance}m以上的位置）掃描了該文件',
    ko: '지정 범위 외 (등록 거점에서 {distance}m보다 떨어진 위치)에서 검색되었습니다',
    th: 'สแกนนอกช่วงที่ระบุ (ตำแหน่งที่อยู่ห่างจากสถานที่ที่ลงทะเบียนมากกว่า {distance}m)',
    vi: 'Đã quét ngoài phạm vi được chỉ định (một vị trí cách vị trí đã đăng ký hơn {distance}m)',
  },
  warning: {
    ja: '【警告】',
    en: '【Warning】',
    zhcn: '【警告】',
    zhtw: '【警告】',
    ko: '【경고】',
    th: '【คำเตือน】',
    vi: '【Cảnh báo】',
  },
  checkBrowserGPS: {
    ja: 'ブラウザのGPS設定が正しく設定されているか再度確認してください',
    en: 'Please check whether your browser GPS setting is active',
    zhcn: '请再次检查浏览器的GPS设置是否正确',
    zhtw: '請再次檢查瀏覽器的GPS設置是否正確',
    ko: '브라우저의 GPS 설정이 제대로 설정되어 있는지 다시 확인해 주세요',
    th: 'กรุณาตรวจสอบอีกครั้งว่าการตั้งค่า GPS ของเบราว์เซอร์ของคุณเปิดใช้งานอยู่หรือไม่',
    vi: 'Vui lòng kiểm tra lại xem cài đặt GPS trình duyệt của bạn có được bật hay không',
  },
  addNewData: {
    ja: '新規追加',
    en: 'Add New Data',
    zhcn: '添新',
    zhtw: '添新',
    ko: '데이터 등록',
    th: 'เพิ่มใหม่',
    vi: 'Thêm mới dữ liệu',
  },
  addNewData2: {
    ja: '新規登録',
    en: 'Add New Data',
    zhcn: '添新',
    zhtw: '添新',
    ko: '데이터 등록',
    th: 'ลงทะเบียนใหม่',
    vi: 'Đăng ký mới',
  },
  register: {
    ja: '登録する',
    en: 'Register',
    zhcn: '注册',
    zhtw: '註冊',
    ko: '등록',
    th: 'ลงทะเบียน',
    vi: 'Đăng ký',
  },
  update: {
    ja: '更新する',
    en: 'Update',
    zhcn: '更新',
    zhtw: '更新',
    ko: '업데이트',
    th: 'อัปเดต',
    vi: 'Cập nhật',
  },
  imageUpload: {
    ja: 'メイン画像アップロード',
    en: 'Upload Image',
    zhcn: '主要图片上传',
    zhtw: '主要圖片上傳',
    ko: '메인 이미지 업로드',
    th: 'อัปโหลดรูปภาพหลัก',
    vi: 'Tải lên hình ảnh chính',
  },
  avatarImageUpload: {
    ja: 'アバター画像アップロード',
    en: 'Upload Avatar Image',
    zhcn: '头像图片上传',
    zhtw: '頭像圖片上傳',
    ko: '아바타 이미지 업로드',
    th: 'อัปโหลดรูปภาพอวตาร',
    vi: 'Tải lên hình ảnh đại diện',
  },
  logoImageUpload: {
    ja: 'ロゴ画像アップロード',
    en: 'Upload Logo Image',
    zhcn: '商标图片上传',
    zhtw: '商標圖片上傳',
    ko: '로고 이미지 업로드',
    th: 'อัปโหลดรูปภาพโลโก้',
    vi: 'Tải lên hình ảnh logo',
  },
  textLabelsBodyNoMatch: {
    ja: 'データが存在しません',
    en: 'No existing data found',
    zhcn: '数据不存在',
    zhtw: '數據不存在',
    ko: '데이터가 존재하지 않습니다',
    th: 'ไม่พบข้อมูลที่มีอยู่',
    vi: 'Không tìm thấy dữ liệu hiện có',
  },
  textLabelsBodyToolTip: {
    ja: '並び替え',
    en: 'Sort',
    zhcn: '种类',
    zhtw: '種類',
    ko: '정렬',
    th: 'เรียงลำดับ',
    vi: 'Sắp xếp',
  },
  textLabelsPaginationNext: {
    ja: '次のページ',
    en: 'Next Page',
    zhcn: '下页',
    zhtw: '下頁',
    ko: '다음 페이지',
    th: 'หน้าถัดไป',
    vi: 'Trang tiếp theo',
  },
  textLabelsPaginationPrevious: {
    ja: '前のページ',
    en: 'Previous Page',
    zhcn: '上一页',
    zhtw: '上一頁',
    ko: '이전 페이지',
    th: 'หน้าก่อนหน้า',
    vi: 'Trang trước',
  },
  textLabelsPaginationRowsPerPage: {
    ja: 'Rows per page:',
    en: 'Rows per page:',
    zhcn: '每页行数：',
    zhtw: '每頁行數：',
    ko: '페이지별 열',
    th: 'แถวต่อหน้า:',
    vi: 'Số hàng mỗi trang:',
  },
  textLabelsPaginationDisplayRows: {
    ja: 'of',
    en: 'of',
    zhcn: '的',
    zhtw: '的',
    ko: '의',
    th: 'จาก',
    vi: 'của',
  },
  textLabelsToolbarSearch: {
    ja: 'キーワード検索をする',
    en: 'Search',
    zhcn: '关键字搜索',
    zhtw: '關鍵字搜索',
    ko: '키워드 검색',
    th: 'ค้นหา',
    vi: 'Tìm kiếm',
  },
  textLabelsToolbarDownloadCsv: {
    ja: 'CSVファイルをダウンロードする',
    en: 'Download CSV File',
    zhcn: '下载CSV文件',
    zhtw: '下載CSV文件',
    ko: 'CSV 파일 다운로드',
    th: 'ดาวน์โหลดไฟล์ CSV',
    vi: 'Tải tệp CSV',
  },
  textLabelsToolbarFilterTable: {
    ja: 'フィルター条件を指定する',
    en: 'Select Filtering Condition',
    zhcn: '要指定过滤条件',
    zhtw: '要指定過濾條件',
    ko: '필터 조건 설정',
    th: 'เลือกเงื่อนไขการกรอง',
    vi: 'Chọn điều kiện lọc',
  },
  textLabelsToolbarViewColumns: {
    ja: '表示項目をカスタマイズする',
    en: 'Customize Presented Item',
    zhcn: '要自定义显示项目',
    zhtw: '要自定義顯示項目',
    ko: '표시 항목을 커스터마이즈',
    th: 'ปรับแต่งรายการที่นำเสนอ',
    vi: 'Tùy chỉnh mục trình bày',
  },
  textLabelsFilterAll: {
    ja: '全て',
    en: 'All',
    zhcn: '全部',
    zhtw: '全部',
    ko: '모든',
    th: 'ทั้งหมด',
    vi: 'Tất cả',
  },
  textLabelsFilterTitle: {
    ja: 'フィルター条件',
    en: 'Filtering Condition',
    zhcn: '过滤条件',
    zhtw: '過濾條件',
    ko: '필터 조건',
    th: 'เงื่อนไขการกรอง',
    vi: 'Điều kiện lọc',
  },
  textLabelsFilterReset: {
    ja: 'リセットする',
    en: 'Reset',
    zhcn: '重置',
    zhtw: '重置',
    ko: '재설정',
    th: 'รีเซ็ต',
    vi: 'Đặt lại',
  },
  editSerial: {
    ja: '個品流通履歴を編集',
    en: 'Edit Product Distribution Record',
    zhcn: '编辑单个产品的分销历史',
    zhtw: '編輯單個產品的分銷歷史',
    ko: '품목별 유통 이력 수정',
    th: 'แก้ไขบันทึกการจัดจำหน่ายผลิตภัณฑ์',
    vi: 'Chỉnh sửa hồ sơ phân phối sản phẩm',
  },
  addNewSerial: {
    ja: '個品流通履歴を新規追加',
    en: 'Add New Product Distribution Record',
    zhcn: '添加新的单独的产品分发历史记录',
    zhtw: '添加新的單獨的產品分發歷史記錄',
    ko: '품목별 유통 이력 새로 추가',
    th: 'เพิ่มบันทึกการจัดจำหน่ายผลิตภัณฑ์ใหม่',
    vi: 'Thêm hồ sơ phân phối sản phẩm mới',
  },
  editSerial2: {
    ja: 'シリアル番号情報を編集',
    en: 'Edit Serial Number Information',
    zhcn: '编辑序列号信息',
    zhtw: '編輯序列號信息',
    ko: '시리얼 번호 정보 수정',
    th: 'แก้ไขข้อมูลหมายเลขประจำเครื่อง',
    vi: 'Chỉnh sửa thông tin số sê-ri',
  },
  addNewSerial2: {
    ja: '新規追加',
    en: 'Add New Serial Number',
    zhcn: '新增序列号',
    zhtw: '新增序列號',
    ko: '신규 추가',
    th: 'เพิ่มหมายเลขประจำเครื่องใหม่',
    vi: 'Thêm số sê-ri mới',
  },
  serialCode: {
    ja: 'シリアル番号',
    en: 'Serial Number',
    zhcn: '序列号',
    zhtw: '序列號',
    ko: '시리얼 번호',
    th: 'หมายเลขประจำเครื่อง',
    vi: 'Số sê-ri',
  },
  serialCode2: {
    ja: 'シリアル',
    en: 'Serial',
    zhcn: '序列',
    zhtw: '序列',
    ko: '시리얼',
    th: 'หมายเลข',
    vi: 'Số',
  },
  brand: {
    ja: '銘柄',
    en: 'Brand',
    zhcn: '品牌',
    zhtw: '品牌',
    ko: '브랜드',
    th: 'แบรนด์',
    vi: 'Thương hiệu',
  },
  brewery: {
    ja: '酒蔵',
    en: 'Brewery',
    zhcn: '酿酒厂',
    zhtw: '釀酒廠',
    ko: '양조장',
    th: 'โรงเบียร์',
    vi: 'Nhà máy',
  },
  breweryName: {
    ja: '酒蔵名',
    en: 'Brewery Name',
    zhcn: '啤酒名',
    zhtw: '啤酒名',
    ko: '양조장 이름',
    th: 'ชื่อโรงเบียร์',
    vi: 'Tên nhà máy',
  },
  breweryPublicId: {
    ja: '酒蔵の公開ID',
    en: 'Brewery Public ID',
    zhcn: '啤酒厂的公共ID',
    zhtw: '啤酒廠的公共ID',
    ko: '양조장 공개 ID',
    th: 'รหัสสธรณของโรเบียร์',
    vi: 'ID công khai của nhà máy',
  },
  adminNote: {
    ja: '備考（管理者用）',
    en: 'Note (for Admin)',
    zhcn: '备注（适用于管理员）',
    zhtw: '備註（適用於管理員）',
    ko: '관리자 비고',
    th: 'หมายเหตุ (สำหรับผู้ดูแล)',
    vi: 'Ghi chú (cho quản trị viên)',
  },
  brewShipAt: {
    ja: '酒蔵 出荷日',
    en: 'Brewery Shipping Date',
    zhcn: '装运日期啤酒厂',
    zhtw: '裝運日期啤酒廠',
    ko: '양조장 출하 일',
    th: 'วันที่จัดส่งโรงเบียร์',
    vi: 'Ngày giao hàng của nhà máy',
  },
  shipAt: {
    ja: '出荷日',
    en: 'Shipping Date',
    zhcn: '装运日期',
    zhtw: '裝運日期',
    ko: '출하일',
    th: 'วันที่จัดส่ง',
    vi: 'Ngày vận chuyển',
  },
  deliveryAt: {
    ja: '配送日',
    en: 'Delivery Date',
    zhcn: '发货日期',
    zhtw: '發貨日期',
    ko: '배송일',
    th: 'วันที่จัดส่ง',
    vi: 'Ngày giao hàng',
  },
  brewShipScanLatLng: {
    ja: '酒蔵 出荷緯度・経度',
    en: 'Longitude/Latitude (Brewery Shipping)',
    zhcn: '啤酒装船纬度和经度',
    zhtw: '啤酒裝船緯度和經度',
    ko: '양조장 출하 위도 · 경도',
    th: 'ลองจิจูด/ละติจูด (การจัดส่งโรงเบียร์)',
    vi: 'Kinh độ/Vĩ độ (Nhà máy vận chuyển)',
  },
  brewNote: {
    ja: '酒蔵 備考',
    en: 'Note (Brewery)',
    zhcn: '啤酒备注',
    zhtw: '啤酒備註',
    ko: '양조장 비고',
    th: 'หมายเหตุ (โรงเบียร์)',
    vi: 'Ghi chú (Nhà máy)',
  },
  brewShipWarning: {
    ja: '酒蔵 出荷警告',
    en: 'Alert (Brewery Shipping)',
    zhcn: '啤酒运警告',
    zhtw: '啤酒運警告',
    ko: '양조장 출하 경고',
    th: 'คำเตือนการจัดส่งโรงเบียร์',
    vi: 'Cảnh báo vận chuyển nhà máy',
  },
  requireSurveyForBrewShip: {
    ja: '酒蔵 出荷要調査',
    en: 'Investigation Required (Brewery Shipping)',
    zhcn: '酒厂出货需求调查',
    zhtw: '酒廠出貨需求調查',
    ko: '양조장 출하 요구 조사',
    th: 'ต้องการการสำรวจ (การจัดส่งโรงเบียร์)',
    vi: 'Yêu cầu khảo sát (Vận chuyển nhà máy)',
  },
  brewShipUser: {
    ja: '酒蔵 出荷ユーザ',
    en: 'User (Brewery Shipping)',
    zhcn: '啤酒运往用户',
    zhtw: '啤酒運往用戶',
    ko: '양조장 출하 사용자',
    th: 'ผู้ใช้ (การจัดส่งโรงเบียร์)',
    vi: 'Người dùng (Vận chuyển nhà máy)',
  },
  brewShipRestLoc: {
    ja: '酒蔵 出荷先レストラン',
    en: 'Destination Restaurant (Brewery Shipping)',
    zhcn: '酒厂出货餐厅',
    zhtw: '酒廠出貨餐廳',
    ko: '양조장 수취인 레스토랑',
    th: 'ปลายทางร้านอาหาร (การจัดส่งโรงเบียร์)',
    vi: 'Địa điểm nhận hàng (Vận chuyển nhà máy)',
  },
  confirm: {
    ja: '確認',
    en: 'Confirm',
    zhcn: '确认',
    zhtw: '確認',
    ko: '확인',
    th: 'ยืนยัน',
    vi: 'Xác nhận',
  },
  calculate: {
    ja: '計算',
    en: 'Calculate',
    zhcn: '计算',
    zhtw: '计算',
    ko: '계산하다',
    th: 'คำนวณ',
    vi: 'Tính toán',
  },
  excessShipmentsError: {
    ja: '10000件以上登録できません',
    en: 'Cannot register more than 10000 items',
    zhcn: '不能注册超过 10000 个项目',
    zhtw: '不能注册超过 10000 个项目',
    ko: '10000개 이상의 항목을 등록할 수 없습니다.',
    th: 'ไม่สามารถลงทะเบียนได้มากกว่า 10,000 รายการ',
    vi: 'Không thể đăng ký quá 10,000 mục',
  },
  checkedBrewShipWarning: {
    ja: '酒蔵 出荷警告確認済',
    en: 'Confirmed Alert (Brewery Shipping)',
    zhcn: '酒厂出货警告确认',
    zhtw: '酒廠出貨警告確認',
    ko: '양조장 출하 경고 확인 완료',
    th: 'ยืนยันการแจ้งเตือนการจัดส่งจากโรงเบียร์',
    vi: 'Xác nhận cảnh báo vận chuyển từ nhà máy',
  },
  distRecvAt: {
    ja: 'ディストリビュータ 入荷日',
    en: 'Distributor Receiving Date',
    zhcn: '经销商到货日',
    zhtw: '经销商到货日',
    ko: '유통업자에 입하 날짜',
    th: 'วันที่รับสินค้าจากผู้จัดจำหน่าย',
    vi: 'Ngày nhn hàng từ nhà phân phối',
  },
  distRecvAtWithTime: {
    ja: '受取日時',
    en: 'Distributor Receiving Date',
    zhcn: '收货日期和时间',
    zhtw: '收货日期和时间',
    ko: '수령 일시',
    th: 'วันและเวลาที่รับสินค้าจากผู้จัดจำหน่าย',
    vi: 'Ngày và giờ nhận hàng từ nhà phân phối',
  },
  distRecvUser: {
    ja: 'ディストリビュータ 入荷ユーザ',
    en: 'User (Distributor Receiving)',
    zhcn: '经销商库存用户',
    zhtw: '经销商库存用户',
    ko: '유통업자 사용자',
    th: 'ผู้ใช้ (การรับสินค้าจากผู้จัดจำหน่าย)',
    vi: 'Người dùng (Nhận hàng từ nhà phân phối)',
  },
  distRecvWarning: {
    ja: 'ディストリビュータ 入荷警告',
    en: 'Alert (Distributor Receiving)',
    zhcn: '经销商库存预警',
    zhtw: '经销商库存预警',
    ko: '유통업자 입하 경고',
    th: 'แจ้งเตือน (การรับสินค้าจากผู้จัดจำหน่าย)',
    vi: 'Cnh báo (Nhận hàng từ nhà phân phối)',
  },
  checkedDistRecvWarning: {
    ja: 'ディストリビュータ 入荷警告確認済',
    en: 'Confirmed Alert (Distributor Receiving)',
    zhcn: '经销商库存预警确认',
    zhtw: '经销商库存预警确认',
    ko: '유통업자 입하 경고 확인 완료',
    th: 'ยืนยันการแจ้งเตือน (การรับสินค้าจากผู้จัดจำหน่าย)',
    vi: 'Xác nhận cảnh báo (Nhận hàng từ nhà phân phối)',
  },
  distShipAt: {
    ja: 'ディストリビュータ 出荷日',
    en: 'Distributor Shipping Date',
    zhcn: '经销商发货日期',
    zhtw: '经销商发货日期',
    ko: '유통업자 출하 일',
    th: 'วันที่จัดส่งจากผู้จัดจำหน่าย',
    vi: 'Ngày giao hàng từ nhà phân phối',
  },
  distShipAtWithTime: {
    ja: '出荷日時',
    en: 'Distributor Shipping Date',
    zhcn: '发货日期',
    zhtw: '发货日期',
    ko: '배송일시',
    th: 'วันและเวลาจัดส่งจากผู้จัดจำหน่าย',
    vi: 'Ngày và giờ giao hàng từ nhà phân phối',
  },
  distShipUser: {
    ja: 'ディストリビュータ 出荷ユーザ',
    en: 'User (Distributor Shipping)',
    zhcn: '经销商的出货用户',
    zhtw: '经销商的出货用户',
    ko: '유통업자 출하 사용자',
    th: 'ผู้ใช้ (การจัดส่งจากผู้จัดจำหน่าย)',
    vi: 'Người dùng (Giao hàng từ nhà phân phối)',
  },
  distShipWarning: {
    ja: 'ディストリビュータ 出荷警告',
    en: 'Alert (Distributor Shipping)',
    zhcn: '经销商出货警告',
    zhtw: '经销商出货警告',
    ko: '유통업자 출하 경고',
    th: 'แจ้งเตือน (การจัดส่งจากผู้จัดจำหน่าย)',
    vi: 'Cảnh báo (Giao hàng từ nhà phân phối)',
  },
  checkedDistShipWarning: {
    ja: 'ディストリビュータ 出荷警告確認済',
    en: 'Confirmed Alert (Distributor Shipping)',
    zhcn: '经销商出货警告确认',
    zhtw: '经销商出货警告确认',
    ko: '유통 출하 경고 확인 완료',
    th: 'ยืนยันการแจ้งเตือน (การจัดส่งจากผู้จัดจำหน่าย)',
    vi: 'Xác nhận cảnh báo (Giao hàng từ nhà phân phối)',
  },
  distNote: {
    ja: 'ディストリビュータ 備考',
    en: 'Note (Distributor)',
    zhcn: '经销商备注',
    zhtw: '经销商备注',
    ko: '유통업자 비고',
    th: 'หมายเหตุ (ผู้จัดจำหน่าย)',
    vi: 'Ghi chú (Nhà phân phối)',
  },
  restRecvAt: {
    ja: 'レストラン 入荷日',
    en: 'Restaurant Receiving Date',
    zhcn: '餐厅收货日期',
    zhtw: '餐厅收货日期',
    ko: '레스토랑 입하 날짜',
    th: 'วันที่รับสินค้าจากร้านอาหาร',
    vi: 'Ngày nhận hàng từ nhà hàng',
  },
  restRecvAtWithTime: {
    ja: '受取日時',
    en: 'Restaurant Receiving Date',
    zhcn: '收货日期和时间',
    zhtw: '收货日期和时间',
    ko: '수령 일시',
    th: 'วันและเวลาที่รับสินค้าจากร้านอาหาร',
    vi: 'Ngày và giờ nhận hàng từ nhà hàng',
  },
  restRecvScanLatLng: {
    ja: 'レストラン 入荷緯度・経度',
    en: 'Longitude/Latitude (Restaurant Receiving)',
    zhcn: '餐厅收货',
    zhtw: '餐厅收货',
    ko: '레스토랑 입고 위도 · 경도',
    th: 'ลองจิจูด/ละติจูด (การรับสินค้าจากร้านอาหร)',
    vi: 'Kinh độ/Vĩ độ (Nhận hàng từ nhà hàng)',
  },
  restRecvWarning: {
    ja: 'レストラン 入荷警告',
    en: 'Alert (Restaurant Receiving)',
    zhcn: '餐厅收货纬度和经度',
    zhtw: '餐厅收货纬度和经度',
    ko: '레스토랑 입하 경고',
    th: 'แจ้งเตือน (การรับสินค้าจากร้านอาหาร)',
    vi: 'Cảnh báo (Nhận hàng từ nhà hàng)',
  },
  requireSurveyForRestRecv: {
    ja: 'レストラン 入荷要調査',
    en: 'Investigation Required (Restaurant Receiving)',
    zhcn: '餐厅收货警告',
    zhtw: '餐厅收货警告',
    ko: '레스토랑 입고 조사',
    th: 'ต้องการการสำรวจ (การรับสินค้าจากร้านอาหาร)',
    vi: 'Yêu cầu khảo sát (Nhận hàng từ nhà hàng)',
  },
  restRecvUser: {
    ja: 'レストラン 入荷ユーザ',
    en: 'User (Restaurant Receiving)',
    zhcn: '餐厅收货调查',
    zhtw: '餐厅收货调查',
    ko: '레스토랑 입하 사용자',
    th: 'ผู้ใช้ (การรับสินค้าจากร้านอาหาร)',
    vi: 'Người dùng (Nhận hàng từ nhà hàng)',
  },
  checkedRestRecvWarning: {
    ja: 'レストラン 入荷警告確認済',
    en: 'Confirmed Alert (Restaurant Receiving)',
    zhcn: '餐厅收货用户',
    zhtw: '餐厅收货用户',
    ko: '레스토랑 입하 경고 확인 완료',
    th: 'ยืนยันการแจ้งเตือน (การรับสินค้าจากร้านอาหาร)',
    vi: 'Xác nhận cảnh báo (Nhận hàng từ nhà hàng)',
  },
  restSoldAt: {
    ja: 'レストラン 販売日',
    en: 'Restaurant Sales Date',
    zhcn: '餐厅收货警告确认',
    zhtw: '餐厅收货警告确认',
    ko: '레스토랑 판매 일',
    th: 'วันที่ขายสินค้าจากร้านอาหาร',
    vi: 'Ngày bán hàng từ nhà hàng',
  },
  restSoldAtWithTime: {
    ja: '販売日時',
    en: 'Restaurant Sales Date',
    zhcn: '销售日期和时间',
    zhtw: '销售日期和时间',
    ko: '판매 일시',
    th: 'วันและเวลาที่ขายสินค้าจากร้านอาหาร',
    vi: 'Ngày và giờ bán hàng từ nhà hàng',
  },
  restSoldScanLatLng: {
    ja: 'レストラン 販売緯度・経度',
    en: 'Longitude/Latitude (Restaurant Sales)',
    zhcn: '餐厅销售日期',
    zhtw: '餐厅销售日期',
    ko: '레스토랑 판매 위도 · 경도',
    th: 'ลองจิจูด/ละติจูด (การขายสินค้าจากร้านอาหาร)',
    vi: 'Kinh độ/Vĩ độ (Bán hàng từ nhà hàng)',
  },
  restSoldWarning: {
    ja: 'レストラン 販売警告',
    en: 'Alert (Restaurant Sales)',
    zhcn: '本店销售的警告',
    zhtw: '本店销售的警告',
    ko: '레스토랑 영업 경고',
    th: 'แจ้งเตือน (การขายสินค้าจากร้านอาหาร)',
    vi: 'Cảnh báo (Bán hàng từ nhà hàng)',
  },
  requireSurveyForRestSold: {
    ja: 'レストラン 販売要調査',
    en: 'Required Investigation (Restaurant Sales)',
    zhcn: '需要本店销售调查',
    zhtw: '需要本店销售调查',
    ko: '레스토랑 판매 요구 조사',
    th: '้องการการสำรวจ (การขายสินค้าจากร้านอาหาร)',
    vi: 'Yêu cầu khảo sát (Bán hàng từ nhà hàng)',
  },
  restSoldUser: {
    ja: 'レストラン 販売ユーザ',
    en: 'User (Restaurant Sales)',
    zhcn: '本店销售的用户',
    zhtw: '本店销售的用户',
    ko: '레스토랑 판매 사용자',
    th: 'ผู้ใช้ (การขายสินค้าจากร้านอาหาร)',
    vi: 'Người dùng (Bán hàng từ nhà hàng)',
  },
  checkedRestSoldWarning: {
    ja: 'レストラン 販売警告確認済',
    en: 'Confirmed Alert (Restaurant Sales)',
    zhcn: '本店销售的警告确认',
    zhtw: '本店销售的警告确认',
    ko: '레스토랑 영업 경고 확인 완료',
    th: 'ยืนยันการแจ้งเตือน (กาขายสินค้าจากร้านอาหาร)',
    vi: 'Xác nhận cảnh báo (Bán hàng từ nhà hàng)',
  },
  restNote: {
    ja: 'レストラン 備考',
    en: 'Note (Restaurant)',
    zhcn: '餐厅备注',
    zhtw: '餐厅备注',
    ko: '레스토랑 비고',
    th: 'หมายเหตุ (ร้านอาหาร)',
    vi: 'Ghi chú (Nhà hàng)',
  },
  distributor: {
    ja: 'ディストリビュータ',
    en: 'Distributor',
    zhcn: '分销商',
    zhtw: '分销商',
    ko: '유통업자',
    th: 'ผู้จัดจำหน่าย',
    vi: 'Nhà phân phối',
  },
  restaurant: {
    ja: 'レストラン',
    en: 'Restaurant',
    zhcn: '餐厅',
    zhtw: '餐厅',
    ko: '레스토랑',
    th: 'ร้านอาหาร',
    vi: 'Nhà hàng',
  },
  recvRestLoc: {
    ja: '入荷レストラン',
    en: 'Receiving Restaurant',
    zhcn: '收货货厅',
    zhtw: '收货餐厅',
    ko: '입하 레스토랑',
    th: 'ร้านอาหารที่รับสินค้า',
    vi: 'Nhà hàng nhận hàng',
  },
  soldRestLoc: {
    ja: '販売レストラン',
    en: 'Sales Restaurant',
    zhcn: '销售餐厅',
    zhtw: '销售餐厅',
    ko: '판매 레스토랑',
    th: 'ร้านอาหารที่ขายสิน้า',
    vi: 'Nhà hàng bán hàng',
  },
  locationInfo: {
    ja: '位置情報',
    en: 'Location Information',
    zhcn: '地点信息',
    zhtw: '地点信息',
    ko: '위치 정보',
    th: 'ข้อมูลตำแหน่ง',
    vi: 'Thông tin vị trí',
  },
  editUser: {
    ja: 'ユーザ情報を編集',
    en: 'Edit User Information',
    zhcn: '编辑用户信息',
    zhtw: '编辑用户信息',
    ko: '사용자 정보 수정',
    th: 'แก้ไขข้อมูลผู้ใช้',
    vi: 'Chỉnh sửa thông tin người dùng',
  },
  addNewUser: {
    ja: '新規ユーザを追加',
    en: 'Add New User',
    zhcn: '添加新用户',
    zhtw: '添加新用户',
    ko: '새 사용자 추가',
    th: 'เพิ่มผู้ใช้ใหม่',
    vi: 'Thêm người dùng mới',
  },
  uid: {
    ja: 'ユーザID',
    en: 'User ID',
    zhcn: '用户身份',
    zhtw: '用户身份',
    ko: '사용자 ID',
    th: 'ID ผู้ใช้',
    vi: 'ID người dùng',
  },
  password: {
    ja: 'Password',
    en: 'Password',
    zhcn: '密码',
    zhtw: '密码',
    ko: '비밀번호',
    th: 'รหัสผ่าน',
    vi: 'Mật khẩu',
  },
  language: {
    ja: '言語',
    en: 'Language',
    zhcn: '语言',
    zhtw: '语言',
    ko: '언어',
    th: 'ภาษา',
    vi: 'Ngôn ngữ',
  },
  company: {
    ja: '会社',
    en: 'Company',
    zhcn: '公司',
    zhtw: '公司',
    ko: '회사',
    th: 'บริษัท',
    vi: 'Công ty',
  },
  email: {
    ja: 'メールアドレス',
    en: 'Email',
    zhcn: '邮件地址',
    zhtw: '邮件地址',
    ko: '이메일 주소',
    th: 'อีเมล',
    vi: 'Email',
  },
  emailLogin: {
    ja: 'Email Address',
    en: 'Email',
    zhcn: '邮件地址',
    zhtw: '邮件地址',
    ko: '이메일 주소',
    th: 'ที่อยู่อีเมล',
    vi: 'Địa chỉ email',
  },
  role: {
    ja: '権限',
    en: 'Role',
    zhcn: '权限',
    zhtw: '权限',
    ko: '역할',
    th: 'บทบาท',
    vi: 'Vai trò',
  },
  businessType: {
    ja: '業種',
    en: 'Business Type',
    zhcn: '行业类别',
    zhtw: '行業類別',
    ko: '업종',
    th: 'ประเภทธุรกิจ',
    vi: 'Loại hình kinh doanh',
  },
  pleaseRegTheLocBeforeUserReg: {
    ja: 'ユーザ登録の前に、拠点を登録してください',
    en: 'Register the location before user registration',
    zhcn: '在用户登记的前方，请注册地奌',
    zhtw: '在用戶登記的前方，請註冊地點',
    ko: '사용자 등록 전에 지점을 등록하십시오',
    th: 'กรุณาลงทะเบียนสถานที่ก่อนลงทะเบียนผู้ใช้',
    vi: 'Vui lòng đăng ký vị trí trước khi đăng ký người dùng',
  },
  pleaseSetTheLocation: {
    ja: '拠点を設定してください',
    en: 'Please set a location',
    zhcn: '请设置地址',
    zhtw: '請設置地址',
    ko: '지점을 설정하십시오',
    th: 'กรุณาตั้งค่าสถานที่',
    vi: 'Vui lòng đặt một vị trí',
  },
  pleaseSetTheName: {
    ja: '名前を設定してください',
    en: 'Please set a name',
    zhcn: '请设置名称',
    zhtw: '請設置名稱',
    ko: '이름을 설정하십시오',
    th: 'กรุณาตั้งชื่อ',
    vi: 'Vui lòng đặt tên',
  },
  pleaseSetTheEmail: {
    ja: 'メールアドレスを設定してください',
    en: 'Please set an email address',
    zhcn: '请设置电子邮件地址',
    zhtw: '請設置電子郵件地址',
    ko: '이메일 주소를 설정하십시오',
    th: 'กรุณาตั้งค่าที่อยู่อีเมล',
    vi: 'Vui lòng đặt một địa chỉ email',
  },
  pleaseSetThePassword: {
    ja: 'パスワードを設定してください',
    en: 'Please set a password',
    zhcn: '请设置密码',
    zhtw: '請設置密碼',
    ko: '암호를 설정하십시오',
    th: 'กรุณาตั้งรหัสผ่าน',
    vi: 'Vui lòng đặt một mật khẩu',
  },
  editLocation: {
    ja: '拠点情報を編集',
    en: 'Edit Location',
    zhcn: '添加新地奌',
    zhtw: '添加新地點',
    ko: '지점 정보를 수정',
    th: 'แก้ไขข้อมูลสถานที่',
    vi: 'Chỉnh sửa thông tin vị trí',
  },
  addNewLocation: {
    ja: '新規拠点を追加',
    en: 'Add New Location',
    zhcn: '添加新基地',
    zhtw: '添加新基地',
    ko: '신규 지점을 추가',
    th: 'เพิ่มสถานที่ใหม่',
    vi: 'Thêm vị trí mới',
  },
  latitude: {
    ja: '緯度',
    en: 'Latitude',
    zhcn: '纬度',
    zhtw: '緯度',
    ko: '위도',
    th: 'ละติจูด',
    vi: 'Vĩ độ',
  },
  longitude: {
    ja: '経度',
    en: 'Longitude',
    zhcn: '经度',
    zhtw: '經度',
    ko: '경도',
    th: 'ลองจิจูด',
    vi: 'Kinh độ',
  },
  category: {
    ja: 'カテゴリ',
    en: 'Category',
    zhcn: '类别',
    zhtw: '類別',
    ko: '카테고리',
    th: 'หมวดหมู่',
    vi: 'Danh mục',
  },
  subCategory: {
    ja: 'サブカテゴリ',
    en: 'Sub Category',
    zhcn: '子目录',
    zhtw: '子目錄',
    ko: '하위 카테고리',
    th: 'หมวดหมู่ย่อย',
    vi: 'Danh mục phụ',
  },
  hiddenSetting: {
    ja: '非表示設定',
    en: 'Hidden Setting',
    zhcn: '非显示设置',
    zhtw: '非顯示設置',
    ko: '비 표시 설정',
    th: 'การตั้งค่าการซ่อน',
    vi: 'Cài đặt ẩn',
  },
  setToHidden: {
    ja: 'アプリ上で非表示にする',
    en: 'Hide in Application',
    zhcn: '要隐藏的应用',
    zhtw: '要隱藏的應用',
    ko: '앱에서 숨기기',
    th: 'ซ่อนในแอปพลิเคชัน',
    vi: 'Ẩn trong ứng dụng',
  },
  hidden: {
    ja: '非表示',
    en: 'Hidden',
    zhcn: '隐藏',
    zhtw: '隱藏',
    ko: '숨기기',
    th: 'ซ่อน',
    vi: 'Ẩn',
  },
  display: {
    ja: '表示',
    en: 'Display',
    zhcn: '展示',
    zhtw: '展示',
    ko: '보기',
    th: 'แสดง',
    vi: 'Hiển thị',
  },
  enable: {
    ja: '有効',
    en: 'Enable',
    zhcn: '现行',
    zhtw: '現行',
    ko: '유효',
    th: 'เปิดใช้งาน',
    vi: 'Bật',
  },
  disable: {
    ja: '無効',
    en: 'Disable',
    zhcn: '无效的',
    zhtw: '無效的',
    ko: '무효',
    th: 'ปิดใช้งาน',
    vi: 'Tắt',
  },
  supplier: {
    ja: '仕入先',
    en: 'Supplier',
    zhcn: '供应商',
    zhtw: '供應商',
    ko: '공급 업체',
    th: 'ผู้จัดจำหน่าย',
    vi: 'Nhà cung cấp',
  },
  country: {
    ja: '国',
    en: 'Country',
    zhcn: '国家',
    zhtw: '國家',
    ko: '국가',
    th: 'ประเทศ',
    vi: 'Quốc gia',
  },
  mapAddress: {
    ja: '住所 [Googleマップ用]',
    en: 'GoogleMap Address',
    zhcn: '地址[为谷歌地图]',
    zhtw: '地址[為谷歌地圖]',
    ko: '주소 [Google지도 용]',
    th: 'ที่อยู่ [สำหรับ Google Maps]',
    vi: 'Địa chỉ [cho Google Maps]',
  },
  mapAddressBaidu: {
    ja: '住所 [Baiduマップ用]',
    en: 'Baidu Map Address',
    zhcn: '地址[为百度地图]',
    zhtw: '地址[為百度地圖]',
    ko: '주소 [Baidu지도 용]',
    th: 'ที่อยู่ [สำหรับ Baidu Maps]',
    vi: 'Địa chỉ [cho Baidu Maps]',
  },
  useMapAddress: {
    ja: '住所 [表示用] と、住所 [Googleマップ用] を同一にする',
    en: 'Displayed Address and GoogleMap Address need to be same',
    zhcn: '和地址[显示]，以相同的地址[为谷歌地图]',
    zhtw: '和地址[顯示]，以相同的地址[為谷歌地圖]',
    ko: '주소[표시용]와 주소 [Google지도 용]는 동일 해야함',
    th: 'ที่อยู่ [สำหรับแสดง] และที่อยู่ [สำหรับ Google Maps] จะต้องเหมือนกัน',
    vi: 'Địa chỉ [hiển thị] và Địa chỉ [cho Google Maps] cần phải giống nhau',
  },
  mainAddress: {
    ja: '住所 [表示用]',
    en: 'Delivery Address',
    zhcn: '地址[显示]',
    zhtw: '地址[顯示]',
    ko: '주소 [표시용]',
    th: 'ที่อยู่ [สำหรับแสดง]',
    vi: 'Địa chỉ [hiển thị]',
  },
  enMainAddress: {
    ja: '住所（英語）',
    en: 'Address (English)',
    zhcn: '地址（英文）',
    zhtw: '地址（英文）',
    ko: '주소 (영어)',
    th: 'ที่อยู่ (ภาษาอังกฤษ)',
    vi: 'Địa chỉ (tiếng Anh)',
  },
  cnMainAddress: {
    ja: '住所（簡体字）',
    en: 'Address (Simplified Chinese)',
    zhcn: '地址（简体字）',
    zhtw: '地址（簡體字）',
    ko: '주소 (간체)',
    th: 'ที่อยู่ (ภาษาจีนตัวย่อ)',
    vi: 'Địa chỉ (tiếng Trung giản thể)',
  },
  hkMainAddress: {
    ja: '住所（繁体字）',
    en: 'Address (Traditional Chinese)',
    zhcn: '地址（繁体字）',
    zhtw: '地址（繁體字）',
    ko: '주소 (번체)',
    th: 'ที่อยู่ (ภาษาจีนตัวเต็ม)',
    vi: 'Địa chỉ (tiếng Trung phồn thể)',
  },
  krMainAddress: {
    ja: '住所（韓国語）',
    en: 'Address (South Korea)',
    zhcn: '地址（韩国）',
    zhtw: '地址（韓國）',
    ko: '주소 (한)',
    th: 'ที่อยู่ (เกาหลีใต้)',
    vi: 'Địa chỉ (Hàn Quốc)',
  },
  allowableRange: {
    ja: '誤差許容範囲',
    en: 'Tolerable Scan Discrepancy',
    zhcn: '允许误差范围',
    zhtw: '允許誤差範囲',
    ko: '오차 허용 범위',
    th: 'ช่วงความเป็นไปได้ของความผิดพลาด',
    vi: 'Phạm vi sai sót cho phép',
  },
  distanceBetweenRegisteredLocation: {
    ja: '{location}と登録拠点間の距離',
    en: 'Distance between {location} and registered location',
    zhcn: '{location}与注册地址之间的距离',
    zhtw: '{location}与注册地址之间的距离',
    ko: '{location}와 등록 지점 간의 거리',
    th: 'ระยะห่างระหว่าง {location} และสถานที่ที่ลงทะเบียน',
    vi: 'Khoảng cách giữa {location} và vị trí đã đăng ký',
  },
  scannedLocation: {
    ja: 'スキャン位置',
    en: 'Scanned location',
    zhcn: '扫描位置',
    zhtw: '掃描位置',
    ko: '스캔 위치',
    th: 'ตำแหน่งที่สแกน',
    vi: 'Vị trí đã quét',
  },
  outgoingLocation: {
    ja: '出荷位置',
    en: 'Shipping location',
    zhcn: '发货位置',
    zhtw: '發貨位置',
    ko: '출하 위치',
    th: 'สถานที่จัดส่ง',
    vi: 'Vị trí giao hàng',
  },
  allowableRange2: {
    ja: 'スキャン位置と登録位置との誤差許容範囲',
    en: 'Tolerable range between the scanned location and the registered location',
    zhcn: '扫描位置和登记位置的误差容许范围',
    zhtw: '掃描位置和登記位置的誤差容許範囲',
    ko: '스캔 위치와 등록 위치와의 오차 허용 범위',
    th: 'ช่วงความเป็นไปได้ของความผิดพลาดระหว่างตำแหน่งที่สแกนและตำแหน่งที่ลงทะเบียน',
    vi: 'Phạm vi sai sót cho phép giữa vị trí đã quét và vị trí đã đăng ký',
  },
  supplierId: {
    ja: '仕入先ID',
    en: 'Supplier ID',
    zhcn: '供应商ID',
    zhtw: '供應商ID',
    ko: '공급 업체 ID',
    th: 'รหัสผู้จัดจำหน่าย',
    vi: 'ID nhà cung cấp',
  },
  editBrand: {
    ja: '銘柄を編集',
    en: 'Edit Brand',
    zhcn: '编辑品牌',
    zhtw: '編輯品牌',
    ko: '브랜드 수정',
    th: 'แก้ไขแบรนด์',
    vi: 'Chỉnh sửa thương hiệu',
  },
  brandUrl: {
    ja: '銘柄URL',
    en: 'Brand URL',
    zhcn: '品牌网址',
    zhtw: '品牌網址',
    ko: '브랜드 URL',
    th: 'URL แบรนด์',
    vi: 'URL thương hiệu',
  },
  intro: {
    ja: '紹介',
    en: 'Introduction',
    zhcn: '介绍',
    zhtw: '介紹',
    ko: '소개',
    th: 'แนะนำ',
    vi: 'Giới thiệu',
  },
  enIntro: {
    ja: '紹介（英語）',
    en: 'Introduction (English)',
    zhcn: '介绍（英文）',
    zhtw: '介紹（英文）',
    ko: '소개 (영어)',
    th: 'การแนะนำ (ภาษาอังกฤษ)',
    vi: 'Giới thiệu (tiếng Anh)',
  },
  cnIntro: {
    ja: '紹介（簡体字）',
    en: 'Introduction (Simplified Chinese)',
    zhcn: '介绍（简体字）',
    zhtw: '介紹（簡體字）',
    ko: '소개 (간체)',
    th: 'การแนะนำ (ภาษาจีนตัวย่อ)',
    vi: 'Giới thiệu (tiếng Trung giản thể)',
  },
  hkIntro: {
    ja: '紹介（繁体字）',
    en: 'Introduction (Traditional Chinese)',
    zhcn: '介绍（繁体字）',
    zhtw: '介紹（繁體字）',
    ko: '소개 (번체)',
    th: 'การแนะนำ (ภาษาจีนตัวเต็ม)',
    vi: 'Giới thiệu (tiếng Trung phồn thể)',
  },
  krIntro: {
    ja: '紹介（韓国語）',
    en: 'Introduction (Korean)',
    zhcn: '介绍（韩语）',
    zhtw: '介紹（韓語）',
    ko: '소개 (한국어)',
    th: 'การแนะนำ (ภาษาเกาหลี)',
    vi: 'Giới thiệu (tiếng Hàn)',
  },
  tastingNote1: {
    ja: '試飲情報 [1]',
    en: 'Tasting Note [1]',
    zhcn: '品尝信息[1]',
    zhtw: '品嚐信息[1]',
    ko: '시음 정보 [1]',
    th: 'บันทึกการเลิกเมือง [1]',
    vi: 'Ghi chú thử nghiệm [1]',
  },
  enTastingNote1: {
    ja: '試飲情報 [1] (英語)',
    en: 'Tasting Note [1] (English)',
    zhcn: '品尝信息[1]（英国）',
    zhtw: '品嚐信息[1]（英國）',
    ko: '시음 정보 [1] (영문)',
    th: 'บันทึกการชิม [1] (ภาษาอังกฤษ)',
    vi: 'Ghi chú thử nghiệm [1] (tiếng Anh)',
  },
  cnTastingNote1: {
    ja: '試飲情報 [1] (簡体字)',
    en: 'Tasting Note [1] (Simplified Chinese)',
    zhcn: '品尝信息[1]（简体字）',
    zhtw: '品嚐信息[1]（簡體字）',
    ko: '시음 정보 [1] (간체)',
    th: 'บันทึกการเลิกเมือง [1] (ภาษาจีนตัวย่อ)',
    vi: 'Ghi chú thử nghiệm [1] (tiếng Trung giản thể)',
  },
  hkTastingNote1: {
    ja: '試飲情報 [1] (繁体字)',
    en: 'Tasting Note [1] (Traditional Chinese)',
    zhcn: '品酒信息[1]（繁体）',
    zhtw: '品酒信息[1]（繁體字）',
    ko: '시음 정보 [1] (번체)',
    th: 'บันทึกการเลิกเมือง [1] (ภาษาจีนตัวเต็ม)',
    vi: 'Ghi chú thử nghiệm [1] (tiếng Trung phồn thể)',
  },
  krTastingNote1: {
    ja: '試飲情報 [1] (韓国語)',
    en: 'Tasting Note [1] (Korean)',
    zhcn: '品尝信息[1]（韩语）',
    zhtw: '品嚐信息[1]（韓語）',
    ko: '시음 정보 [1] (한국어)',
    th: 'บันทึกการเลิกเมือง [1] (ภาษาเกาหลี)',
    vi: 'Ghi chú thử nghiệm [1] (tiếng Hàn)',
  },
  tastingNote2: {
    ja: '試飲情報 [2]',
    en: 'Tasting Note [2]',
    zhcn: '品尝信息[2]',
    zhtw: '品嚐信息[2]',
    ko: '시음 정보 [2]',
    th: 'บันทึกการเลิกเมือง [2]',
    vi: 'Ghi chú thử nghiệm [2]',
  },
  enTastingNote2: {
    ja: '試飲情報 [2] (英語)',
    en: 'Tasting Note [2] (English)',
    zhcn: '品尝信息[2]（英国）',
    zhtw: '品嚐信息[2]（英國）',
    ko: '시음 정보 [2] (영문)',
    th: 'บันทึกการเลิกเมือง [2] (ภาษาอังกฤษ)',
    vi: 'Ghi chú thử nghiệm [2] (tiếng Anh)',
  },
  cnTastingNote2: {
    ja: '試飲情報 [2] (簡体字)',
    en: 'Tasting Note [2] (Simplified Chinese)',
    zhcn: '品尝信息[2]（简体字）',
    zhtw: '品嚐信息[2]（簡體字）',
    ko: '시음 정보 [2] (간체)',
    th: 'บันทึกการชิม [2] (ภาษาจีนตัวย่อ)',
    vi: 'Ghi chú thử nghiệm [2] (tiếng Trung giản thể)',
  },
  hkTastingNote2: {
    ja: '試飲情報 [2] (繁体字)',
    en: 'Tasting Note [2] (Traditional Chinese)',
    zhcn: '品酒信息[2]（繁体字）',
    zhtw: '品酒信息[2]（繁體字）',
    ko: '시음 정보 [2] (번체)',
    th: 'บันทึกการเลิกเมือง [2] (ภาษาจีนตัวเต็ม)',
    vi: 'Ghi chú thử nghiệm [2] (tiếng Trung phồn thể)',
  },
  krTastingNote2: {
    ja: '試飲情報 [2] (韓国語)',
    en: 'Tasting Note [2] (Korean)',
    zhcn: '品尝信息[2]（韩语）',
    zhtw: '品嚐信息[2]（韓語）',
    ko: '시음 보 [2] (한국어)',
    th: 'บันทึกการเลิกเมือง [2] (ภาษาเกาหลี)',
    vi: 'Ghi chú thử nghiệm [2] (tiếng Hàn)',
  },
  paringFood: {
    ja: 'ペアリングフード',
    en: 'Paring Food',
    zhcn: '配对食物',
    zhtw: '配對食物',
    ko: '페어링 푸드',
    th: 'อาหารที่จับคู่',
    vi: 'Món ăn phối hợp',
  },
  enParingFood: {
    ja: 'ペアリングフード (英語)',
    en: 'Paring Food (English)',
    zhcn: '配对食品（英语）',
    zhtw: '配對食品（英語）',
    ko: '페어링 푸드 (영문)',
    th: 'อาหารที่จับคู่ (ภาษาอังกฤษ)',
    vi: 'Món ăn phối hợp (tiếng Anh)',
  },
  cnParingFood: {
    ja: 'ペアリングフード (簡体字)',
    en: 'Paring Food (Simplified Chinese)',
    zhcn: '配对食品（简体字）',
    zhtw: '配對食品（簡體字）',
    ko: '페어링 푸드 (간체)',
    th: 'อาหารที่จับคู่ (ภาษาจีนตัวย่อ)',
    vi: 'Món ăn phối hợp (tiếng Trung giản thể)',
  },
  hkParingFood: {
    ja: 'ペアリングフード (繁体字)',
    en: 'Paring Food (Traditional Chinese)',
    zhcn: '配对食品（繁体字）',
    zhtw: '配對食品（繁體字）',
    ko: '페어링 푸드 (번체)',
    th: 'อาหารที่จับคู่ (ภาษาจีนตัวเต็ม)',
    vi: 'Món ăn phối hợp (tiếng Trung phồn thể)',
  },
  krParingFood: {
    ja: 'ペアリングフード (韓国語)',
    en: 'Paring Food (Korean)',
    zhcn: '配对食物（韩文）',
    zhtw: '配對食物（韓文）',
    ko: '페어링 푸드 (한국어)',
    th: 'อาหารที่จับคู่ (ภาษาเกาหลี)',
    vi: 'Món ăn phối hợp (tiếng Hàn)',
  },
  servingSuggestion: {
    ja: 'おすすめの飲み方',
    en: 'Serving Suggestion',
    zhcn: '推荐的品尝方法',
    zhtw: '推薦的品嘗方法',
    ko: '마시는 방법 추천',
    th: 'คำแนะนำในการดื่ม',
    vi: 'Gợi ý phục vụ',
  },
  enServingSuggestion: {
    ja: 'おすすめの飲み方 (英語)',
    en: 'Serving Suggestion (English)',
    zhcn: '推荐的品尝方法（英文）',
    zhtw: '推薦的品嘗方法（英文）',
    ko: '마시는 방법 추천 (영문)',
    th: 'คำแนะนำในการดื่ม (ภาษาอังกฤษ)',
    vi: 'Gợi ý phục vụ (tiếng Anh)',
  },
  cnServingSuggestion: {
    ja: 'おすすめの飲み方 (簡体字)',
    en: 'Serving Suggestion (Simplified Chinese)',
    zhcn: '推荐的品尝方法（简体字）',
    zhtw: '推薦的品嘗方法（簡體字）',
    ko: '마시는 방법 추천 (간체)',
    th: 'คำแนะนำในการดื่ม (ภาษาจีนตัวย่อ)',
    vi: 'Gợi ý phục vụ (tiếng Trung giản thể)',
  },
  hkServingSuggestion: {
    ja: 'おすすめの飲み方 (繁体字)',
    en: 'Serving Suggestion (Traditional Chinese)',
    zhcn: '推荐的品尝方法（繁体字）',
    zhtw: '推薦的品嘗方法（繁體字）',
    ko: '마시는 방법 추천 (번체)',
    th: 'คำแนะนำในการดื่ม (ภาษาจีนตัวเต็ม)',
    vi: 'Gợi ý phục vụ (tiếng Trung phồn thể)',
  },
  krServingSuggestion: {
    ja: 'おすすめの飲み方 (韓国語)',
    en: 'Serving Suggestion (Korean)',
    zhcn: '推荐的品尝方法（韩文）',
    zhtw: '推薦的品嘗方法（韓文）',
    ko: '마시는 방법 추천 (한국어)',
    th: 'คำแนะนำในการดื่ม (ภาษาเกาหลี)',
    vi: 'Gợi ý phục vụ (tiếng Hàn)',
  },
  riceSpecific: {
    ja: '酒米',
    en: 'Sake Ingredients Rice',
    zhcn: '清酒大米',
    zhtw: '清酒米種',
    ko: '주미',
    th: 'ข้าวสากเช้า',
    vi: 'Gạo cho sake',
  },
  enRiceSpecific: {
    ja: '酒米 (英語)',
    en: 'Sake Ingredients Rice (English)',
    zhcn: '酿酒大米（英文）',
    zhtw: '釀酒米種（英文）',
    ko: '주미 (영문)',
    th: 'ข้าวสากเช้า (ภาษาอังกฤษ)',
    vi: 'Gạo cho sake (tiếng Anh)',
  },
  cnRiceSpecific: {
    ja: '酒米 (簡体字)',
    en: 'Sake Ingredients Rice (Simplified Chinese)',
    zhcn: '酿酒大米（简体字）',
    zhtw: '釀酒米種（簡體字）',
    ko: '주미 (간체)',
    th: 'ข้าวสากเช้า (ภาษาจีนตัวย่อ)',
    vi: 'Gạo cho sake (tiếng Trung giản thể)',
  },
  hkRiceSpecific: {
    ja: '酒米 (繁体字)',
    en: 'Sake Ingredients Rice (Traditional Chinese)',
    zhcn: '酿酒大米（繁体字）',
    zhtw: '釀酒米種（繁體字）',
    ko: '주미 (번체)',
    th: 'ข้าวสากเช้า (ภาษาจีนตัวเต็ม)',
    vi: 'Gạo cho sake (tiếng Trung phồn thể)',
  },
  krRiceSpecific: {
    ja: '酒米 (韓国語)',
    en: 'Sake Ingredients Rice (Korean)',
    zhcn: '酿酒米（韩国）',
    zhtw: '釀酒米（韓國）',
    ko: '주미 (한국어)',
    th: 'ข้าวสากเช้า (ภาษาเกาหลี)',
    vi: 'Gạo cho sake (tiếng Hàn)',
  },
  bottleSize: {
    ja: 'ボトルサイズ',
    en: 'Bottle Size',
    zhcn: '瓶大小',
    zhtw: '瓶大小',
    ko: '병 크기',
    th: 'ขนาดขวด',
    vi: 'Kích thước chai',
  },
  enBottleSize: {
    ja: 'ボトルサイズ (英語)',
    en: 'Bottle Size (English)',
    zhcn: '瓶口尺寸（英文）',
    zhtw: '瓶口尺寸（英文）',
    ko: '병 크기 (영문)',
    th: 'ขนาดขวด (ภาษาอังกฤษ)',
    vi: 'Kích thước chai (tiếng Anh)',
  },
  cnBottleSize: {
    ja: 'ボトルサイズ (簡体字)',
    en: 'Bottle Size (Simplified Chinese)',
    zhcn: '瓶大小（简体字）',
    zhtw: '瓶大小（簡體字）',
    ko: '병 크기 (간체)',
    th: 'ขนาดขวด (ภาษาจีนตัวย่อ)',
    vi: 'Kích thước chai (tiếng Trung giản thể)',
  },
  hkBottleSize: {
    ja: 'ボトルサイズ (繁体字)',
    en: 'Bottle Size (Traditional Chinese)',
    zhcn: '瓶大小（繁体字）',
    zhtw: '瓶大小（繁體字）',
    ko: '병 크기 (번체)',
    th: 'ขนาดขวด (ภาษาจีนตัวเต็ม)',
    vi: 'Kích thước chai (tiếng Trung phồn thể)',
  },
  krBottleSize: {
    ja: 'ボトルサイズ (韓国語)',
    en: 'Bottle Size (Korean)',
    zhcn: '瓶大小（韩文）',
    zhtw: '瓶大小（韓文）',
    ko: '병 크기 (한국어)',
    th: 'ขนาดขวด (ภาษาเกาหลี)',
    vi: 'Kích thước chai (tiếng Hàn)',
  },
  alcohol: {
    ja: 'アルコール度数',
    en: 'Alcohol Content',
    zhcn: '酒精度',
    zhtw: '酒精度',
    ko: '알코올 도수',
    th: 'ปริมาณแอลกอฮอล์',
    vi: 'Nội dung cồn',
  },
  enAlcohol: {
    ja: 'アルコール度数 (英語)',
    en: 'Alcohol Content (English)',
    zhcn: '酒精（英文）',
    zhtw: '酒精（英文）',
    ko: '알코올 도수 (영문)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาอังกฤษ)',
    vi: 'Nội dung cồn (tiếng Anh)',
  },
  cnAlcohol: {
    ja: 'アルコール度数 (簡体字)',
    en: 'Alcohol Content (Simplified Chinese)',
    zhcn: '酒精（简体字）',
    zhtw: '酒精（簡體字）',
    ko: '알코올 도수 (간체)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาจีนตัวย่อ)',
    vi: 'Nội dung cồn (tiếng Trung giản thể)',
  },
  hkAlcohol: {
    ja: 'アルコール度数 (繁体字)',
    en: 'Alcohol Content (Traditional Chinese)',
    zhcn: '酒精（繁体字）',
    zhtw: '酒精（繁體字）',
    ko: '알코올 도수 (번체)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาจีนตัวเต็ม)',
    vi: 'Nội dung cồn (tiếng Trung phồn thể)',
  },
  krAlcohol: {
    ja: 'アルコール度数 (韓国語)',
    en: 'Alcohol Content (Korean)',
    zhcn: '酒精（韩国）',
    zhtw: '酒精（韓國）',
    ko: '알코올 도수 (한국어)',
    th: 'ปริมาณแอลกอฮอล์ (ภาษาเกาหลี)',
    vi: 'Nội dung cồn (tiếng Hàn)',
  },
  addNewBrand: {
    ja: '新規銘柄を追加',
    en: 'Add New Brand',
    zhcn: '添加一个新的品牌',
    zhtw: '添加一個新的品牌',
    ko: '신규 브랜드 추가',
    th: 'เพิ่มสัญลักษณ์รุ่นใหม่',
    vi: 'Thêm thương hiệu mới',
  },
  enableDirectSettingOfImageUrl: {
    ja: '画像URLを直接設定する',
    en: 'Enable direct setting of image URL',
    zhcn: '要直接设置图像URL',
    zhtw: '要直接設置圖像URL',
    ko: '이미지 URL을 직접 설정',
    th: 'เปิดใช้งานการตั้งค่า URL รูปภาพโดยตรง',
    vi: 'Bật cài đặt URL hình ảnh trực tiếp',
  },
  enableDirectSettingOfLogoImageUrl: {
    ja: 'ロゴ画像URLを直接設定する',
    en: 'Enable direct setting of logo image URL',
    zhcn: '直接设置标志图像URL',
    zhtw: '直接設置標誌圖像URL',
    ko: '로고 이미지 URL을 직접 설정',
    th: 'เปิดใช้งานการตั้งค่า URL รูปภาพโดยตรง',
    vi: 'Bật cài đặt URL hình ảnh trực tiếp',
  },
  user: {
    ja: 'ユーザ',
    en: 'User',
    zhcn: '用户',
    zhtw: '用戶',
    ko: '사용자',
    th: 'ผู้ใช้',
    vi: 'Người dùng',
  },
  slipNum: {
    ja: '伝票番号',
    en: 'Sales Order Number',
    zhcn: '单号',
    zhtw: '單號',
    ko: '전표 번호',
    th: 'หมายเลขใบสั่งขาย',
    vi: 'Số đơn đặt hàng',
  },
  deliveryHistory: {
    ja: '配送履歴',
    en: 'Delivery History',
    zhcn: '交货历史',
    zhtw: '交貨歷史',
    ko: '배송 내역',
    th: 'ประวัติการจัดส่ง',
    vi: 'Lịch sử giao hàng',
  },
  shippingHistory: {
    ja: '出荷履歴',
    en: 'Shipping History',
    zhcn: '运输历史',
    zhtw: '運輸歷史',
    ko: '배송 내역',
    th: 'ประวัติการส่งสินค้า',
    vi: 'Lịch sử vận chuyển',
  },
  todaySummarybrew: {
    ja: '出荷済み（本日分）',
    en: "Today's Summary",
    zhcn: '發貨（今天）',
    zhtw: '发货（今天）',
    ko: '선적 완료(오늘 분)',
    th: 'สรุปวันนี้',
    vi: 'Tóm tắt hôm nay',
  },
  todaySummarydist: {
    ja: '配送済み（本日分）',
    en: "Today's Summary",
    zhcn: '已交付（今天）',
    zhtw: '已交付（今天）',
    ko: '배송 완료(오늘 분)',
    th: 'สรุปการจัดส่งวันนี้',
    vi: 'Tóm tắt giao hàng hôm nay',
  },
  todaySummaryrest: {
    ja: '販売済み（本日分）',
    en: "Today's Summary",
    zhcn: '卖完了（今天）',
    zhtw: '賣完了（今天）',
    ko: '판매됨(오늘 분',
    th: 'สรุปการขายวันนี้',
    vi: 'Tóm tắt bán hàng hôm nay',
  },
  allSerials: {
    ja: '全てのシリアル',
    en: 'All Serials',
    zhcn: '所有连续剧',
    zhtw: '所有連續劇',
    ko: '모든 연재물',
    th: 'ทุกซีเรียล',
    vi: 'Tất cả các tập phim liên tiếp',
  },
  registrationNum: {
    ja: '登録番号',
    en: 'Registration Number',
    zhcn: '注册号码',
    zhtw: '註冊號碼',
    ko: '등록 번호',
    th: 'หมายเลขลงทะเบียน',
    vi: 'Số đăng ký',
  },
  detailNum: {
    ja: '詳細番号',
    en: 'Detail Number',
    zhcn: '详细号',
    zhtw: '詳細號',
    ko: '상세 번호',
    th: 'หมายเลขรายละเอียด',
    vi: 'Số chi tiết',
  },
  operation: {
    ja: '入荷/販売',
    en: 'Receiving/Selling',
    zhcn: '库存/销售',
    zhtw: '庫存/銷售',
    ko: '입하/판매',
    th: 'รับสินค้า/ขาย',
    vi: 'Nhận hàng/Bán hàng',
  },
  quantity: {
    ja: '数量',
    en: 'Quantity',
    zhcn: '数量',
    zhtw: '數量',
    ko: '수량',
    th: 'จำนวน',
    vi: 'Số lượng',
  },
  scanLatitude: {
    ja: '緯度（スキャン時）',
    en: 'Scan Latitude',
    zhcn: '纬度（在扫描期间）',
    zhtw: '緯度（在掃描期間）',
    ko: '위도 (스캔)',
    th: 'ละติจูด (เมื่อสแกน)',
    vi: 'Vĩ độ (khi quét)',
  },
  scanLongitude: {
    ja: '経度（スキャン時）',
    en: 'Scan Longitude',
    zhcn: '经度（在扫描的时间）',
    zhtw: '經度（在掃描的時間）',
    ko: '경도 (스캔)',
    th: 'ลองจิจูด (เมื่อสแกน)',
    vi: 'Kinh độ (khi quét)',
  },
  totalSalesContractsCounts: {
    ja: '販売契約数',
    en: 'Sales Contract Quantity',
    zhcn: '销售合同号',
    zhtw: '銷售合同號',
    ko: '판매 계약 수',
    th: 'จำนวนสัญญาขาย',
    vi: 'Số lượng hợp đồng bán hàng',
  },
  totalBrewShipCounts: {
    ja: '総酒蔵出荷数',
    en: 'Total Brewery Shipping Quantity',
    zhcn: '总出货量啤酒厂',
    zhtw: '總出貨量啤酒廠',
    ko: '총 양조장 출하 수',
    th: 'จำนวนการจัดส่งทั้งหมดจากโรงเบียร์',
    vi: 'Tổng số lượng vận chuyển từ nhà máy',
  },
  totalRestRecvCounts: {
    ja: '総レストラン入荷数',
    en: 'Total Restaurant Receiving Quantity',
    zhcn: '餐厅的总收货量',
    zhtw: '餐廳的總收貨量',
    ko: '총 레스토랑 입하',
    th: 'จำนวนการรับสินค้าทั้งหมดจากร้านอาหาร',
    vi: 'Tổng số lượng nhận hàng từ nhà hàng',
  },
  totalRestSoldCounts: {
    ja: '総レストラン販売数',
    en: 'Total Restaurant Sales Quantity',
    zhcn: '餐厅的总销售',
    zhtw: '餐廳的總銷售',
    ko: '총 레스토랑 판매량',
    th: 'จำนวนการขายทั้งหมดจากร้านอาหาร',
    vi: 'Tổng số lượng bán hàng từ nhà hàng',
  },
  totalRestStockCounts: {
    ja: '総レストラン在庫数',
    en: 'Total Restaurant Inventory Quantity',
    zhcn: '餐厅的总库存',
    zhtw: '餐廳的總庫存',
    ko: '총 레스토랑 재고 수량',
    th: 'จำนวนสินค้าคงคลังทั้งหมดในร้านอาหาร',
    vi: 'Tổng số lượng tồn kho tại nhà hàng',
  },
  price: {
    ja: '価格',
    en: 'Price',
    zhcn: '价格',
    zhtw: '價格',
    ko: '가격',
    th: 'ราคา',
    vi: 'Giá',
  },
  temperature: {
    ja: '温度',
    en: 'Temperature',
    zhcn: '温度',
    zhtw: '溫度',
    ko: '온도',
    th: 'อุณหภูมิ',
    vi: 'Nhiệt độ',
  },
  stock: {
    ja: '在庫',
    en: 'Stock',
    zhcn: '库存',
    zhtw: '庫存',
    ko: '재고',
    th: 'สต็อก',
    vi: 'Tồn kho',
  },
  stockCount: {
    ja: '本数',
    en: 'Stock',
    zhcn: '数字',
    zhtw: '數字',
    ko: '개수',
    th: 'จำนวน',
    vi: 'Số lượng',
  },
  stockCountTotal: {
    ja: '合計本数',
    en: 'Total',
    zhcn: '总数',
    zhtw: '總數',
    ko: '총개수',
    th: 'จำนวนทั้งหมด',
    vi: 'Tổng số',
  },
  summary: {
    ja: '概要',
    en: 'Summary',
    zhcn: '概括',
    zhtw: '概括',
    ko: '요약',
    th: 'สรุป',
    vi: 'Tóm tắt',
  },
  details: {
    ja: '明細',
    en: 'Details',
    zhcn: '细节',
    zhtw: '細節',
    ko: '품목',
    th: 'รายละเอียด',
    vi: 'Chi tiết',
  },
  editProfile: {
    ja: 'プロフィール編集',
    en: 'Edit Profile',
    zhcn: '编辑个人资料',
    zhtw: '編輯個人資料',
    ko: '프로필 수정',
    th: 'แก้ไขโปรไฟล์',
    vi: 'Chỉnh sửa hồ sơ',
  },
  inventoryManagement: {
    ja: '在庫管理',
    en: 'Stock Management',
    zhcn: '库存管理',
    zhtw: '庫存管理',
    ko: '재고 관리',
    th: 'การจัดการสต็อก',
    vi: 'Quản lý tồn kho',
  },
  bottleManagement: {
    ja: 'ボトル管理',
    en: 'Bottle management',
    zhcn: '酒瓶管理',
    zhtw: '酒瓶管理',
    ko: '병 관리',
    th: 'การจัดการขวด',
    vi: 'Quản lý chai',
  },
  serialRegistrationDate: {
    ja: '出荷登録予定日',
    en: 'Shipment Registration Date',
    zhcn: '出货登记计划日',
    zhtw: '出貨登記計劃日',
    ko: '발송 등록 예정일',
    th: 'วันที่จะลงทะเบียนการจัดส่ง',
    vi: 'Ngày đăng ký gửi hàng',
  },
  serialRegistration: {
    ja: '商品登録',
    en: 'Product Registration',
    zhcn: '商品登记',
    zhtw: '商品登記',
    ko: '상품 등록',
    th: 'ลงทะเบียนสินค้า',
    vi: 'Đăng ký sản phẩm',
  },
  serialDistRegistrationAt: {
    ja: '入荷受付日',
    en: 'Receiving Date',
    zhcn: '进货日期',
    zhtw: '進貨日期',
    ko: '입하 접수일',
    th: 'วันที่รับสินค้า',
    vi: 'Ngày nhận hàng',
  },
  serialDistRegistration: {
    ja: '入荷受付',
    en: 'Receiving Date',
    zhcn: '进货',
    zhtw: '進貨',
    ko: '입하 접수',
    th: 'รับสินค้า',
    vi: 'Nhận hàng',
  },
  serialSoldRegistrationAt: {
    ja: '販売日',
    en: 'Sale Date',
    zhcn: '发售日期',
    zhtw: '發售日期',
    ko: '판매 일',
    th: 'วันที่ขาย',
    vi: 'Ngày bán',
  },
  back: {
    ja: '戻る',
    en: 'Back',
    zhcn: '回去',
    zhtw: '回去',
    ko: '돌아 가기',
    th: 'กลับ',
    vi: 'Quay lại',
  },
  next: {
    ja: '次へ',
    en: 'Next',
    zhcn: '下一个',
    zhtw: '下一個',
    ko: '다음',
    th: 'ถัดไป',
    vi: 'Tiếp theo',
  },
  finish: {
    ja: '完了',
    en: 'Finish',
    zhcn: '完成',
    zhtw: '完成',
    ko: '완료',
    th: 'เสร็จสิ้น',
    vi: 'Hoàn thành',
  },
  serialCount: {
    ja: '本数',
    en: 'Bottle Quantity',
    zhcn: '瓶数',
    zhtw: '瓶數',
    ko: '개수',
    th: 'จำนวนขวด',
    vi: 'Số lượng chai',
  },
  shipping: {
    ja: '出荷',
    en: 'Shipping',
    zhcn: '发货',
    zhtw: '發貨',
    ko: '출하',
    th: 'การจัดส่ง',
    vi: 'Vận chuyển',
  },
  delivering: {
    ja: '配送',
    en: 'Delivering',
    zhcn: '发货',
    zhtw: '發貨',
    ko: '배송',
    th: 'การจัดส่ง',
    vi: 'Giao hàng',
  },
  shipped: {
    ja: '出荷済み',
    en: 'Shipped',
    zhcn: '已出貨',
    zhtw: '已出货',
    ko: '출하 완료',
    th: 'จัดส่งแล้ว',
    vi: 'Đã gửi',
  },
  delivered: {
    ja: '配送済み',
    en: 'Delivered',
    zhcn: '已发货',
    zhtw: '已發貨',
    ko: '배송 완료',
    th: 'จัดส่งแล้ว',
    vi: 'Đã giao',
  },
  unshipped: {
    ja: '未出荷',
    en: 'Unshipped',
    zhcn: '未发货',
    zhtw: '未發貨',
    ko: '미출하',
    th: 'ยังไม่ได้จัดส่ง',
    vi: 'Chưa gửi',
  },
  unreceived: {
    ja: '未入荷',
    en: 'Unreceived',
    zhcn: '未到货',
    zhtw: '未到貨',
    ko: '미입고',
    th: 'ยังไม่ได้รับ',
    vi: 'Chưa nhận',
  },
  notReceived: {
    ja: '未入荷',
    en: 'Not Received',
    zhcn: '未到货',
    zhtw: '未到貨',
    ko: '미입하',
    th: 'ยังไม่ได้รับ',
    vi: 'Chưa nhận',
  },
  notonsale: {
    ja: '未販売',
    en: 'Unsold',
    zhcn: '未售出',
    zhtw: '未售出',
    ko: '미판매',
    th: 'ยังไม่ขาย',
    vi: 'Chưa bán',
  },
  appBrewery: {
    ja: '酒蔵',
    en: 'Brewery',
    zhcn: '酿酒厂',
    zhtw: '釀酒廠',
    ko: '양조장',
    th: 'โรงเบียร์',
    vi: 'Nhà máy',
  },
  shippingDestination: {
    ja: '出荷先',
    en: 'Shipping Destination',
    zhcn: '运送目的地',
    zhtw: '運送目的地',
    ko: '출하처',
    th: 'สถานที่จัดส่ง',
    vi: 'Địa điểm gửi hàng',
  },
  deliveringDestination: {
    ja: '配送先',
    en: 'Delivery Destination',
    zhcn: '发货目的地',
    zhtw: '發貨目的地',
    ko: '배송처',
    th: 'สถานที่จัดส่ง',
    vi: 'Địa điểm giao hàng',
  },
  restaurant2: {
    ja: '配送先 (レストラン)',
    en: 'Delivery Destination (Restaurant)',
    zhcn: '发货目的地（餐馆）',
    zhtw: '發貨目的地（餐館）',
    ko: '배송처 (레스토랑)',
    th: 'สถานที่จัดส่ง (ร้านอาหาร)',
    vi: 'Địa điểm giao hàng (nhà hàng)',
  },
  selectDistToShip: {
    ja: '出荷先（ディストリビュータ）',
    en: 'Shipping Destination (Distributor)',
    zhcn: '运送目的地（分销商）',
    zhtw: '運送目的地（分銷商）',
    ko: '출하처 (유통)',
    th: 'สถานที่จัดส่ง (ผู้จัดจำหน่าย)',
    vi: 'Địa điểm gửi hàng (nhà phân phối)',
  },
  restHistory: {
    ja: '流通履歴',
    en: 'Distribution Record',
    zhcn: '出货纪录',
    zhtw: '出貨紀錄',
    ko: '유통 이력',
    th: 'ประวัติการจัดจำหน่าย',
    vi: 'Lịch sử phân phối',
  },
  inventoryStatus: {
    ja: '在庫状況',
    en: 'Inventory Status',
    zhcn: '库存状态',
    zhtw: '庫存狀態',
    ko: '재고 상황',
    th: 'สถานะสต็อก',
    vi: 'Tình trạng tồn kho',
  },
  status: {
    ja: 'ステーテス',
    en: 'Status',
    zhcn: '地位',
    zhtw: '地位',
    ko: '상태',
    th: 'สถานะ',
    vi: 'Trạng thái',
  },
  baseName: {
    ja: '拠点名',
    en: 'Location',
    zhcn: '基本名称',
    zhtw: '基本名稱',
    ko: '거점 이름',
    th: 'ชื่อสถานที่',
    vi: 'Tên địa điểm',
  },
  restReceivingAndSelling: {
    ja: '入荷/販売',
    en: 'Receiving/Selling',
    zhcn: '库存/销售',
    zhtw: '庫存/銷售',
    ko: '입하/판매',
    th: 'รับสินค้า/ขาย',
    vi: 'Nhận hàng/Bán hàng',
  },
  sold: {
    ja: '販売済み',
    en: 'Sold',
    zhcn: '已卖出',
    zhtw: '已賣出',
    ko: '판매 완',
    th: 'ขายแล้ว',
    vi: 'Đã bán',
  },
  pleaseSelect: {
    ja: '選択してください...',
    en: 'Please select...',
    zhcn: '请选择...',
    zhtw: '請選擇...',
    ko: '선택하십시오',
    th: 'กรุณาเลือก...',
    vi: 'Vui lòng chọn...',
  },
  pleaseEnter: {
    ja: '入力してください...',
    en: 'Please enter...',
    zhcn: '请输入...',
    zhtw: '請輸入...',
    ko: '입력하십시오',
    th: 'กรุณาป้อน...',
    vi: 'Vui lòng nhập...',
  },
  setShipDestAndInvoice: {
    ja: '出荷先と伝票番号を設定する',
    en: 'Set Shipping Destination and Invoice Number',
    zhcn: '要设置出货目的地和收据号码',
    zhtw: '要設置出貨目的地和收據號碼',
    ko: '출하처 및 인보이스 번호를 설정',
    th: 'ตั้งค่าสถานที่จัดส่งและหมายเลขใบแจ้งหนี้',
    vi: 'Cài đặt địa điểm gửi hàng và số hóa đơn',
  },
  setShipDestAndSalesOrder: {
    ja: '配送先と伝票番号を設定する',
    en: 'Select a delivery address and enter sales order number',
    zhcn: '设置发货目的地和订单号码',
    zhtw: '設置發貨目的地和訂單號碼',
    ko: '배송처 및 주문 번호를 설정',
    th: 'เลือกที่อยู่สำหรับการจัดส่งและป้อนหมายเลขใบสั่งขาย',
    vi: 'Chọn địa chỉ giao hàng và nhập số đơn đặt hàng',
  },
  selectOperation: {
    ja: '入荷/販売を選択',
    en: 'Select Receiving/Selling',
    zhcn: '选择 进货/ 出货',
    zhtw: '選擇 進貨/ 出貨',
    ko: '입하/판매 선택',
    th: 'เลือกรับสินค้า/ขาย',
    vi: 'Chọn Nhận hàng/Bán hàng',
  },
  scanQRCode: {
    ja: 'QRコードをスキャンする',
    en: 'Scan QR Code',
    zhcn: '扫描QR码',
    zhtw: '掃描QR碼',
    ko: 'QR 코드를 스캔',
    th: 'สแกน QR Code',
    vi: 'Quét mã QR',
  },
  registerOutgoingProducts: {
    ja: '出荷する商品を登録する',
    en: 'Register product to ship',
    zhcn: '注册要发货的产品',
    zhtw: '註冊要發貨的商品',
    ko: '출하 할 상품 등록하기',
    th: 'ลงทะเบียนสินค้าที่จะจัดส่ง',
    vi: 'Đăng ký sản phẩm để gửi',
  },
  registerDeliverProducts: {
    ja: '配送する商品を登録する',
    en: 'Register product to deliver',
    zhcn: '注册要发货的产品',
    zhtw: '註冊要發貨的商品',
    ko: '배송 할 상품 등록하기',
    th: 'ลงทะเบียนสินค้าที่จะจัดส่ง',
    vi: 'Đăng ký sản phẩm để giao',
  },
  registerProducts: {
    ja: '商品を登録する',
    en: 'Register product',
    zhcn: '注册产品',
    zhtw: '注册商品',
    ko: '상품 등록하기',
    th: 'ลงทะเบียนสินค้า',
    vi: 'Đăng ký sản phẩm',
  },
  confirmAndCompleteOutgoingProductRegistration: {
    ja: '確認して出荷登録を完了する',
    en: 'Confirm and complete the shipping registration',
    zhcn: '确认并完成运输登记',
    zhtw: '確認並完成運輸登記',
    ko: '확인 후 출하 등록을 완료하다',
    th: 'ยืนยันและดำเนินการลงทะเบียนการจัดส่งให้เสร็จสมบูรณ์',
    vi: 'Xác nhận và hoàn thành đăng ký gửi hàng',
  },
  confirmAndCompleteDeliveryProductRegistration: {
    ja: '確認して配送登録を完了する',
    en: 'Confirm and complete the delivery registration',
    zhcn: '确认并完成发货登记',
    zhtw: '確認並完成發貨登記',
    ko: '확인 후 배송 등록을 완료',
    th: 'ยืนยันและดำเนินการลงทะเบียนการจัดส่งให้เสร็จสมบูรณ์',
    vi: 'Xác nhận và hoàn thành đăng ký giao hàng',
  },
  confirmAndCompleteProductRegistration: {
    ja: '確認して登録を完了する',
    en: 'Confirm and complete the product registration',
    zhcn: '确认并完成注册',
    zhtw: '確認並完成註冊',
    ko: '확인하고 후 등록을 완료하다',
    th: 'ยืนยันและดำเนินการลงทะเบียนสินค้าให้เสร็จสมบูรณ์',
    vi: 'Xác nhận và hoàn thành đăng ký sản phẩm',
  },
  startScan: {
    ja: 'スキャンを開始',
    en: 'Start Scanning',
    zhcn: '开始扫描',
    zhtw: '開始掃描',
    ko: '스캔을 시작',
    th: 'เริ่มสแกน',
    vi: 'Bắt đầu quét',
  },
  qrReader: {
    ja: 'QR読み込み',
    en: 'QR Reader',
    zhcn: 'QR读取',
    zhtw: 'QR讀取',
    ko: 'QR 읽기',
    th: 'อ่าน QR',
    vi: 'Đọc mã QR',
  },
  externalQR: {
    ja: 'URL入力',
    en: 'Input QR code URL',
    zhcn: '输入QR码的URL',
    zhtw: '輸入QR碼的URL',
    ko: 'URL 입력',
    th: 'ป้อน URL ของ QR code',
    vi: 'Nhập URL của mã QR',
  },
  internal: {
    ja: 'デバイス内蔵',
    en: 'Device Built-in',
    zhcn: '内建机制',
    zhtw: '內建機制',
    ko: '디바이스 빌트인',
    th: 'อปกรณ์ภายใน',
    vi: 'Được tích hợp trong thiết bị',
  },
  scanner: {
    ja: 'スキャナー',
    en: 'Scanner',
    zhcn: '扫描器',
    zhtw: '掃描器',
    ko: '스캐너',
    th: 'สแกนเนอร์',
    vi: 'Máy quét',
  },
  camera: {
    ja: 'カメラスキャン',
    en: 'Camera Scan',
    zhcn: '相机',
    zhtw: '相機',
    ko: '카메라스캔',
    th: 'สแกนด้วยกล้อง',
    vi: 'Quét bằng máy ảnh',
  },
  useQRCodeScanner: {
    ja: '外付けQRスキャナーを利用',
    en: 'Use QR Code Scanner',
    zhcn: '使用外部QR扫描',
    zhtw: '使用外部QR掃描',
    ko: '외부 QR 스캐너를 이용',
    th: 'ใช้สแกนเนอร์ QR ภายนอก',
    vi: 'Sử dụng máy quét mã QR bên ngoài',
  },
  serialCodeList: {
    ja: 'シリアル番号一覧',
    en: 'Serial Number List',
    zhcn: '号列表',
    zhtw: '號列表',
    ko: '일련 번호 목록',
    th: 'รายการหมายเลขซีเรียล',
    vi: 'Danh sách số hiệu',
  },
  unitBottle: {
    ja: '{unit}本',
    en: '{unit} bottle(s)',
    zhcn: '{unit}瓶',
    zhtw: '{unit}瓶',
    ko: '{unit}병',
    th: '{unit} ขวด',
    vi: '{unit} chai',
  },
  bottles: {
    ja: ' 本',
    en: ' bottle(s)',
    zhcn: ' 瓶',
    zhtw: ' 瓶',
    ko: ' 병',
    th: ' ขวด',
    vi: ' chai',
  },
  scannedSerials: {
    ja: 'スキャン本数',
    en: 'Scanned Quantity',
    zhcn: '扫描数',
    zhtw: '掃描數',
    ko: '스캔 갯수',
    th: 'จำนวนที่สแกน',
    vi: 'Số lượng đã quét',
  },
  outgoingScannedCount: {
    ja: '出荷本数',
    en: 'Quantity',
    zhcn: '发货数量',
    zhtw: '發貨數量',
    ko: '출하 수',
    th: 'จำนวนที่ส่งออก',
    vi: 'Số lượng',
  },
  registeredLocationInformation: {
    ja: '登録位置情報',
    en: 'Registered Location Information',
    zhcn: '报名地点信息',
    zhtw: '報名地點信息',
    ko: '등록 위치 정보',
    th: 'ข้อมูลสถานที่ที่ลงทะเบียน',
    vi: 'Thông tin vị trí đã đăng ký',
  },
  brandNotRegisteredInSerial: {
    ja: 'シリアル番号に銘柄が登録されていません',
    en: 'No brand registered to this serial number',
    zhcn: '查无该品牌注册序号',
    zhtw: '查無該品牌註冊序號',
    ko: '해당 시리얼번호에 브랜드가 등록되어 있지 않습니다.',
    th: 'ไม่มีแบรนด์ที่ลงทะเบียนกับหมายเลขซีเรียลนี้',
    vi: 'Không có thương hiệu được đăng ký với số hiệu này',
  },
  serialNumberNotRegistered: {
    ja: 'シリアル番号が登録されていません',
    en: 'This serial number is not registered',
    zhcn: '序列号码没有被注册',
    zhtw: '序列號碼沒有被註冊',
    ko: '시리얼번호가 등록되어 있지 않습니다.',
    th: 'หมายเลขซีเรียลนี้ยังไม่ได้ลงทะเบียน',
    vi: 'Số hiệu này chưa được đăng ký',
  },
  arrivalNotRegistered: {
    ja: '入荷登録がされていません',
    en: 'This has not been received',
    zhcn: '尚未入货',
    zhtw: '尚未入貨',
    ko: '입하되지 않았습니다.',
    th: 'ยังไม่ได้ลงทะเบียนการรับสินค้า',
    vi: 'Chưa được nhận',
  },
  invalidBreweryData: {
    ja: '酒蔵が異なるため、出荷できません',
    en: 'This cannot be shipped due to invalid brewery',
    zhcn: '酒厂资讯不符、无法发货',
    zhtw: '酒廠資訊不符、無法發貨',
    ko: '양조장이 다르기 때문에 배송할 수 없습니다.',
    th: 'ไไม่สามารถจัดส่งได้เนื่องจากโรงสาเกไม่ถูกต้อง',
    vi: 'Không thể gửi do nhà máy không hợp lệ',
  },
  brandNotRegistered: {
    ja: '(銘柄: 未登録)',
    en: '(Brand: Not registered)',
    zhcn: '（品牌名称：未注册）',
    zhtw: '（品牌名稱：未註冊）',
    ko: '(브랜드 : 미등록)',
    th: '(แบรนด์: ยังไม่ได้ลงทะเบียน)',
    vi: '(Thương hiệu: Chưa đăng ký)',
  },
  canBeRegistered: {
    ja: '登録可能です',
    en: 'Can be registered',
    zhcn: '它可以被注册',
    zhtw: '它可以被註冊',
    ko: '등록 가능합니다.',
    th: 'สามารถลงทะเบียนได้',
    vi: 'Có thể đăng ký',
  },
  alreadyRegisteredForShipping: {
    ja: '既に出荷登録されています',
    en: 'This has already been registerd for shipping',
    zhcn: '它已经发货登记',
    zhtw: '它已經發貨登記',
    ko: '이미 출하 등록되어 있습니다.',
    th: 'มีการลงทะเบียนการจัดส่งแล้ว',
    vi: 'Đã được đăng ký gửi hàng',
  },
  alreadyRegisteredForArrival: {
    ja: '既に入荷登録されています',
    en: 'This has already been registered for receiving',
    zhcn: '已经登记入货',
    zhtw: '已經登記入貨',
    ko: '이미 입하 등록되어 있습니다.',
    th: 'มีการลงทะเบียนการรับสินค้าแล้ว',
    vi: 'Đã được đăng ký nhận hàng',
  },
  alreadyRegisteredForSale: {
    ja: '既に販売登録されています',
    en: 'This has already been registered for sale',
    zhcn: '已经登记可被销售',
    zhtw: '已經登記可被銷售',
    ko: '이미 판매 등록되어 있습니다.',
    th: 'มีการลงทะเบียนการขายแล้ว',
    vi: 'Đã được đăng ký bán',
  },
  breweryShippingIncomplete: {
    ja: '酒蔵の出荷登録がされていません',
    en: 'This has not been shipped by brewery',
    zhcn: '酒厂尚未出货',
    zhtw: '酒廠尚未出貨',
    ko: '양조장으로부터 출하 등록이되어 있지 않습니다.',
    th: 'การลงทะเบียนส่งสินค้าออกของโรงสาเกยังไม่ถุกลงทะเบียน',
    vi: 'Chưa được gửi bởi nhà máy',
  },
  diffDistributorArrivedContactJCSC: {
    ja: '別のディストリビュータが入荷したボトルです',
    en: 'This has been received by a different distributor.',
    zhcn: '已被其他经销商收货',
    zhtw: '已被其他經銷商收貨',
    ko: '다른 유통 업체에 의해 입하 된 병입니다.',
    th: 'ได้รับสินค้าจากผู้จัดจำหน่ายคนอื่น',
    vi: 'Đã được nhận bởi nhà phân phối khác',
  },
  diffRestaurantArrivedContactJCSC: {
    ja: '別のレストランが入荷したボトルです',
    en: 'This has been received by a different restaurant',
    zhcn: '已被其他餐厅收货',
    zhtw: '已被其他餐廳收貨',
    ko: '다른 레스토랑에 의해 입하 된 병입니다.',
    th: 'ขวดที่ได้รับจากร้านอาหารอื่น',
    vi: 'Đã được nhận bởi nhà hàng khác',
  },
  positionInfoWhenScanning: {
    ja: 'スキャン位置情報',
    en: 'Scanned Location Information',
    zhcn: '扫描位置信息',
    zhtw: '掃描位置信息',
    ko: '스캔 위치 정보',
    th: 'ข้อมูลสถานที่ที่สแกน',
    vi: 'Thông tin vị trí đã quét',
  },
  listOfScannedQRData: {
    ja: 'スキャンデータ一覧',
    en: 'Scanned QR Data List',
    zhcn: '扫描数据列表',
    zhtw: '掃描數據列表',
    ko: '스캔 데이터 목록',
    th: 'รายการข้อมูล QR ที่สแกน',
    vi: 'Danh sách dữ liệu QR đã quét',
  },
  pleaseBringTheQRCodeCloserToTheCamera: {
    ja: '*QRをカメラに近づけてください',
    en: '*Please move QR code closer to the camera',
    zhcn: '*请将QR码贴近相机',
    zhtw: '*請將QR碼貼近相機',
    ko: '*QR 코드를 카메라에 가까이 하십시오',
    th: '*กรุณานำ QR code ใกล้กล้อง',
    vi: '*Vui lòng đưa mã QR gần camera',
  },
  reading: {
    ja: '読み取り中...',
    en: 'Reading...',
    zhcn: '读取中...',
    zhtw: '讀取中 ...',
    ko: '읽는 중',
    th: 'กำลังอ่าน...',
    vi: 'Đang đọc...',
  },
  import: {
    ja: '取り込む',
    en: 'Import',
    zhcn: '进口',
    zhtw: '進口',
    ko: '캡처',
    th: 'นำเข้า',
    vi: 'Nhập khẩu',
  },
  invalidQRCode: {
    ja: '無効なQRコードです',
    en: 'Invalid QR code',
    zhcn: '无效的QR码',
    zhtw: '無效的QR碼',
    ko: '잘못된 QR 코드',
    th: 'QR code ไม่ถูกต้อง',
    vi: 'Mã QR không hợp lệ',
  },
  thisQRCodeHasAlreadyBeenScanned: {
    ja: 'すでにスキャン済みのQRコードです',
    en: 'This QR code has already been scanned',
    zhcn: '它已经扫描QR码',
    zhtw: '它已經掃描QR碼',
    ko: '이미 스캔 된 QR 코드',
    th: 'QR code นี้ถูกสแกนแล้ว',
    vi: 'Mã QR này đã được quét',
  },
  doYouWantToImportThisData: {
    ja: '以下のデータをインポートしますか？',
    en: 'Import this data?',
    zhcn: '你确定你要导入以下数据？',
    zhtw: '你確定你要導入以下數據？',
    ko: '해당 데이터를 가져 오시겠습니까?',
    th: 'คุณต้องการนำเข้าข้อมูลนี้หรือไม่?',
    vi: 'Bạn có muốn nhập dữ liệu này không?',
  },
  url: {
    ja: 'URL',
    en: 'URL',
    zhcn: '网址',
    zhtw: '網址',
    ko: 'URL',
    th: 'URL',
    vi: 'URL',
  },
  area: {
    ja: 'エリア名',
    en: 'Area Name',
    zhcn: '区域名',
    zhtw: '區域名',
    ko: '지역 이름',
    th: 'ชื่อพื้นที่',
    vi: 'Tên khu vực',
  },
  successfulScan: {
    ja: 'スキャン成功',
    en: 'Scan successful',
    zhcn: '扫描成功',
    zhtw: '掃描成功',
    ko: '스캔 성공',
    th: 'สแกนสำเร็จ',
    vi: 'Quét thành công',
  },
  pleasePasteTheQRDataMessage: {
    ja: '*QRスキャナーで読み取ったデータを、そのまま貼り付けてください',
    en: '*Input data read by a QR',
    zhcn: '*数据由QR扫描仪读取，请复制并粘贴',
    zhtw: '*數據由QR掃描儀讀取，請複製並貼上',
    ko: '*QR 스캐너로 스캔 한 데이터를 그대로 붙여 넣으십시오',
    th: '*กรุณาวางข้อมูลที่อ่านโดย QR สแกนเนอร์',
    vi: '*Vui lòng dán dữ liệu được đọc bởi máy quét QR',
  },
  addSerial: {
    ja: 'シリアル番号を追加',
    en: 'Add Serial Number',
    zhcn: '添加序列号',
    zhtw: '添加序列號',
    ko: '일련 번호를 추가',
    th: 'เพิ่มหมายเลขซีเรียล',
    vi: 'Thêm số sê-ri',
  },
  unregisteredSerialNumber: {
    ja: '*未登録シリアル番号 (登録不可)',
    en: '*Unregistered serial number',
    zhcn: '*未注册的序列号（注册是不可能的）',
    zhtw: '*未註冊的序列號（註冊是不可能的）',
    ko: '*미등록 일련 번호 (등록 불가)',
    th: '*หมายเลขซีเรียลที่ไม่ได้ลงทะเบียน (ไม่สามารถลงทะเบียนได้)',
    vi: '*Số sê-ri chưa đăng ký (không thể đăng ký)',
  },
  brandBreweryDoesNotBelongToOwnBrewery: {
    ja: '*酒蔵が異なる (登録不可)',
    en: '*A brand does not belong to the selected brewery',
    zhcn: '*品牌资讯与酒厂不符（注册是不可能的）',
    zhtw: '*品牌資訊與酒廠不符（註冊是不可能的）',
    ko: '*해당 브랜드가 선택한 양조장에 없음 (등록 불가)',
    th: '*โรงสาเกไม่ถูกต้อง (ไม่สามารถลงทะเบียนได้)',
    vi: '*Thương hiệu không thuộc về nhà máy đã chọn (không thể đăng ký)',
  },
  differentFromBrewShipping: {
    ja: '*酒蔵出荷先と異なる',
    en: '*Different from the brewery shipping destination',
    zhcn: '*从酿酒厂发货目的地不同',
    zhtw: '*從釀酒廠發貨目的地不同',
    ko: '*양조장 출하 위치와 다름',
    th: '*ที่อยู่การส่งออกของโรงสาเกไม่ถูกต้อง',
    vi: '*Khác với địa điểm gửi của Nhà máy',
  },
  differentFromRestRecv: {
    ja: '*レストラン入荷先と異なる',
    en: '*Different from the designated receiving restaurant',
    zhcn: '与收货餐厅不同',
    zhtw: '與收貨餐廳不同',
    ko: '*입하 레스토랑과 다름',
    th: '*แตกต่างจากร้านอาหารที่รับ',
    vi: '*Khác với nhà hàng nhận',
  },
  incompleteRecv: {
    ja: '*レストラン入荷手続未完了 (登録不可)',
    en: '*Incomplete restaurant receiving',
    zhcn: '*餐厅库存过程没有完成（注册是不可能的）',
    zhtw: '*餐廳庫存過程沒有完成（註冊是不可能的）',
    ko: '*레스토랑 입하 절차 미완료 (등록 불가)',
    th: '*การรับสินค้าจากร้านอาหารไม่สมบูรณ์ (ไม่สามารถลงทะเบียนได้)',
    vi: '*Nhận hàng từ nhà hàng chưa hoàn thành (không thể đăng ký)',
  },
  checkDataAndRegister: {
    ja: '最終確認・登録する',
    en: 'Finalize Data and Register',
    zhcn: '最终确认和登记',
    zhtw: '最終確認和登記',
    ko: '최종 확인 · 등록하기',
    th: 'ตรวจสอบและลงทะเบียนข้อมูลสุดท้าย',
    vi: 'Hoàn thành và đăng ký dữ liệu cuối cùng',
  },
  search: {
    ja: '検索',
    en: 'Search',
    zhcn: '搜寻',
    zhtw: '搜尋',
    ko: '검색',
    th: 'ค้นหา',
    vi: 'Tìm kiếm',
  },
  receiving: {
    ja: '入荷',
    en: 'Receiving',
    zhcn: '货到',
    zhtw: '貨到',
    ko: '입하',
    th: 'รับสินค้า',
    vi: 'Nhận hàng',
  },
  selling: {
    ja: '販売',
    en: 'Selling',
    zhcn: '销售',
    zhtw: '銷售',
    ko: '판매',
    th: 'ขาย',
    vi: 'Bán hàng',
  },
  registrationError: {
    ja: '登録処理中にエラーが発生しました',
    en: 'An issue has occured during registration',
    zhcn: '在注册过程中发生了错误',
    zhtw: '在註冊過程中發生了錯誤',
    ko: '등록 처리 중 오류가 발생했습니다',
    th: 'เกิดปัญหาระหว่างการลงทะเบียน',
    vi: 'Đã xảy ra vấn đề trong quá trình đăng ký',
  },
  cannotUpdateDueToBreweryProcedures: {
    ja: '酒蔵出荷以降の手続きが完了しているため、更新ができません',
    en: 'Cannot update data because the procedures after brewery shipping are completed',
    zhcn: '由于酒厂已完成装运，无法再次更改',
    zhtw: '由於酒廠已經完成裝運，無法再次更改',
    ko: '양조장 출하가 완료 된 이후에는 업데이트 할 수 없습니다',
    th: 'ไม่สามารถอัปเดตข้อมูลเนื่องจากกระบวนการหลังจากจัดส่งโรงสาเกได้เสร็จสมบูรณ์',
    vi: 'Không thể cập nhật dữ liệu vì các thủ tục sau khi gửi từ Nhà máy đã hoàn thành',
  },
  langJapanese: {
    ja: '日本語',
    en: 'Japanese',
    zhcn: '日语',
    zhtw: '日語',
    ko: '일본어',
    th: 'ภาษาญี่ปุ่น',
    vi: 'Tiếng Nhật',
  },
  langEnglish: {
    ja: '英語',
    en: 'English',
    zhcn: '英语',
    zhtw: '英語',
    ko: '영어',
    th: 'ภาษาอังกฤษ',
    vi: 'Tiếng Anh',
  },
  langChinese: {
    ja: '簡体字中国語',
    en: 'Chinese',
    zhcn: '简体中文',
    zhtw: '簡體中文',
    ko: '중국',
    th: 'ภาษาจีนตัวย่อ',
    vi: 'Tiếng Trung (Giản thể)',
  },
  langCantonese: {
    ja: '香港語中国語',
    en: 'Cantonese',
    zhcn: '繁体中文',
    zhtw: '繁體中文',
    ko: '홍콩어',
    th: 'ภาษาจีนตัวเต็ม',
    vi: 'Tiếng Trung (Phồn thể)',
  },
  langKorean: {
    ja: '韓国語',
    en: 'Korean',
    zhcn: '韩语',
    zhtw: '韓語',
    ko: '한국어',
    th: 'ภาษาเกาหลี',
    vi: 'Tiếng Hàn',
  },
  breweryRestaurantInvalidUser: {
    ja: '酒蔵または、レストランに属しないユーザは本アプリを利用出来ません。',
    en: 'Users not belonging to a designated brewery or restaurant cannot use this application',
    zhcn: '该用户不属于登记酒厂或餐厅、无法使用本程序',
    zhtw: '該用戶不屬於登記酒廠或餐廳、無法使用本程序',
    ko: '지정된 양조장이나 레스토랑에 혹하지 않는 사용자는 이 앱을 이용할 수 없습니다',
    th: 'ผู้ใช้ที่ไม่เป็นของโรงเบียร์หรือร้านอาหารที่ระบุไม่สามารถใช้แอปพลิเคชันนี้ได้',
    vi: 'Người dùng không thuộc nhà máy hoặc nhà hàng được chỉ định không thể sử dụng ứng dụng này',
  },
  serialCollection: {
    ja: 'シリアル集',
    en: 'Serial Numbers',
    zhcn: '系列收藏',
    zhtw: '系列收藏',
    ko: '시리얼번호',
    th: 'ชุดหมายเลขซีเรียล',
    vi: 'Bộ sưu tập số sê-ri',
  },
  processReceiving: {
    ja: '入荷を受け付ける',
    en: 'Receive Stock',
    zhcn: '确认收货',
    zhtw: '確認收貨',
    ko: '입하 진행',
    th: 'รับการนำเข้าสินค้า',
    vi: 'Nhận hàng',
  },
  maxLimitRegistration: {
    ja: '*最大{limit}件までしか登録できません',
    en: '*Cannot register more than {limit} items',
    zhcn: '您只能最多只能登录{limit}',
    zhtw: '*您只能最多只能登錄{limit}',
    ko: '*최대 {limit} 건까지만 등록 할 수 있습니다',
    th: '*ไม่สามารถลงทะเบียนได้มากกว่า {limit} รายการ',
    vi: '*Không thể đăng ký nhiều hơn {limit} mục',
  },
  startPointLowerInvalid: {
    ja: '* 始点より小さい値は無効です',
    en: '*A value lower than the start is invalid',
    zhcn: '*小于该值是无效的',
    zhtw: '*小於該值是無效的',
    ko: '*시작점보다 작은 값은 잘못되었습니다',
    th: '*ค่าที่น้อยกว่าจุดเริ่มต้นไม่ถูกต้อง',
    vi: '*Giá trị nhỏ hơn điểm bắt đầu là không hợp lệ',
  },
  serialNotInRange: {
    ja: '*シリアルが範囲外',
    en: '*Serial not in range',
    zhcn: '*序列不在范围内',
    zhtw: '*序列不在范围内',
    ko: '*범위에 없는 일련 번호',
    th: '*หมายเลขซีเรียลไม่อยู่ในช่วง',
    vi: '*Số sê-ri không nằm trong phạm vi',
  },
  duplicateSerial: {
    ja: '*重複シリアル',
    en: '*Duplicate Serial',
    zhcn: '*重复序列',
    zhtw: '*重复序列',
    ko: '*중복 시리얼',
    th: '*ซ้ำซ้อนหมายเลขซีเรียล',
    vi: '*Số sê-ri trùng lặp',
  },
  from: {
    ja: '始点',
    en: 'From',
    zhcn: '从',
    zhtw: '從',
    ko: '에서',
    th: 'จาก',
    vi: 'Từ',
  },
  to: {
    ja: '終点',
    en: 'To',
    zhcn: '直到',
    zhtw: '直到',
    ko: '까지',
    th: 'ถึง',
    vi: 'Đến',
  },
  except: {
    ja: '欠番',
    en: 'Except',
    zhcn: '除了',
    zhtw: '除了',
    ko: '제외하고',
    th: 'ยกเว้น',
    vi: 'Ngoại trừ',
  },
  amount: {
    ja: '合計本数:',
    en: 'Total:',
    zhcn: '数量:',
    zhtw: '数量:',
    ko: '양:',
    th: 'จำนวนรวมทั้งหมด:',
    vi: 'Tổng:',
  },
  serialRangeStart: {
    ja: 'シリアル番号 (始点)',
    en: 'Serial Number (Start)',
    zhcn: '序列号（起点）',
    zhtw: '序列號（起點）',
    ko: '시리얼번호(시작)',
    th: 'หมายเลขซีเรียล (จุดเริ่มต้น)',
    vi: 'Số sê-ri (Bắt đầu)',
  },
  serialRangeEnd: {
    ja: 'シリアル番号 (終点)',
    en: 'Serial Number (End)',
    zhcn: '序号（结束）',
    zhtw: '序號（結束）',
    ko: '시리얼번호(끝)',
    th: 'หมายเลขซีเรียล (จุดสิ้นสุด)',
    vi: 'Số sê-ri (Kết thúc)',
  },
  serialExclude: {
    ja: 'シリアル番号 (欠番)',
    en: 'Missing Serial Number',
    zhcn: '序号（除了）',
    zhtw: '序號（除了）',
    ko: '시리얼번호(제외하고)',
    th: 'หมายเลขซีเรียล (ยกเว้น)',
    vi: 'Số sê-ri (Ngoại trừ)',
  },
  serialInputSampleStart: {
    ja: '例: 10001',
    en: 'ex) 10001',
    zhcn: '例如：10001',
    zhtw: '例如：10001',
    ko: '예 : 10001',
    th: 'ตัวอย่าง: 10001',
    vi: 'Ví dụ: 10001',
  },
  serialInputSampleEnd: {
    ja: '例: 10020',
    en: 'Ex: 10020',
    zhcn: '例如：10020',
    zhtw: '例如：10020',
    ko: '예 : 10020',
    th: 'ตัวอย่าง: 10020',
    vi: 'Ví dụ: 10020',
  },
  serialInputSampleExclude: {
    ja: '例: 10013,10017',
    en: 'Ex: 10013,10017',
    zhcn: '例如：10013,10017',
    zhtw: '例如：10013,10017',
    ko: '예 : 10013,10017',
    th: 'ตัวอย่าง: 10013,10017',
    vi: 'Ví dụ: 10013,10017',
  },
  individualRegistration: {
    ja: '単体登録',
    en: 'Individual Registration',
    zhcn: '单一註冊',
    zhtw: '單一註冊',
    ko: '개별 등록',
    th: 'ลงทะเบียนแบบเดี่ยว',
    vi: 'Đăng ký cá nhân',
  },
  bundleRegistration: {
    ja: '一括登録',
    en: 'Bundle Registration',
    zhcn: '整批注册',
    zhtw: '整批註冊',
    ko: '일괄 등록',
    th: 'ลงทะเบียนแบบชุด',
    vi: 'Đăng ký theo gói',
  },
  reload: {
    ja: 'リロード',
    en: 'Reload',
    zhcn: '刷新',
    zhtw: '刷新',
    ko: '새로 고침',
    th: 'รีโหลด',
    vi: 'Tải lại',
  },
  invoiceNumber: {
    ja: '伝票番号',
    en: 'Invoice Number',
    zhcn: '收据号码',
    zhtw: '收據號碼',
    ko: '전표 번호',
    th: 'หมายเลขใบแจ้งหนี้',
    vi: 'Số hóa đơn',
  },
  salesOrderNumber: {
    ja: '伝票番号',
    en: 'Sales Order Number',
    zhcn: '销售代码',
    zhtw: '銷售代碼',
    ko: '전표 번호',
    th: 'หมายเลขใบสั่งขาย',
    vi: 'Số đơn đặt hàng bán',
  },
  serialLength: {
    ja: '* 最大文字数は{field}文字です',
    en: '* The maximum number of character is {field}',
    zhcn: '*最大字符数为{field}',
    zhtw: '*最大字符數為{field}',
    ko: '* 최대 길이는 {field} 자입니다',
    th: '* จำนวนตัวอักษรสูงสุดคือ {field}',
    vi: '* Số lượng ký tự tối đa là {field}',
  },
  onlyNumbers: {
    ja: '* 数字以外の文字が含まれています',
    en: '* Contains non-numeric character',
    zhcn: '*含有数字以外的英文字母或符号',
    zhtw: '*含有數字以外的英文字母或符號',
    ko: '* 숫자가 아닌 문자가 포함되어 있습니다',
    th: '* มีอักขระที่ไม่ใช่ตัวเลข',
    vi: '* Chứa ký tự không phải số',
  },
  totalCount: {
    ja: '計{field}本',
    en: '{field}bottle(s)',
    zhcn: '共计{field}瓶',
    zhtw: '共計{field}瓶',
    ko: '총 {field} 개',
    th: 'ทั้งหมด {field} ขวด',
    vi: 'Tổng {field} chai',
  },
  bottleContactJCSC: {
    ja: '別のレストラン向けに出荷されたボトルです',
    en: 'The bottle is shipped to another restaurant',
    zhcn: '这是发往其他餐厅的酒瓶',
    zhtw: '這是發往其他餐廳的酒瓶',
    ko: '다른 레스토랑 전용으로 출시 된 병입니다',
    th: 'ขวดนี้ถูกจัดส่งให้ร้านอาหารอื่น',
    vi: 'Chai này được gửi đến nhà hàng khác',
  },
  canRegister: {
    ja: '登録可能',
    en: 'Can be registered',
    zhcn: '可注册',
    zhtw: '可註冊',
    ko: '등록 가능',
    th: 'สามารถลงทะเบียนได้',
    vi: 'Có thể đăng ký',
  },
  alreadyRegister: {
    ja: '既に登録済み',
    en: 'Already registered',
    zhcn: '已经注册',
    zhtw: '已經註冊',
    ko: '이미 등록 완료',
    th: 'ลงทะเบียนแล้ว',
    vi: 'Đã đăng ký',
  },
  serverError: {
    ja: 'サーバ内部でエラーが発生しました',
    en: 'An error has occurred in server',
    zhcn: '服务器内发生错误',
    zhtw: '服務器內發生錯誤',
    ko: '서버 내부 오류가 발생했습니다',
    th: 'เกิดข้อผิดพลาดในเซิร์ฟเวอร์',
    vi: 'Đã xảy ra lỗi trong máy chủ',
  },
  serialDelete: {
    ja: 'シリアル番号を削除',
    en: 'Delete serial number',
    zhcn: '删除序列号',
    zhtw: '刪除序列號',
    ko: '일련 번호를 삭제',
    th: 'ลบหมายเลขซีเรียล',
    vi: 'Xóa số sê-ri',
  },
  confirmSerialDelete: {
    ja: 'シリアル番号{field}を削除してもよろしいですか？',
    en: 'Are you sure to delete the serial number {field}?',
    zhcn: '您确定要删除序列号{field}吗？',
    zhtw: '您確定要刪除序列號{field}嗎？',
    ko: '일련 번호 {field}를 삭제 하시겠습니까?',
    th: 'คุณแน่ใจหรือไม่ที่จะลบหมายเลขซีเรียล {field}?',
    vi: 'Bạn có chắc chắn muốn xóa số sê-ri {field} không?',
  },
  confirmSerialDeleteAdmin: {
    ja: 'シリアル({field})を削除しすか？',
    en: 'Are you sure you want to delete serial ({field})?',
    zhcn: '您确定要删除序列号 ({field})?',
    zhtw: '您確定要刪除序列號 ({field})?',
    ko: '시리얼 ({field})을 삭제하시겠습니까?',
    th: 'คุณแน่ใจหรือไม่ว่าจะลบหมายเลขซีเรียล ({field})?',
    vi: 'Bạn có chắc chắn muốn xóa số sê-ri ({field}) không?',
  },
  filter: {
    ja: 'フィルター',
    en: 'Filter',
    zhcn: '筛选',
    zhtw: '篩選',
    ko: '필터',
    th: 'ตัวกรอง',
    vi: 'Bộ lọc',
  },
  filterClose: {
    ja: 'フィルターを閉じる',
    en: 'Close Filter',
    zhcn: '关闭过滤器',
    zhtw: '關閉過濾器',
    ko: '필터 닫기',
    th: 'ปิดตัวกรอง',
    vi: 'Đóng bộ lọc',
  },
  filterOpen: {
    ja: 'フィルターを開く',
    en: 'Open Filter',
    zhcn: '打开过滤器',
    zhtw: '打開過濾器',
    ko: '필터 열기',
    th: 'เปิดตัวกรอง',
    vi: 'Mở bộ lọc',
  },
  filterReset: {
    ja: 'フィルターリセット',
    en: 'Reset Filter',
    zhcn: '过滤器复位',
    zhtw: '過濾器復位',
    ko: '필터 재설정',
    th: 'รีเซ็ตตัวกรอง',
    vi: 'Đặt lại bộ lọc',
  },
  inputPeriod: {
    ja: '期間を指定',
    en: 'Designate Duration',
    zhcn: '指定时间段',
    zhtw: '指定時間段',
    ko: '기간 지정',
    th: 'ระบุช่วงเวลา',
    vi: 'Chỉ định khoảng thời gian',
  },
  today: {
    ja: '今日',
    en: 'Today',
    zhcn: '今天',
    zhtw: '今天',
    ko: '오늘',
    th: 'วันนี้',
    vi: 'Hôm nay',
  },
  yesterday: {
    ja: '昨日',
    en: 'Yesterday',
    zhcn: '昨天',
    zhtw: '昨天',
    ko: '어제',
    th: 'เมื่อวาน',
    vi: 'Hôm qua',
  },
  lastSevenDays: {
    ja: '過去7日間',
    en: 'Last 7 Days',
    zhcn: '过去7天',
    zhtw: '過去7天',
    ko: '지난 7 일',
    th: '7 วันที่ผ่านมา',
    vi: '7 ngày qua',
  },
  ok: {
    ja: '決定',
    en: 'OK',
    zhcn: '决定',
    zhtw: '決定',
    ko: '결정',
    th: 'ตกลง',
    vi: 'OK',
  },
  daySunday: {
    ja: '日',
    en: 'Sun',
    zhcn: '日',
    zhtw: '日',
    ko: '일',
    th: 'อา',
    vi: 'CN',
  },
  dayMonday: {
    ja: '月',
    en: 'Mon',
    zhcn: '一',
    zhtw: '一',
    ko: '월',
    th: 'จ',
    vi: 'T2',
  },
  dayTuesday: {
    ja: '火',
    en: 'Tue',
    zhcn: '二',
    zhtw: '二',
    ko: '화',
    th: 'อ',
    vi: 'T3',
  },
  dayWednesday: {
    ja: '水',
    en: 'Wed',
    zhcn: '三',
    zhtw: '三',
    ko: '수',
    th: 'พ',
    vi: 'T4',
  },
  dayThursday: {
    ja: '木',
    en: 'Thur',
    zhcn: '四',
    zhtw: '四',
    ko: '목',
    th: 'พฤ',
    vi: 'T5',
  },
  dayFriday: {
    ja: '金',
    en: 'Fri',
    zhcn: '五',
    zhtw: '五',
    ko: '금',
    th: 'ศ',
    vi: 'T6',
  },
  daySaturday: {
    ja: '土',
    en: 'Sat',
    zhcn: '六',
    zhtw: '六',
    ko: '토',
    th: 'ส',
    vi: 'T7',
  },
  reset: {
    ja: 'リセット',
    en: 'Reset',
    zhcn: '重置',
    zhtw: '重置',
    ko: '재설정',
    th: 'รีเซ็ต',
    vi: 'Đặt lại',
  },
  alreadyExist: {
    ja: '伝票番号が重複しています',
    en: 'This Sales Order Number has already been registered. Please try again',
    zhcn: '此销售订单号已经注册。请再试一次',
    zhtw: '此銷售訂單號已經註冊。請再試一次',
    ko: '이 판매 주문 번호는 이미 등록되었습니다. 다시 시도하십시오',
    th: 'หมายเลขบิลนี้ซ้ำกันอยู่',
    vi: 'Số đơn đặt hàng bán này đã được đăng ký. Vui lòng thử lại',
  },
  serialAlreadyEntered: {
    ja: '※シリアルは入力済みです',
    en: '*Serial has already been entered',
    zhcn: '*序列号已经输入',
    zhtw: '*序列号已经输入',
    ko: '*일련은 이미 입력되었습니다',
    th: '*หมายเลขซีเรียลถูกป้อนไปแล้ว',
    vi: '*Số sê-ri đã được nhập',
  },
  serialOutsideRange: {
    ja: '※シリアルは範囲外',
    en: '*Serial is outside the range',
    zhcn: '*序列号超出范围',
    zhtw: '*序列号超出范围',
    ko: '*시리얼이 범위를 벗어남',
    th: '*หมายเลขซีเรียลอยู่นอกช่วง',
    vi: '*Số sê-ri nằm ngoài phạm vi',
  },
  selectBottleToCancelShipping: {
    ja: '出荷をキャンセルするボトルを選択',
    en: 'Select bottles to cancel shipping',
    zhcn: '选择瓶子以取消运输',
    zhtw: '選擇瓶子以取消運輸',
    ko: '배송을 취소할 병 선택',
    th: 'เลือกขวดเพื่อยกเลิกการจัดส่ง',
    vi: 'Chọn chai để hủy giao hàng',
  },
  undo: {
    ja: '元に戻す',
    en: 'Undo',
    zhcn: '撤消',
    zhtw: '撤消',
    ko: '실행 취소',
    th: 'กลับไปเหมือนเดิม',
    vi: 'Hủy bỏ',
  },
  confirmCancelShipping: {
    ja: '出荷のキャンセルを確認',
    en: 'Confirm cancel Shipping',
    zhcn: '确认取消发货',
    zhtw: '确认取消发货',
    ko: '배송 취소 확인',
    th: 'ยืนยันการยกเลิกการจัดส่ง',
    vi: 'Xác nhận hủy giao hàng',
  },
  cancelShippingDialogBody: {
    ja: '出荷をキャンセルしてもよろしいですか？',
    en: 'Are you sure to cancel shipping?',
    zhcn: '您确定要取消发货吗？',
    zhtw: '您确定要取消发货吗？',
    ko: '배송을 취소하시겠습니까?',
    th: 'คุณแน่ใจหรือไม่ที่จะยกเลิกการจัดส่ง?',
    vi: 'Bạn có chắc chắn muốn hủy giao hàng không?',
  },
  cancelShippingDialogTitle: {
    ja: '出荷をキャンセル',
    en: 'Cancel Shipping',
    zhcn: '取消发货',
    zhtw: '取消发货',
    ko: '배송 취소',
    th: 'ยกเลิกการจัดส่ง',
    vi: 'Hủy giao hàng',
  },
  selectAll: {
    ja: 'すべて選択',
    en: 'Select All',
    zhcn: '全选',
    zhtw: '全选',
    ko: '모두 선택',
    th: 'เลือกทั้งหมด',
    vi: 'Chọn tất cả',
  },
  tokenIsInvalid: {
    ja: 'セッションの有効期限が切れました。恐れ入りますが再度ログインしてください。',
    en: 'Session has exired. Please login again.',
    zhcn: '会话已过期。 很抱歉，请重新登录。',
    zhtw: '會話已過期。 很抱歉，請重新登錄。',
    ko: '세션이 만료되었습니다. 다시 로그인하십시오.',
    th: 'ระยะเวลาของคุณเสร็จสิ้น โปรดลงชื่อเข้าใช้ใหม่',
    vi: 'Phiên đã hết hạn. Vui lòng đăng nhập lại',
  },
  customerCode: {
    ja: '取引先番号',
    en: 'Customer Number',
    zhcn: '客戶代碼',
    zhtw: '客户代码',
    ko: '고객 코드',
    th: 'หมายเลขลูกค้า',
    vi: 'Mã khách hàng',
  },
  enterCustomerNumber: {
    ja: 'カスタマーナンバーまたは名前（任意）',
    en: 'Customer Number or Name (optional)',
    zhcn: '客户编号或名称（可选）',
    zhtw: '客戶編號或名稱（選擇）',
    ko: '고객 번호 또는 이름(선택 사항)',
    th: 'หมายเลขลูกค้า หรือ ชื่อ (ตัวเลือก)',
    vi: 'Mã khách hàng hoặc tên (tùy chọn)',
  },
  enterEventName: {
    ja: 'イベント名',
    en: 'Event Name',
    zhcn: '事件名称',
    zhtw: '事件名稱',
    ko: '이벤트 이름',
    th: 'ชื่อเหตุการณ์',
    vi: 'Tên sự kiện',
  },
  enterPurpose: {
    ja: '使用目的（例：テイスティング、マーケティング etc...）',
    en: 'Purpose (e.g. Tasting, Marketing etc…）',
    zhcn: '目的（例如品尝，营销等...）',
    zhtw: '目的（例如品嘗，行銷等...）',
    ko: '목적 (예 : 시음, 마케팅 등... ）',
    th: 'วัตถุประสงค์การใช้ (ตัวอย่าง เช่น การชิมรสชาติ,มาเก็ตติ้ง เป็นต้น',
    vi: 'Mục đích (ví dụ: Thử vị, Marketing, v.v...)',
  },
});

function LangSettings<T extends { [key: string]: { [key in TSupportedLanguages] } }>(arg: T): T {
  return arg;
}
